//react
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button } from "@mui/material";
import Card from "@mui/joy/Card";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { Container } from "@mui/system";
import "../components/enquiries/scss/UserProfile.scss";

const UserProfile = () => {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // local state
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    phone: "",
    email: "",
  });

  // React Hooks
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }

    getuserdata();
  }, []);

  // get user view profile data
  const getuserdata = async () => {
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/users/profile`;
      let options = {
        method: "get",
        headers: new Headers({
          //authorization:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6eyJfaWQiOiI2MzNmYmFiOWIyM2IyOTdkMTBlY2FhNmIiLCJmaXJzdF9uYW1lIjoicHJlZXRoaSIsImxhc3RfbmFtZSI6InZlbGxhIiwidXNlcl9uYW1lIjoiYWRtaW4iLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsInBob25lIjoiOTYxODM3MTIxMCIsInBhc3N3b3JkIjoiJDJiJDEwJDNoRmFZVkZBeUw3YTQxZVEwSzE0Ly44RVJoNnR5WVl5enVYbmxNSTZDMWY4TTR1Z0ZtQm5TIiwidXNlcl90eXBlIjoiQURNSU4iLCJjcmVhdGVkQXQiOiIyMDIyLTEwLTA3VDA1OjM1OjUzLjU2OFoiLCJ1cGRhdGVkQXQiOiIyMDIyLTEwLTE3VDA0OjEzOjUzLjE2NVoiLCJfX3YiOjAsImNvbmZpcm1QYXNzd29yZCI6IiQyYiQxMCRwUDJhdmFpRUJ2L0xGQ3M1ZUhrNTkuSEVBdC5FdndndDRZSm5SZHJhVmtLZmdtNFJRUkxTLiJ9LCJpZCI6IjYzM2ZiYWI5YjIzYjI5N2QxMGVjYWE2YiIsImlhdCI6MTY2NTk4ODMyMywiZXhwIjoxNjY2ODUyMzIzfQ.OlKT0Y4NWAgzMBGhvpwIyyEgPstY0hXrXHWs4TQVTa0",
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let result = await fetch(url, options);
      result = await result.json().then((getuser) => {
        console.log(getuser.data);
        setUserData(getuser.data);
        console.log(userData);
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div id="UserProfile">
      <Container>
        <div>
          <div>
            <Typography pt={4}>View User Profile</Typography>
          </div>
          <Box className="block-1">
            <Card className="inner" variant="outlined">
              <div>
                <div className="">
                  <Link to={`/profile/edit/${userData._id}`}>
                    <Button className="button" variant="btn btn-success">
                      Edit
                    </Button>
                  </Link>
                </div>
                <div>
                  <Avatar src={userData.profile_pic} />
                </div>
              </div>
              <Box>
                <div>
                  <Typography className="pb-1" pt={3}>
                    First Name : {userData.first_name}
                  </Typography>
                  <Typography className="pb-1" pt={3}>
                    Last Name : {userData.last_name}
                  </Typography>
                  <Typography className="pb-1" pt={3}>
                    User Type : {userData.user_name}
                  </Typography>
                  <Typography className="pb-1" pt={3}>
                    Phone Number : {userData.phone}
                  </Typography>
                  <Typography className="pb-1" pt={3}>
                    Email Id : {userData.email}
                  </Typography>
                </div>
              </Box>
            </Card>
          </Box>
        </div>
      </Container>
    </div>
  );
};

export default UserProfile;
