//react
import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { Card, Grid, Typography } from "@mui/material";
import { store } from "./ViewProgress";

import "../scss/view/_viewAddress.scss";

function ViewEnquiryAddress({ enquriyInfo }) {

  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
  }, []);

  return (
    <div id="viewAddress">
      <Card className="address-block" variant="outlined">
        <Grid container spacing={2} rowGap={2}>
          <Grid className="title-block" item xs={12}>
            <Typography className="title">Address Details</Typography>
          </Grid>
          <Grid item className="sub-title-block" xs={12}>
            <Typography className="sub-title">
              Address For Communication
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className="viewtitle">Address Line 1</Typography>
            <span className="details">
              {console.log(enquriyInfo.communication_address)}
              {enquriyInfo.communication_address
                ? enquriyInfo.communication_address.address_line_1
                : "-"}
            </span>
          </Grid>
          <Grid item xs={6}>
            <Typography className="viewtitle">Address Line 2</Typography>
            <span className="details">
              {enquriyInfo.communication_address
                ? enquriyInfo.communication_address.address_line_2 || "-"
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Town/City</Typography>
            <span className="details" style={{ textTransform: "capitalize" }}>
              {enquriyInfo.communication_address
                ? enquriyInfo.communication_address.city
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">State</Typography>
            <span className="details" style={{ textTransform: "capitalize" }}>
              {enquriyInfo.communication_address
                ? enquriyInfo.communication_address.state
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Pincode</Typography>
            <span className="details">
              {enquriyInfo.communication_address
                ? enquriyInfo.communication_address.pincode
                : "-"}
            </span>
          </Grid>
          <Grid item className="sub-title-block" xs={12}>
            <Typography className="sub-title">
              Address For Registration
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className="viewtitle">Address Line 1</Typography>
            <span className="details">
              {console.log(enquriyInfo)}

              {enquriyInfo.registration_address
                ? enquriyInfo.registration_address.address_line_1 || "-"
                : "-"}
            </span>
          </Grid>
          <Grid item xs={6}>
            <Typography className="viewtitle">Address Line 2</Typography>
            <span className="details">
              {enquriyInfo.registration_address
                ? enquriyInfo.registration_address.address_line_2 || "-"
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Town/City</Typography>
            <span className="details" style={{ textTransform: "capitalize" }}>
              {enquriyInfo.registration_address
                ? enquriyInfo.registration_address.city || "-"
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">State</Typography>
            <span className="details" style={{ textTransform: "capitalize" }}>
              {enquriyInfo.registration_address
                ? enquriyInfo.registration_address.state || "-"
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Pincode</Typography>
            <span className="details">
              {enquriyInfo.registration_address
                ? enquriyInfo.registration_address.pincode || "-"
                : "-"}
            </span>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default ViewEnquiryAddress;
