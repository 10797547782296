import { TextField } from "@mui/material"
import { useState } from "react"
import { IconButton, InputAdornment } from "@mui/material";
import CancelOutlined from "@mui/icons-material/CancelOutlined";

const SearchByName = ({ captureValue }) => {

    const [name, setName] = useState()
    return (
        <>
            <TextField
                sx={{ border: "1px solid" }}
                placeholder="Customer Name"
                value={name}
                onChange={(e) => {
                    setName(e.target.value)
                    captureValue(e.target.value)
                }}
                InputProps={{

                    endAdornment: (
                        <InputAdornment position="start">
                            {name ?
                                <IconButton onClick={() => {
                                    setName("")
                                    captureValue("")

                                }}>
                                    <CancelOutlined />
                                </IconButton> : ""}
                        </InputAdornment>
                    )
                }}
            />
        </>
    )
}
export default SearchByName