import React from "react";
import { useState } from "react";

const ProfileUploader = (props) => {
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [baseURL, setBaseURL] = useState(
    "https://cresco-test.s3.ap-south-1.amazonaws.com/"
  );
  const [profilePic, setProfilePic] = useState(props.profile_pic);

  console.log(props);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;

      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }

    e.preventDefault();
    var formdata = new FormData();
    formdata.append("file", file);
    var requestOptions = {
      method: "POST",
      body: formdata,
    };
    fetch(
      "${process.env.REACT_APP_API_BASE_URL}/fileupload/upload",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        result = JSON.parse(result);
        setProfilePic(result.data.key);
        console.log("after images", profilePic);
      })
      .catch((error) => console.log("error", error));
  };

  let handleUpdate = async (e) => {
    console.log("profile upload");
  };

  return (
    <div>
      <form id="form" onSubmit={handleUpdate}>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          ref={imageUploader}
          id="file"
          multiple
          style={{
            display: "none",
          }}
        />
        <div
          style={{
            height: "60px",
            width: "60px",
            border: "1px dashed black",
          }}
          onClick={() => imageUploader.current.click()}
        >
          <img
            alt=""
            ref={uploadedImage}
            style={{
              width: "100%",
              height: "100%",
              position: "acsolute",
            }}
            src={baseURL + profilePic}
          />
        </div>
      </form>
    </div>
  );
};

export default ProfileUploader;
