import { TextField } from "@mui/material"
import { useState } from "react"
import { IconButton, InputAdornment } from "@mui/material";
import CancelOutlined from "@mui/icons-material/CancelOutlined";

const SearchByPhoneNumber = ({ captureValue }) => {

    const [phoneNumber, setPhoneNumber] = useState()
    return (
        <>
            <TextField
                sx={{ border: "1px solid" }}
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => {
                    setPhoneNumber(e.target.value)
                    captureValue(e.target.value)
                }}
                InputProps={{

                    endAdornment: (
                        <InputAdornment position="start">
                            {phoneNumber ?
                                <IconButton onClick={() => {
                                    setPhoneNumber("")
                                    captureValue("")

                                }}>
                                    <CancelOutlined />
                                </IconButton> : ""}
                        </InputAdornment>
                    )
                }}
            />
        </>
    )
}
export default SearchByPhoneNumber