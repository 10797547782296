import moment from "moment";

export const getDate = (timeString) => {
    if (timeString) {
        let date = moment(new Date(timeString)).format('DD MMM')
        return date;
    } else return 'NA';
}

export const getTime = (timeString) => {
    if (timeString) {
        let date = moment(new Date(timeString)).format('DD/MM/YYYY hh:mm A')
        return date;
    }
    return 'NA';
}

export const getDateWithYear = (timeString) => {
    if (timeString) {
        let date = moment(new Date(timeString)).format('DD/MM/YYYY')
        return date;
    }
    return 'NA';
}

export const getDateWithYearForApi = (timeString) => {
    console.log(timeString);
    if (timeString) {
        let date = moment(new Date(timeString)).format('YYYY-MM-DD')
        return date;
    }
    return 'NA';
}

