//react
import * as React from "react";
import { useState, useEffect, createContext } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Box, Card, Typography } from "@mui/material";

import ViewEnquiryDMS from "./ViewEnquiryDMS";
import ViewEnquiryPersonal from "./ViewEnquiryPersonal.js";
import ViewEnquiryCarInfo from "./ViewEnquiryCarInfo.js";
import ViewEnquiryOtherInfo from "./ViewEnquiryOtherInfo";
import ViewBookingInfo from "./ViewBookingInfo.js";
import ViewEnquiryUpload from "./ViewEnquiryUpload.js";
import ViewEnquiryAddress from "./ViewEnquiryAddress";
import Progress from "../../Progress";
import ViewDiscounts from "./ViewDiscounts";
import ViewAccessories from "./ViewAccessories";
import { Grid } from "semantic-ui-react";
import AddPayment from "../Add/AddPayment";
import ViewRetail from "./ViewRetail/ViewRetail";
import ViewFinanceInfo from "./viewFinance/ViewFinanceInfo";
import { fontSize } from "@mui/joy/styles/styleFunctionSx";

//Creating the store
export const store = createContext();

function ViewProgress() {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // local state
  const [value, setValue] = React.useState(0);
  const [allValidationErrors, setAllValidationErrors] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [showLoading, setShowLoading] = useState(false);
  const [status, setStatus] = useState();
  const [message, setMessage] = useState();

  // pagination url
  useEffect(() => {
    getDetails();

    if (searchParams.get("tab") == "booking") {
      setValue(1);
    } else {
      setValue(0);
    }
  }, []);

  //empty object for store the enquiryinfo
  const [enquriyInfo, setEnquriyInfo] = React.useState({
    dms_number: "",
    enquiry_date: new Date(),
    sales_representative_id: "",
    occupation: "",
    enquiry_through: "",
    other_enquiry_through: "",
    enquiry_notes: "",
    personal_information: {
      first_name: "",
      last_name: "",
      address_line_1: "",
      address_line_2: "",
      town: "",
      state: "",
      state1: "",
      pincode: "",
      phone_number_r: "",
      phone_number_o: "",
      phone_number_c: "",
      fax: "",
      email_id: "",
      occupation: "",
      date_of_birth: "",
    },
    car_information: {
      model: "",
      version: "",
      color: "",
      year: "",
      transmission: "",
      car_body: "",
      price: "",
    },
    corporation_details: {
      company_name: "",
      contact_person: "",
      contact_number: "",
      designation: "",
    },
    booking_info: {
      booking_date: null,
      expected_date_of_registration: null,
      expected_date_of_delivery: null,
      other_vehicles_own: true,
      exchange: true,
      exchange_type: "",
      e_w: true,
      e_w_amount: "",
      auto_card: true,
      auto_card_amount: "",
      vehicle_status: "",
      in_house_finance_company: false,
      b_a: false,
      special_no: false,
      affidavit: true,
      hypothecation: false,
      normal_pricing: {
        ex_showroom: "",
        insurance: "",
        reg_charges_incl_of_all_taxes: "",
      },
      on_road_price: "",
      discounts: [],
      total_discounts: "",
      price_after_discounts: "",
      less_by_party_reg: "",
      less_by_party_insurance: "",
      net_price_to_collect: "",
      extra_accessories_to_be_put_free_of_cost: {
        title: "",
        price: "",
      },
      booking_amount: "",
    },
    finance_info: {
      financier_status: null,
      direct_payment_status: null,
      financier: {
        financier_name: "",
        finance_amount: "",
        finance_expected_date: ""
      },
      direct_payment: {
        amount: null,
        expected_date: ""
      },
      d_s_a: "",
      contact_person: "",
      contact_number_o: "",
      contact_number_c: ""
    },
    retail_info: {
      insurance: {
        by_us: null,
        by_party: null,
        by_financier: null,
        insurance_co: '',
        cover_note_no: '',
        cover_note_date: ''
      },
      registration: {
        by_us: null,
        by_party: null,
        temporary: null,
        permanent: null
      },
      extra_accessories: []
    },
    status: "",
  });

  //event for logout
  const handleShow = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });

    navigate("/");
  };

  //event
  let getDetails = async () => {
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/enquiries/${params.id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          "content-type": "application-json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      setShowLoading(true);
      let res = await fetch(url, options);
      let getdata = await res.json();
      console.log(getdata.data.data)

      if (res.status == 200 || res.status == 201) {

        setStatus(getdata.data.status);
        setShowLoading(false);
        getdata.data.actual_status = getdata.data.status;

        if (!getdata.data.normal_pricing) {
          getdata.data.normal_pricing = {
            ex_showroom: null,
            insurance: null,
            reg_charges_incl_of_all_taxes: null,
          };
        }

        if (!getdata.data.corporation_details) {
          getdata.data.corporation_details = {
            contact_person: "",
            contact_number: "",
            designation: "",
          };
        }

        if (!getdata.data.discounts) {
          getdata.data.discounts = {
            premium_woi: null,
            wheels_of_india: null,
            consumer: null,
            msil_schema_for_the_month: null,
            free_insurance: null,
            corporate_discount: null,
            two_ka_four_discounts: null,
            exchange_bonus: null,
            school_bus_offer: null,
            deal_based_offer: null,
            gold_coin_offer: null,
            extra_discount: null,
          };
        }
        setEnquriyInfo({ ...enquriyInfo, ...getdata.data.data });
      } else if (res.status == 422) {
        setMessage("");
      } else if (res.status == 403) {
        handleShow();
        navigate("/")
      }
    } catch (err) {
      console.error(err);
    }
  };

  //render UI
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Grid container xs={12}>
        <div style={{ display: "inline-flex", gap: "20px" }}>
          <Grid item xs={8}>
            {value == 0 ? (
              <form style={{ display: "inline-grid" }}>
                <div>
                  <ViewEnquiryDMS enquriyInfo={enquriyInfo} />
                </div>
                <div>
                  <ViewEnquiryPersonal enquriyInfo={enquriyInfo} />
                  <ViewEnquiryAddress enquriyInfo={enquriyInfo} />
                  <ViewEnquiryCarInfo enquriyInfo={enquriyInfo} />
                  <ViewEnquiryOtherInfo enquriyInfo={enquriyInfo} />
                  <ViewBookingInfo enquriyInfo={enquriyInfo} />
                  <ViewDiscounts enquriyInfo={enquriyInfo} />
                  <ViewAccessories enquriyInfo={enquriyInfo} />
                  {/* <ViewFinanceInfo enquriyInfo={enquriyInfo} />
                  <ViewRetail enquriyInfo={enquriyInfo} /> */}

                  <ViewEnquiryUpload enquriyInfo={enquriyInfo} />
                  {/* <AddPayment/> */}
                </div>
              </form>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                position: "sticky",
                right: "0px",
                top: "130px",
                zIndex: "999",
                margin: "2.15rem 0 -3rem",
                cursor: "pointer",
                marginBottom: "-3rem",
              }}
            >

              <Progress
                equeryData={enquriyInfo}
                orientation="vertical"
              ></Progress>
            </div>
          </Grid>
        </div>
      </Grid>
    </Box>
  );
}

export default ViewProgress;
