// react
import * as React from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Container } from "@mui/system";

import "./BaseLayout.scss";

function BaseLayout() {
  // store selector
  const signInSliceData = useSelector((state) => state.signIn);

  // route methods
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // local state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showLeft, setShowLeft] = React.useState();
  const open = Boolean(anchorEl);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleLeft = () => {
  //   setShowLeft();
  // };

  const handleShow = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });

    navigate("/");
  };
  const goToProfile = () => {
    navigate("/profile")
  };
  
  const handleUpdatePassword = () => {
    navigate("/profile/edit")
  };

  // events
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  return (
    <Box id="BaseLayout" sx={{ display: "flex", }}>
      <CssBaseline />
      <Box position="fixed" sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,

      }}>
        <AppBar className="top-bar">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <img src={require("../img/logo1.png")} alt="" />
            <div className="right-side-block">
              {/* <SearchAppBar></SearchAppBar> */}

              <div>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  style={{ color: "#333333", fontWeight: "500"}}
                >
                  {signInSliceData.signInSuccess.userDetails.userData?.first_name}

                  <Avatar src={require("../img/Profile.png")} alt=""></Avatar>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >                  
                  <MenuItem onClick={goToProfile}>Profile</MenuItem>
                  <MenuItem onClick={handleUpdatePassword}>Update Password</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={handleShow}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
          </Grid>
        </AppBar>
      </Box>
      <Drawer
        variant="permanent"
        sx={{
          width: 275,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 275, boxSizing: "border-box" },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }} pt={3}>
          <Typography className="menu-list text-xl">MAIN MENU</Typography>
          <List className="side-menu-bar">
            <NavLink
              to="/dashboard"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <ListItemButton
                className="list-btn"
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0)}
              >
                <ListItemIcon
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                >
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText className="list-item">Dashboard</ListItemText>
              </ListItemButton>
            </NavLink>

            <NavLink
              to="/enquiries"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <ListItemButton
                className="list-btn"
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1)}
              >
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText className="list-item">Enquiries</ListItemText>
              </ListItemButton>
            </NavLink>

            <NavLink
              to="/sales-representative"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <ListItemButton
                className="list-btn"
                selected={selectedIndex === 4}
                onClick={(event) => handleListItemClick(event, 4)}
              >
                <ListItemIcon>
                  <SupervisorAccountIcon />
                </ListItemIcon>

                <ListItemText className="list-item">Sales Rep</ListItemText>
              </ListItemButton>
            </NavLink>

            <NavLink
              to="/cars"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <ListItemButton
                className="list-btn"
                selected={selectedIndex === 6}
                onClick={(event) => handleListItemClick(event, 6)}
              >
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>

                <ListItemText className="list-item">Cars List</ListItemText>
              </ListItemButton>
            </NavLink>
          </List>
        </Box>
      </Drawer>
      <div style={{ width: "100%" }}>
        <Outlet />
      </div>
    </Box>
  );
}
export default BaseLayout;
