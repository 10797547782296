import * as React from "react";
import { useState } from 'react';
import { json, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, Container, Grid } from "@mui/material";
import EnquiriesGraph from "./EnquiriesGraph";
import LeadsGraph from "./Leads";
import StatisticsData from "./Statistics";
import RecentEnquiries from "./RecentEnquiries";
import SalesRepresentativeDeliveries from "./SalesRespDeliveries";
import SalesRespEnquiries from "./SalesRespEnquiries";
import { setStatusValidations } from "../../storeSlices/equirySlice";

function Dashboard() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setStatusValidations([]))
  }, [])


  return (
    <div id="Dashboard" style={{ marginTop: "11%", }}>
      <Container>
        <div className="content">
          <p></p>
          <Grid container>
            <Grid xs={6}>
              <StatisticsData />
            </Grid>

            <Grid xs={6}>
              <LeadsGraph />

            </Grid>

          </Grid>

          <Grid container sx={{ marginTop: "10%" }}>
            <Grid xs={6}>
              <EnquiriesGraph />
            </Grid>
            <Grid xs={6}>
              <RecentEnquiries />


            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "10%" }}>
            <Grid xs={6}>
              <SalesRepresentativeDeliveries />
            </Grid>
            <Grid xs={6}>
              <SalesRespEnquiries />
            </Grid>
          </Grid>

          <Grid container sx={{ marginTop: "10%" }}>
            <Grid xs={6}>

            </Grid>
            <Grid xs={6}>

            </Grid>
          </Grid>
        </div>
      </Container >

      {/* 
      < Backdrop open={loading} sx={{ background: "white", opacity: ".2" }
      }>
        <img
          src={"/208.png"}
          alt="bg-image"
          height='100px'
          width='100px'
        />
      </Backdrop > */}
    </div >
  );
}

export default Dashboard;
