//react
import { useEffect, useContext } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { Card, Grid, Typography } from "@mui/material";
import { store } from "./ViewProgress";
import moment from "moment";

import "../scss/view/_viewBookingInfo.scss";

function ViewBookingInfo({ enquriyInfo }) {

  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    // dateCheck();
  }, []);

  return (
    <div id="BookingView">
      <Card className="booking" variant="outlined">
        <div className="heading">
          <span className="title">Booking Information</span>
        </div>
        {/* <div style={{ float: "left", width: "8%", paddingBottom: "10px" }}>
          <Link to={`/enquiries/${params.id}/edit?tab=booking`}>
            <Button variant="outlined" color="error" size="small">
              <IconButton color="error" size="small">
                <EditIcon />
              </IconButton>
            </Button>
          </Link>
        </div> */}
        <Grid container spacing={1} rowGap={2}>
          <Grid item xs={4}>
            <Typography className="viewtitle">Booking Date</Typography>
            <span className="details">
              {/* {bookingDate == "Invalid date" ? "-" : bookingDate} */}
              {moment(enquriyInfo.booking_info?.booking_date).format(
                "DD-MM-YYYY"
              ) == "Invalid date"
                ? "-"
                : moment(enquriyInfo.booking_info.booking_date).format(
                  "DD-MM-YYYY"
                )}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">
              Expected Dt of Delivery
            </Typography>
            <span className="details">
              {/* {exDeDate == "Invalid date" ? "-" : exDeDate} */}
              {moment(
                enquriyInfo.booking_info.expected_date_of_delivery
              ).format("DD-MM-YYYY") == "Invalid date"
                ? "-"
                : moment(
                  enquriyInfo.booking_info.expected_date_of_delivery
                ).format("DD-MM-YYYY")}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">
              Expected Dt of Registration
            </Typography>
            <span className="details">
              {/* {exReDate == "Invalid date" ? "-" : exReDate} */}
              {moment(
                enquriyInfo.booking_info.expected_date_of_registration
              ).format("DD-MM-YYYY") == "Invalid date"
                ? "-"
                : moment(
                  enquriyInfo.booking_info.expected_date_of_registration
                ).format("DD-MM-YYYY")}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Other Vehicles owned</Typography>
            <span className="details">
              {enquriyInfo.booking_info.other_vehicles_own == true
                ? "Yes"
                : enquriyInfo.booking_info.other_vehicles_own == false
                  ? "No"
                  : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Exchange</Typography>
            <span className="details">
              {enquriyInfo.booking_info.exchange == true
                ? "Yes"
                : enquriyInfo.booking_info.exchange == false
                  ? "No"
                  : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Vehicle Status</Typography>
            <span className="details">
              {enquriyInfo.booking_info.vehicle_status
                ? enquriyInfo.booking_info.vehicle_status
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">BA</Typography>
            <span className="details">
              {enquriyInfo.booking_info.b_a == true
                ? "Yes"
                : enquriyInfo.booking_info.b_a == false
                  ? "No"
                  : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Special No</Typography>
            <span className="details">
              {enquriyInfo.booking_info.special_no == true
                ? "Yes"
                : enquriyInfo.booking_info.special_no == false
                  ? "No"
                  : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Affidavit</Typography>
            <span className="details">
              {enquriyInfo.booking_info.affidavit == true
                ? "Yes"
                : enquriyInfo.booking_info.affidavit == false
                  ? "No"
                  : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">EW</Typography>
            <span className="details">
              {enquriyInfo.booking_info.e_w == true
                ? "Yes"
                : enquriyInfo.booking_info.e_w == false
                  ? "No"
                  : "-"}
            </span>
          </Grid>
          <Grid item xs={6}>
            <Typography className="viewtitle">AC</Typography>
            <span className="details">
              {enquriyInfo.booking_info.auto_card == true
                ? "Yes"
                : enquriyInfo.booking_info.auto_card == false
                  ? "No"
                  : "-"}
            </span>
          </Grid>
          {enquriyInfo.booking_info.e_w == true && (
            <Grid item xs={4}>
              <Typography className="viewtitle">EW Amount</Typography>
              <span className="details">
                {enquriyInfo.booking_info.e_w_amount
                  ? "₹ " +
                  enquriyInfo.booking_info.e_w_amount
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                  : "-"}
              </span>
            </Grid>
          )}
          {enquriyInfo.booking_info.auto_card == true && (
            <Grid item xs={6}>
              <Typography className="viewtitle">AC Amount</Typography>
              <span className="details">
                {enquriyInfo.booking_info.auto_card_amount
                  ? "₹ " +
                  enquriyInfo.booking_info.auto_card_amount
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                  : "-"}
              </span>
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
}

export default ViewBookingInfo;
