// react
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import {
  Card,
  TextField,
  Typography,
  Button,
  Container,
  Alert,
  Snackbar,
  AlertTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import "../components/enquiries/scss/UpdatePassword.scss";

const UpdatePassword = () => {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);
  // route methods
  const navigate = useNavigate();
  // local state
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [success, setSuccess] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [allValidationErrors, setAllValidationErrors] = useState("");

  // React Hooks
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    handleUpdate();
  }, []);

  // edit and update password
  let handleUpdate = async (e) => {
    console.log("onclick Update buton");
    e.preventDefault();
    try {
      setAllValidationErrors("");
      setValidationErrors("");
      setSuccess("");
      setShowLoading(true);
      let url = `${process.env.REACT_APP_API_BASE_URL}/users/update-password`;
      let options = {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          //authorization:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6eyJfaWQiOiI2MzNmYmFiOWIyM2IyOTdkMTBlY2FhNmIiLCJmaXJzdF9uYW1lIjoicHJlZXRoaSIsImxhc3RfbmFtZSI6InZlbGxhIiwidXNlcl9uYW1lIjoiYWRtaW4iLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsInBob25lIjoiOTYxODM3MTIxMCIsInBhc3N3b3JkIjoiJDJiJDEwJFMxTEpxbWFSTk9UMFZoTGNwd25pYWVtTlF3dzgvTVB1ZUptcm9FL2pCS1plcjB2aEkxRGxpIiwidXNlcl90eXBlIjoiQURNSU4iLCJjcmVhdGVkQXQiOiIyMDIyLTEwLTA3VDA1OjM1OjUzLjU2OFoiLCJ1cGRhdGVkQXQiOiIyMDIyLTEwLTE1VDExOjQ5OjU1LjQ5NloiLCJfX3YiOjB9LCJpZCI6IjYzM2ZiYWI5YjIzYjI5N2QxMGVjYWE2YiIsImlhdCI6MTY2NTk3MjA3MiwiZXhwIjoxNjY2ODM2MDcyfQ.2AcdVhutjccfRcXRuv7c6kRm0W55qUjJ-Ot7vcCg7KI",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
        body: JSON.stringify({
          password: password,
          confirmPassword: confirmPassword,
        }),
      };
      setShowLoading(false);
      let res = await fetch(url, options);
      responseData = await responseData.json();
      let responseData = await res.json();
      if (res.status === 200 || res.status === 201) {
        setShowSuccessAlert(true);
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate("/");
        }, 1500);
        setSuccess(responseData.message);
        setPassword("");
        setConfirmPassword("");
      } else if (res.status === 401) {
        setValidationErrors(responseData.message);
      } else if (res.status === 422) {
        let allError = responseData.errors;
        setAllValidationErrors(allError);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div id="UpdatePassword">
      <Container>
        <br></br>
        <Box className="update-inner-block">
          <div>
            <Card className="card-block">
              <div className="card-inner">
                <form onSubmit={handleUpdate}>
                  <div>
                    <p>Update Password</p>
                    <TextField
                      size="small"
                      label="Password"
                      variant="outlined"
                      type={"password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      helpertext={allValidationErrors["password"]}
                      error={allValidationErrors["password"]}
                      sx={{ mb: 2, background: "#f6f7f9" }}
                    />
                  </div>
                  <div>
                    <TextField
                      sx={{ background: "#f6f7f9" }}
                      size="small"
                      label="ConfirmPassword"
                      variant="outlined"
                      type={"confirmPassword"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      helpertext={allValidationErrors["confirmPassword"]}
                      error={allValidationErrors["confirmPassword"]}
                    />
                  </div>
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {validationErrors}
                  </Typography>
                  {showLoading === true ? <p> Updating Password ... </p> : ""}
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={showLoading === true ? true : false}
                    style={{ marginTop: "2em" }}
                  >
                    Update
                  </Button>
                </form>

                <Snackbar
                  open={showSuccessAlert}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    Password updated successfully
                  </Alert>
                </Snackbar>

                <Typography
                  variant="h6"
                  color="success"
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                    color: "green",
                  }}
                >
                  {success}
                </Typography>
              </div>
            </Card>
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default UpdatePassword;
