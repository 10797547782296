//react
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

//importing redux store
import { useSelector } from "react-redux";

//importing MaterialUI
import {
  FormControl,
  OutlinedInput,
  MenuItem,
  Select,
  Typography,
  Card,
  Grid,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//importing monent
import moment from "moment";
import "../scss/add/AddEnquiryDMS.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddEnquiryDMS(props) {

  console.log('test', props.enquriyInfo.sales_representative_id)
  // store selector
  const params = useParams();
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // local state
  const [_id, setId] = useState("");
  const [dms_number, setDmsNumber] = useState("");
  const [dateValue, setDateValue] = useState();
  const [salesrepresentative, setSalesRepresentative] = useState([]);
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    getSalesData();
  }, []);

  // get all setSalesRepresentative data
  const getSalesData = async () => {
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/sales-representatives?page=1&take=100`;
      let options = {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let getsalesdata = await fetch(url, options);
      getsalesdata = await getsalesdata.json();
      console.log(getsalesdata);

      setSalesRepresentative(getsalesdata.data);
    } catch (err) {
      console.error(err);
    }
  };


  // handle on Enquiry Date change
  const handleDateValueChange = (evt) => {
    setDateValue(
      new Date(moment(new Date(evt)).format("YYYY-MM-DD")).toISOString()
    );
    props.enquriyInfo.enquiry_date = new Date(
      moment(new Date(evt)).format("YYYY-MM-DD")
    ).toISOString();
  };

  // const restError=(key)=>{
  //   props.allValidationErrors[key]=""
  // }

  return (
    <div id="AddDMS">
      <Typography className="enquiry-title">{params.id ? "Edit Enquiry" : "New Enquiry"}</Typography>
      <Card className="add-dms-block" variant="outlined">
        <Grid className="block-inner" container spacing={2}>
          <Grid className="form-block" item xs={3}>
            <FormControl className="form-inner">
              <Typography className="title">
                # DMS<strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              {params.id ? (
                <TextField
                  className="text-field"
                  id="outlined-basic"
                  variant="standard"
                  name="dms"
                  disabled
                  inputProps={{ maxLength: 10 }}
                  value={props.enquriyInfo.dms_number}
                  onChange={(e) => {
                    setDmsNumber(e.target.value);
                    props.enquriyInfo.dms_number = e.target.value;
                    props.allValidationErrors["dms_number"] = null;
                  }}
                  error={
                    props.allValidationErrors
                      ? props.allValidationErrors["dms_number"]
                      : ""
                  }
                />
              ) : (
                <TextField
                  className="text-field"
                  id="outlined-basic"
                  variant="standard"
                  name="dms"
                  inputProps={{ maxLength: 10 }}
                  value={props.enquriyInfo.dms_number}
                  onChange={(e) => {
                    setDmsNumber(e.target.value);
                    props.enquriyInfo.dms_number = e.target.value;
                    props.allValidationErrors["dms_number"] = null;
                  }}
                  error={
                    props.allValidationErrors
                      ? props.allValidationErrors["dms_number"]
                      : ""
                  }
                />
              )}
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["dms_number"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={3}>
            <FormControl className="form-inner">
              <Typography className="title">
                Enquiry Date
                <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  className="text-field"
                  inputFormat="DD/MM/YYYY"
                  mask="__/__/____"
                  value={props.enquriyInfo.enquiry_date}
                  onChange={handleDateValueChange}
                  renderInput={(params) => (
                    <TextField onKeyDown={onKeyDown} {...params} />
                  )}
                  disableFuture={true}
                />
              </LocalizationProvider>
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["enquiry_date"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={6}>
            <FormControl variant="filled" className="form-inner">
              <Typography
                id="demo-simple-select-filled-label"
                className="title"
              >
                Sales Representative
                <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              <Select
                className="text-field"
                displayempty="true"
                // disabled={true}
                name="sald"
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={props.enquriyInfo.sales_representative_id}
                onChange={(e) => {
                  setId(e.target.value);
                  props.enquriyInfo.sales_representative_id = e.target.value;
                }}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                error={
                  props.allValidationErrors && _id
                    ? ""
                    : props.allValidationErrors["sales_representative_id"]
                }
              >
                <MenuItem disabled value="">
                  <em>
                    Select Sales Representative
                    <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                  </em>
                </MenuItem>
                {salesrepresentative.map((sales) => (
                  <MenuItem key={sales._id} value={sales._id}>
                    {sales.name}
                  </MenuItem>
                ))}
              </Select>
              {_id ? (
                ""
              ) : (
                <Typography
                  variant="h6"
                  color="error"
                  style={{ fontSize: "12px", paddingLeft: "15px" }}
                >
                  {props.allValidationErrors
                    ? props.allValidationErrors["sales_representative_id"]
                    : ""}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default AddEnquiryDMS;
