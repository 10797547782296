import { createSlice } from '@reduxjs/toolkit'

export const eqnuirySlice = createSlice({
    name: 'eqnuiry',
    initialState: {
        eqnuries_list: {

        },
        single_equiry: {

        },
        statusValidations: [],
        setStatusValue: ""
    },
    reducers: {
        setEquriesList: (state, data) => {
            state.eqnuries_list = data.payload
        },
        setSingleEquery: (state, data) => {
            state.single_equiry = data.payload
        },
        setStatusValidations: (state, data) => {
            state.statusValidations = data.payload
        },
        setStatusName: (state, data) => { state.setStatusValue = data.payload }
    }
})

// Action creators are generated for each case reducer function
export const { setEquriesList, setSingleEquery, setStatusValidations, setStatusName } = eqnuirySlice.actions

export default eqnuirySlice.reducer