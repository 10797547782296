//react
import { Box, Button, TextField, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import "./SignIn.scss";
import { useSignInRequest } from "./../services/authService";
function SignIn() {
  // store selectors
  const signInSliceData = useSelector((state) => state.signIn);
  console.log(signInSliceData)

  // service methods
  const signInRequest = useSignInRequest();

  // route method
  const navigate = useNavigate();

  // local state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [AllValidationErrors, setAllValidationErrors] = useState("");

  // React Hooks
  useEffect(() => {
    if (signInSliceData.signInSuccess) {
      navigate("/enquiries");
    }
  }, []);

  // events
  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setShowLoading(true);
      setErrorMessage();
      setAllValidationErrors("");
      let signInCopyData = await signInRequest(email, password);
      if (signInCopyData.signInSuccess != null) {
        navigate("/enquiries");
      } else if (signInCopyData.signInValidation != null) {
        let allError = signInCopyData.signInValidation;
        setAllValidationErrors(allError);
      } else if (signInCopyData.signInFailed != null) {
        setErrorMessage(signInCopyData.signInFailed);
      } else if (signInCopyData.signInValidation != null) {
        console.log("login error", signInCopyData.signInValidation);
      }
      // store success -> navitgeate
      // store message -> error
      // store errors -> validations
    } catch (err) {
      console.log(err);
    }
    setShowLoading(false);
  };

  return (
    <div id="signIn">
      <Grid container spacing={2} rowSpacing={1} alignItems="center" justifyContent={'space-between'} sx={{minHeight:"100vh"}}>
        <Grid item xs={7}>
          <div className="logo">
            <img
              src={require("../img/logo.png")}
              alt="Logo"
              className="image"
            />
          </div>
          <img
            src={require("../img/sign-in-bg.png")}
            alt="bg-image"
            className="bg-image"
          />
        </Grid>
        <Grid item xs={4}>
          <Box className="form">
            <Typography
              sx={{ fontSize: "32px", lineHeight: "40px", color: "#1A1A1A" }}
            >
              Login
            </Typography>
            <Typography mb={3} className="tagline" component="p">
              Enter your details below to continue
            </Typography>
            <form onSubmit={handleSubmit}>
              <div>
                <Typography sx={{ color: "#1A1A1A", fontSize: "16px" }} mt={4}>
                  Email
                </Typography>
                <TextField
                  className="username"
                  size="small"
                  placeholder="Email"
                  variant="outlined"
                  sx={{ width: "100%", height: "36px", 
                }}
                  type={"text"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ background: "#fff" }}
                  error={AllValidationErrors["email"]}
                />
                <Typography color="error" style={{ fontSize: "12px" }}>
                  {AllValidationErrors ? AllValidationErrors["email"] : ""}
                </Typography>
                <Typography
                  sx={{
                    marginTop: "1.5em",
                    color: "#1A1A1A",
                    fontSize: "16px",
                  }}
                >
                  Password
                </Typography>
                <TextField
                  size="small"
                  placeholder="Password"
                  variant="outlined"
                  sx={{ width: "100%", height: "36px" }}
                  type={"password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ background: "#fff" }}
                  error={AllValidationErrors["password"]}
                />
                <Typography color="error" style={{ fontSize: "12px" }}>
                  {AllValidationErrors ? AllValidationErrors["password"] : ""}
                </Typography>
              </div>
              <Typography variant="h6" color="error">
                {errorMessage}
              </Typography>
              {showLoading === true ? <p> Signing in ... </p> : ""}
              <Button
                type="submit"
                variant="contained"
                disabled={showLoading === true ? true : false}
                style={{
                  marginTop: "2.5em",
                  width: "100%",
                  background: "#3563E9",
                  borderRadius: "4px",
                  lineHeight: "24px",
                  fontSize: "16px",
                  color: "#fff",
                }}
              >
                Login
              </Button>
            </form>
            <Typography mt={3} className="copyright-text">
              @ All rights reserved
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
export default SignIn;
