//react
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import { store } from "./ViewProgress";

//material Ui
import { Backdrop, Card, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import "../scss/view/_viewDMS.scss";
import { setStatusName, setStatusValidations } from "../../../storeSlices/equirySlice";

function ViewEnquiryDMS({ enquriyInfo }) {

  console.log(enquriyInfo);
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  //local states
  const [message, setMessage] = useState("Something went wrong !");
  const [loading, setLoading] = useState(false);

  // route methods
  const navigate = useNavigate();
  const params = useParams();
  console.log(params)
  const dispatch = useDispatch();

  // local state
  const [salesrepresentative, setSalesRepresentative] = useState([]);
  const Swal = require("sweetalert2");

  // pagination url
  useEffect(() => {

    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }

    getSalesData();
  }, []);

  //event for logout
  const handleForbidenError = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });

    navigate("/");
  };

  // get all setSalesRepresentative data
  const getSalesData = async () => {
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/sales-representatives?page=1&take=10`;
      let options = {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let result = await fetch(url, options);
      result = await result.json().then((getsalesdata) => {
        setSalesRepresentative(getsalesdata.data);
      });
    } catch (err) {
      console.error(err);
    }
  };

  //event for Status Change
  const handleStatusChange = async (e) => {
    dispatch(setStatusName(e.target.value))

    setLoading(true);
    try {
      let enquriyInfoModifiedData = enquriyInfo;
      enquriyInfoModifiedData.id = enquriyInfoModifiedData._id;
      delete enquriyInfoModifiedData._id;
      enquriyInfoModifiedData.sales_representative_id = enquriyInfoModifiedData.sales_representative_id._id;
      enquriyInfoModifiedData.car_information.year = +enquriyInfoModifiedData.car_information.year;
      let data = {
        ...enquriyInfoModifiedData,
        status: e.target.value,
      }
      console.log(data);
      // let docUrl = `${process.env.REACT_APP_API_BASE_URL}/enquiries/update-status/${params.id}`;
      let docUrl = `${process.env.REACT_APP_API_BASE_URL}/enquiries`;

      let docOptions = {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
        body: JSON.stringify(data),
      };
      let response = await fetch(docUrl, docOptions);
      console.log(response)
      let details = await response.json();
      console.log(details)

      if (response.status == 200 || response.status == 201) {
        setLoading(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: `Your status changed to ${e.target.value[0] + e.target.value.toLowerCase().slice(1)
            }`,
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (details.status == 422) {
        dispatch(setStatusValidations(details?.errors))

        navigate(`/enquiries/${enquriyInfo.id}/edit?from=view&status=${e.target.value} `);
      } else if (details.status == 403) {
        handleForbidenError();
      } else {
        setMessage(details.message || "Something went wrong !");
        Swal.fire({
          title: details.error,
          icon: "info",
        });
        handleForbidenError();

      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  //render Ui
  return (
    <div id="viewDMS">
      <Card className="dms-info" variant="outlined">
        <div className="block-inner">
          <div className="block-item">
            <Typography className="title">
              # DMS :<span className="details">{enquriyInfo.dms_number}</span>
            </Typography>
          </div>
          <div className="block-item">
            <Typography className="title">
              Date :
              <span className="details">
                {moment(enquriyInfo.enquiry_date).format("DD-MM-YYYY")}
              </span>
            </Typography>
          </div>
          <div className="block-item">
            <Typography className="title">
              Sales Rep :
              <span className="details">
                {enquriyInfo.sales_representative_id.name}
              </span>
            </Typography>
          </div>
          <div className="block-item">
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <Typography className="title">Status :</Typography>
              <FormControl sx={{ width: "140px", paddingLeft: "10px" }}>
                <Select
                  labelid="demo-simple-select-label"
                  id="demo-simple-select"
                  value={enquriyInfo.status}
                  selected={true}
                  sx={{
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                  onChange={handleStatusChange}
                >
                  <MenuItem sx={{ fontSize: "12px" }} value="ENQUIRY">
                    ENQUIRY
                  </MenuItem>
                  <MenuItem sx={{ fontSize: "12px" }} value="BOOKING">
                    BOOKING
                  </MenuItem>
                  {/* <MenuItem sx={{ fontSize: "12px" }} value="FINANCE">
                    FINANCE
                  </MenuItem>
                  <MenuItem sx={{ fontSize: "12px" }} value="RETAIL">
                    RETAIL
                  </MenuItem>
                  <MenuItem sx={{ fontSize: "12px" }} value="CUSTOMER CARE">
                    Cust. Care
                  </MenuItem> */}
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
      </Card>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default ViewEnquiryDMS;
