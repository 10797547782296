import { Badge, Card, Typography } from '@mui/material';
import React, { PureComponent, useEffect, useState } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Label } from 'recharts';
import CircleIcon from '@mui/icons-material/Circle';
import { DateRangePicker, Stack } from 'rsuite';
import "rsuite/dist/rsuite.css";
import { useDispatch, useSelector } from 'react-redux';
import randomColor from 'randomcolor';
import isAfter from 'date-fns/isAfter';
import { getDateWithYearForApi } from '../pipes/timePipe';
import moment from 'moment';

const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } =
    DateRangePicker;

const SalesRepresentativeDeliveries = () => {
    const AuthStateData = useSelector((state) => state.signIn);
    const [defaultFromDate, setDefaultFromDate] = useState(getDateWithYearForApi(new Date('2023-07-01').toISOString()));
    const [defaultToDate, setDefaultToDate] = useState(getDateWithYearForApi(new Date().toISOString()));

    const [salesRepDeliveresData, setSalesRepEnquiresGraphData] = useState()
    const [carSoldCount, setCarSoldCount] = useState()

    useEffect(() => {
        getSaleRepEnquiriesGraphData()
    }, [])

    //date
    const [value, setValue] = React.useState([
        new Date(),
        new Date()
    ]);
    const [fromDate, setFromDate] = useState();




    //api call for get statisticsData
    const getSaleRepEnquiriesGraphData = async (fromDate = defaultFromDate, toDate = defaultToDate) => {
        try {
            const url = `${process.env.REACT_APP_API_BASE_URL}/enquiries/delivery/count?from_date=${fromDate}&to_date=${toDate}`;
            const options = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`
                }),
            };
            const response = await fetch(url, options);
            const responseData = await response.json();
            console.log(responseData)
            if (response.status == 200) {
                setSalesRepEnquiresGraphData(responseData.data);
                setCarSoldCount(responseData.cars_sold)
            }
            else if (response.status == 401) {
                setSalesRepEnquiresGraphData(null)
            }
            else {
                throw responseData;
            }

        } catch (err) {
            console.error(err);
        } finally {
        }
    };

    //colors array
    let colors = []




    return (
        <>

            <Card style={{ marginLeft: "20px", width: "90%", height: "100%" }}>
                <div style={{ height: "50px", display: "flex", width: "90%", padding: "20px", flexDirection: "row", alignContent: "center" }}>
                    <Typography variant='h6' style={{ width: "90%" }}>Sales-Reps -Deliveries</Typography>
                    <Stack direction="column" spacing={8} alignItems="flex-start">
                        <DateRangePicker
                            showOneCalendar
                            disabledDate={afterToday()}
                            defaultValue={[new Date(defaultFromDate), new Date()]}
                            value={fromDate}
                            onChange={(newDate) => {
                                setFromDate(newDate)
                                if (newDate) {
                                    let date1 = new Date(moment(new Date(newDate[0])).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                                    let date2 = new Date(moment(new Date(newDate[1])).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                                    getSaleRepEnquiriesGraphData(date1, date2)
                                }
                                else {
                                    let date1 = new Date(moment(new Date()).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                                    let date2 = new Date(moment(new Date()).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                                    getSaleRepEnquiriesGraphData(date1, date2)
                                }

                            }}
                        />
                    </Stack>
                </div>
                <div style={{ height: "200px" }}>
                    {salesRepDeliveresData == null || carSoldCount == 0 ?
                        <div style={{ marginTop: "30%", textAlign: "center " }}>
                            <Typography variant='title1'>No Cars Sold In Between Range</Typography>
                        </div> :
                        <div style={{ display: "flex", flexDirection: "row", width: "90%", height: "90%" }}>
                            <PieChart width={400} height={400} >
                                <Pie
                                    data={salesRepDeliveresData}
                                    cx={120}
                                    cy={200}
                                    innerRadius={60}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="count"
                                >


                                    {salesRepDeliveresData?.map((entry, index) => {
                                        let color = randomColor(index)
                                        colors.push(color)
                                        return < Cell key={`cell-${index}`} fill={color} />
                                    })}
                                    <Label value={carSoldCount} position="centerBottom" />
                                    <Label value={"Cars Sold"} position="centerTop" />


                                </Pie>
                            </PieChart>
                            <div>
                                {salesRepDeliveresData?.map((entry, index) => {
                                    if (entry.count == 0) {

                                    }
                                    else {

                                        return (<div key={index} style={{ display: "flex", alignItems: "center", justifyItems: "center", marginTop: "40%" }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <CircleIcon fontSize='20px' sx={{ color: colors[index] }} />
                                                <Typography variant='subtitle2' >{entry.salesRepresentativeName}</Typography>
                                                <Typography variant='subtitle2' sx={{ marginLeft: "20px" }}>{entry.count}</Typography>
                                            </div>
                                        </div>)
                                    }

                                })}
                            </div>
                        </div>}
                </div>
            </Card >


        </>
    )
}
export default SalesRepresentativeDeliveries