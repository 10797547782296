//react
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//import redux store
import { useSelector } from "react-redux";

//importing MaterialUI
import {
  Card,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  Grid,
  OutlinedInput,
  Box,
  Select,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import "../scss/add/AddEnquiryOthers.scss";

function AddEnquiryOthers(props) {

  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // local state
  const [ocupation, setOcupation] = useState("");
  const [enquiry_through, setEnquiryThrough] = useState("");
  const [notes, setNotes] = useState(props.enquriyInfo.enquiry_notes ? props.enquriyInfo.enquiry_notes : "");
  const [companyName, setCompanyName] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [designation, setDesignation] = useState('');


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
  });

  // Handle setting Occupation
  const handleOccupation = (event) => {
    setOcupation(event.target.value);
    props.enquriyInfo.occupation = event.target.value;
  };

  // handle setting Other in Enquiry Through
  const handleOthersDetails = (event) => {
    setEnquiryThrough(event.target.value);
    props.enquriyInfo.enquiry_through = event.target.value;
  };

  //function to reset the errors onChanging
  // const resetError = (keys) => {
  //   if (keys.length === 1) {
  //     props.allValidationErrors[keys[0]] = "";
  //   } else {
  //     keys &&
  //       keys.map((key) => {
  //         props.allValidationErrors[key] = "";
  //       });
  //   }
  // };

  return (
    <div id="addOthers">
      <Card className="add-others" variant="outlined">
        <div className="block-inner">
          <div className="block-item">
            <Typography className="title" mb={1}>
              Other Information
            </Typography>
          </div>
          <Grid className="block-inner" container spacing={3}>
            <Grid className="form-block" item xs={12}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  Occupation
                  <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>
                <Select
                  className="text-field"
                  name="occ"
                  sx={{ width: "26ch" }}
                  displayempty="true"
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={props.enquriyInfo.occupation}
                  label="Occupation"
                  onChange={handleOccupation}
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  error={
                    props.allValidationErrors && ocupation
                      ? ""
                      : props.allValidationErrors["occupation"]
                  }
                >
                  <MenuItem value={"Individual"}>Individual</MenuItem>
                  <MenuItem value={"Professional"}>Professional</MenuItem>
                  <MenuItem value={"Business"}>Business</MenuItem>
                  <MenuItem value={"Corporate"}>Corporate</MenuItem>
                </Select>
                {ocupation ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors["occupation"]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          {props.enquriyInfo.occupation == "Corporate" && (
            <div>
              <Grid className="block-inner" container spacing={2} mt={1}>
                <Grid className="form-block" item xs={3}>
                  <FormControl className="form-inner" variant="standard">
                    <Typography className="heading">
                      Company Name
                      <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                    </Typography>
                    <TextField
                      className="text-field"
                      id="outlined-basic"
                      name="cormpa"
                      variant="outlined"
                      value={props.enquriyInfo.corporation_details.company_name
                        ? props.enquriyInfo.corporation_details.company_name : companyName}
                      onChange={(e) => {
                        // resetError(["corporation_details.company_name"]);
                        setCompanyName(e.target.value);
                        props.enquriyInfo.corporation_details.company_name = e.target.value;
                      }}
                      helperText={
                        props.allValidationErrors &&
                          props.allValidationErrors["corporation_details"]
                          ? props.allValidationErrors[
                          "corporation_details.company_name"
                          ]
                          : ""
                      }
                      error={
                        props.allValidationErrors &&
                          props.allValidationErrors["corporation_details"]
                          ? props.allValidationErrors[
                          "corporation_details.company_name"
                          ]
                          : ""
                      }
                    />
                    <Typography
                      variant="h6"
                      color="error"
                      style={{ fontSize: "12px", paddingLeft: "15px" }}
                    >
                      {props.allValidationErrors
                        ? props.allValidationErrors[
                        "corporation_details.company_name"
                        ]
                        : ""}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid className="form-block" item xs={3}>
                  <FormControl className="form-inner" variant="standard">
                    <Typography className="heading">
                      Contact Person
                      <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                    </Typography>
                    <TextField
                      className="text-field"
                      id="outlined-basic"
                      variant="outlined"
                      name="cornta"
                      value={props.enquriyInfo.corporation_details.contact_person ?
                        props.enquriyInfo.corporation_details.contact_person : contactPerson}
                      onChange={(e) => {
                        // resetError(["corporation_details.contact_person"]);
                        setContactPerson(e.target.value);
                        props.enquriyInfo.corporation_details.contact_person = e.target.value;
                      }}
                      helpertext={
                        props.allValidationErrors &&
                          props.allValidationErrors["corporation_details"]
                          ? props.allValidationErrors[
                          "corporation_details.contact_person"
                          ]
                          : ""
                      }
                      error={
                        props.allValidationErrors &&
                          props.allValidationErrors["corporation_details"]
                          ? props.allValidationErrors[
                          "corporation_details.contact_person"
                          ]
                          : ""
                      }
                    />
                    <Typography
                      variant="h6"
                      color="error"
                      style={{ fontSize: "12px", paddingLeft: "15px" }}
                    >
                      {props.allValidationErrors
                        ? props.allValidationErrors[
                        "corporation_details.contact_person"
                        ]
                        : ""}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid className="form-block" item xs={3}>
                  <FormControl className="form-inner" variant="standard">
                    <Typography className="heading">
                      Contact No
                      <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                    </Typography>
                    <TextField
                      className="text-field"
                      id="outlined-basic"
                      type="number"
                      variant="outlined"
                      name="cornta"
                      value={
                        props.enquriyInfo.corporation_details
                          ? props.enquriyInfo.corporation_details.contact_number
                          : contactNumber
                      }
                      onChange={(e) => {
                        // resetError(["corporation_details.contact_number"]);
                        setContactNumber(e.target.value)
                        props.enquriyInfo.corporation_details.contact_number =
                          e.target.value;
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      helpertext={
                        props.allValidationErrors &&
                          props.allValidationErrors["corporation_details"]
                          ? props.allValidationErrors[
                          "corporation_details.contact_number"
                          ]
                          : ""
                      }
                      error={
                        props.allValidationErrors &&
                          props.allValidationErrors["corporation_details"]
                          ? props.allValidationErrors[
                          "corporation_details.contact_number"
                          ]
                          : ""
                      }
                    />
                    <Typography
                      variant="h6"
                      color="error"
                      style={{ fontSize: "12px", paddingLeft: "15px" }}
                    >
                      {props.allValidationErrors
                        ? props.allValidationErrors[
                        "corporation_details.contact_number"
                        ]
                        : ""}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid className="form-block" item xs={3}>
                  <FormControl className="form-inner" variant="standard">
                    <Typography className="heading">
                      Designation
                      <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                    </Typography>
                    <TextField
                      className="text-field"
                      id="outlined-basic"
                      variant="outlined"
                      name="corsig"
                      value={
                        props.enquriyInfo.corporation_details
                          ? props.enquriyInfo.corporation_details.designation
                          : designation
                      }
                      onChange={(e) => {
                        // resetError(["corporation_details.designation"]);
                        setDesignation(e.target.value);
                        props.enquriyInfo.corporation_details.designation =
                          e.target.value;

                      }}
                      helpertext={
                        props.allValidationErrors &&
                          props.allValidationErrors["corporation_details"]
                          ? props.allValidationErrors[
                          "corporation_details.designation"
                          ]
                          : ""
                      }
                      error={
                        props.allValidationErrors &&
                          props.allValidationErrors["corporation_details"]
                          ? props.allValidationErrors[
                          "corporation_details.designation"
                          ]
                          : ""
                      }
                    />
                    <Typography
                      variant="h6"
                      color="error"
                      style={{ fontSize: "12px", paddingLeft: "15px" }}
                    >
                      {props.allValidationErrors
                        ? props.allValidationErrors[
                        "corporation_details.designation"
                        ]
                        : ""}
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          )}
          <Grid className="form-block" item xs={6}>
            <FormControl className="form-inner">
              <Typography className="heading" mt={3}>
                Enquiry Through
                <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>

              <Select
                className="text-field"
                sx={{ width: "26ch", background: "#f6f7f9" }}
                name="enq"
                // variant="outlined"
                displayempty="true"
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={props.enquriyInfo.enquiry_through}
                onChange={handleOthersDetails}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                error={
                  props.allValidationErrors && enquiry_through
                    ? ""
                    : props.allValidationErrors["enquiry_through"]
                }
              >
                <MenuItem value={"ADVERTISEMENT"}>Advertisement</MenuItem>
                <MenuItem value={"COLD CALL"}>Cold Call</MenuItem>
                <MenuItem value={"MELA ENQUIRY"}>Mela Enquiry</MenuItem>
                <MenuItem value={"REFERENCE"}>Reference</MenuItem>
                <MenuItem value={"EXISTING"}>Existing</MenuItem>
                <MenuItem value={"TELE ENQUIRY"}>Tele Enquiry</MenuItem>
                <MenuItem value={"TELEMARKETING"}>Telemarketing</MenuItem>
                <MenuItem value={"WALK IN"}>Walk in</MenuItem>
                <MenuItem value={"FINANCIER LEAD"}>Financier Lead</MenuItem>
                <MenuItem value={"Others"}>Others</MenuItem>
              </Select>
              {enquiry_through ? (
                ""
              ) : (
                <Typography
                  variant="h6"
                  color="error"
                  style={{ fontSize: "12px", paddingLeft: "15px" }}
                >
                  {props.allValidationErrors
                    ? props.allValidationErrors["enquiry_through"]
                    : ""}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {props.enquriyInfo.enquiry_through == "Others" && (
            <Grid className="form-block" item xs={6}>
              <FormControl className="form-inner">
                <Typography className="heading" mt={3}>
                  Other Details
                  <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>
                <TextField
                  className="text-field"
                  type="text"
                  maxLength={20}
                  sx={{ width: "26ch", background: "#f6f7f9" }}
                  id="outlined-basic"
                  variant="outlined"
                  name="oth"
                  value={props.enquriyInfo.other_details}
                  onChange={(e) => {
                    props.enquriyInfo.other_details = e.target.value;
                    // resetError(["other_details"]);
                  }}
                  error={
                    props.allValidationErrors
                      ? props.allValidationErrors["other_details"]
                      : ""
                  }
                />
                <Typography
                  variant="h6"
                  color="error"
                  style={{ fontSize: "12px", paddingLeft: "15px" }}
                >
                  {props.allValidationErrors
                    ? props.allValidationErrors["other_details"]
                    : ""}
                </Typography>
              </FormControl>
            </Grid>
          )}
          <Grid className="form-block" item xs={12}>
            <FormControl
              className="form-inner"
              variant="standard"
              sx={{ width: "56ch" }}
            >
              <Typography className="heading" mt={1}>
                Notes
              </Typography>

              <Textarea
                placeholder="Note..."
                minRows={3}
                value={notes}
                sx={{ height: "8ch" }}
                onChange={(e) => {
                  setNotes(e.target.value);
                  props.enquriyInfo.enquiry_notes = e.target.value;
                }}

                endDecorator={
                  <Box
                    sx={{
                      display: "flex",
                      gap: "var(--Textarea-paddingBlock)",
                      borderTop: "1px solid",
                    }}
                  ></Box>
                }
              />
            </FormControl>
          </Grid>
        </div>
      </Card>
    </div>
  );
}

export default AddEnquiryOthers;
