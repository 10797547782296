import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import {
    FormControl,
    OutlinedInput,
    MenuItem,
    Select,
    Typography,
    Card,
    Grid,
    TextField,
    Divider,
    Box,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { NumericFormat } from "react-number-format";
import { Textarea } from "@mui/joy";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

//Styles to the card
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function AddPayment() {
    //route methods
    const params = useParams();
    const AuthStateData = useSelector((state) => state.signIn);
    const Swal = require("sweetalert2");

    //local states
    const [dateValue, setDateValue] = useState();
    const [title, setTitle] = useState();
    const [price, setPrice] = useState();
    const [description, setDescription] = useState();
    const [payemt_through, setPayment] = useState();
    const [loading, setLoading] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [allvalidations, setAllValidationErrors] = useState();
    const [payments, setPayments] = useState();
    const [persondetails, setPerson] = useState();
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [editopen, setEdit] = useState(false);
    const [current, setCurrentValue] = useState();

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // pagination url
    useEffect(() => {
        fetchDdocuments();
    }, []);

    const fetchDdocuments = async () => {
        try {
            let docUrl = `${process.env.REACT_APP_API_BASE_URL}/enquiries/${params.id}`;
            let docOptions = {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
                }),
            };
            let details = await fetch(docUrl, docOptions);
            details = await details.json();
            setPerson(details);
            console.log(details);
            let paymentlist = [...details.data.payment];
            setPayments(paymentlist);
        } catch (err) {
            console.error(err);
        }
    };

    //submit event
    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            let viewDocUrl = `${process.env.REACT_APP_API_BASE_URL}/enquiries/${params.id}/record-payment`;
            let requestOptions = {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
                }),
                body: JSON.stringify({
                    title: title,
                    amount: price,
                    payment_through: payemt_through,
                    payment_at: dateValue ? dateValue : new Date(),
                    discription: description,
                }),
            };
            let res = await fetch(viewDocUrl, requestOptions);
            res = await res.json();
            console.log(res);

            if (res.success === true) {
                setLoading(false);
                setTitle("");
                setPayment("");
                setPrice("");
                setDescription("");
                setAllValidationErrors([]);
                setTimeout(() => {
                    Swal.fire(`${title} added successfully!`, "success");
                }, 1000);
                fetchDdocuments();
            } else if (res.status == 422) {
                setAllValidationErrors(res.errors);
                setMessage(res.message);
                setLoading(false);
                setShowErrorAlert(true);
                setTimeout(() => {
                    setShowErrorAlert(false);
                }, 2000);
            }
        } catch (err) {
            console.error(err);
        }
    };

    //delete Payment
    const deleteDocument = async (doc) => {
        setLoading(true);
        try {
            let deleteDocUrl = `${process.env.REACT_APP_API_BASE_URL}/enquiries/${params.id}/record-payment/${doc._id}`;
            let deleteDocOptions = {
                method: "DELETE",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
                }),
            };
            let result = await fetch(deleteDocUrl, deleteDocOptions);
            result = await result.json();
            persondetails.data["payment"].splice(
                persondetails.data["payment"].findIndex((a) => a._id === doc._id)
            );
            console.log(persondetails);
            setPayments(persondetails.data["payment"]);
            setMessage("Payment removed successfully");

            setShowSuccessAlert(true);
            setTimeout(() => {
                setLoading(false);
                setShowSuccessAlert(false);
            }, 2000);
            fetchDdocuments();
        } catch (err) {
            console.error(err);
        }
    };

    const handlechangeValues = (pay) => {
        setCurrentValue(pay);
        setTitle(pay.title);
        setPayment(pay.payment_through);
        setPrice(pay.amount);
        setDateValue(pay.payment_at);
        setDescription(pay.discription);
        setEdit(true);
    };

    const updatePayment = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            let updateDocUrl = `${process.env.REACT_APP_API_BASE_URL}/enquiries/${params.id}/record-payment/${current._id}`;
            let updateDocOptions = {
                method: "PATCH",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
                }),
                body: JSON.stringify({
                    title: title,
                    amount: price,
                    payment_through: payemt_through,
                    payment_at: dateValue,
                    discription: description,
                }),
            };
            let result = await fetch(updateDocUrl, updateDocOptions);
            result = await result.json();
            console.log(result);
            if (result.success === true) {
                setLoading(false);
                fetchDdocuments();
                setEdit(false);
                setTitle("");
                setPayment("");
                setPrice("");
                setDescription("");
                setAllValidationErrors([]);
            }
        } catch (err) {
            console.error(err);
        }
    };

    //events
    const onKeyDown = (e) => {
        e.preventDefault();
    };
    const handleDateValueChange = (evt) => {
        console.log(evt);
        setDateValue(evt);
        let a = moment(new Date(evt)).format("YYYY-MM-DD");
    };

    //render UI
    return (
        <div>
            
            <Card className="personal-info" sx={{ width: "90ch" }} variant="outlined">
                <Typography
                    className="enquiry-title"
                    sx={{ color: "#3563e9", fontStyle: "normal", fontSize: "20px" }}
                >
                    Add Payment
                </Typography>
                <Divider />
                <Grid className="block-inner" container spacing={2}>
                    <Grid className="block-item" item xs={6}>
                        <Typography style={{ fontSize: "16px" }}>
                            Title<strong style={{ color: "#c70a0d" }}>*</strong>
                        </Typography>
                        <TextField
                            className="text-field"
                            id="outlined-basic"
                            variant="filled"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            error={allvalidations ? allvalidations["title"] : ""}
                        />

                        <Typography
                            variant="h6"
                            color="error"
                            style={{ fontSize: "12px", paddingLeft: "15px" }}
                        >
                            {allvalidations ? allvalidations["title"] : ""}
                        </Typography>
                    </Grid>
                    <Grid item className="block-item" xs={6}>
                        <FormControl className="form-inner">
                            <Typography className="heading-1">
                                Amount<strong style={{ color: "#c70a0d" }}>*</strong>
                            </Typography>
                            <NumericFormat
                                className="text-field"
                                variant="filled"
                                customInput={TextField}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                style={{
                                    // width: "240px",
                                    cursor: "pointer",
                                    border: "1px blue",
                                }}
                                prefix="₹"
                                value={price}
                                onValueChange={(e) => {
                                    setPrice(e.floatValue);
                                }}
                                thousandSeparator
                                thousandsGroupStyle="lakh"
                                error={allvalidations ? allvalidations["amount"] : ""}
                            />

                            <Typography
                                variant="h6"
                                color="error"
                                style={{ fontSize: "12px", paddingLeft: "15px" }}
                            >
                                {allvalidations ? allvalidations["amount"] : ""}
                            </Typography>
                        </FormControl>
                    </Grid>

                    <Grid className="block-item" item xs={6}>
                        <FormControl>
                            <Typography style={{ fontSize: "16px" }}>
                                Payment through
                                <strong style={{ color: "#c70a0d" }}>*</strong>
                            </Typography>
                            <Select
                                className="text-field"
                                displayempty="true"
                                defaultValue=""
                                sx={{ width: "34ch", border: "1px solid" }}
                                value={payemt_through}
                                onChange={(e) => {
                                    setPayment(e.target.value);
                                }}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                                error={allvalidations ? allvalidations["payment_through"] : ""}
                            >
                                <MenuItem disabled value="">
                                    <em>Selective type</em>
                                </MenuItem>
                                <MenuItem value="cash">Cash</MenuItem>
                                <MenuItem value="UPI">UPI</MenuItem>
                            </Select>
                            <Typography
                                variant="h6"
                                color="error"
                                style={{ fontSize: "12px", paddingLeft: "15px" }}
                            >
                                {allvalidations ? allvalidations["payment_through"] : ""}
                            </Typography>
                        </FormControl>
                    </Grid>

                    <Grid className="block-item" item xs={6}>
                        <Typography style={{ fontSize: "16px" }}>
                            Payment At<strong style={{ color: "#c70a0d" }}>*</strong>
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                className="text-field"
                                inputFormat="DD/MM/YYYY"
                                mask="__/__/____"
                                value={dateValue}
                                onChange={handleDateValueChange}
                                renderInput={(params) => (
                                    <TextField onKeyDown={onKeyDown} {...params} />
                                )}
                                disableFuture={true}
                            />
                        </LocalizationProvider>
                        <Typography
                            variant="h6"
                            color="error"
                            style={{ fontSize: "12px", paddingLeft: "15px" }}
                        >
                            {allvalidations ? allvalidations["payment_at"] : ""}
                        </Typography>
                    </Grid>

                    <Grid className="form-block" item xs={8}>
                        <FormControl
                            className="form-inner"
                            variant="standard"
                            sx={{ width: "56ch" }}
                        >
                            <Typography className="heading" mt={1}>
                                Description
                            </Typography>

                            <Textarea
                                minRows={3}
                                value={description}
                                sx={{ height: "8ch" }}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                                endDecorator={
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "var(--Textarea-paddingBlock)",
                                            borderTop: "1px solid",
                                        }}
                                    ></Box>
                                }
                                error={allvalidations ? allvalidations["discription"] : ""}
                            />
                        </FormControl>
                        <Typography
                            variant="h6"
                            color="error"
                            style={{ fontSize: "12px", paddingLeft: "15px" }}
                        >
                            {allvalidations ? allvalidations["discription"] : ""}
                        </Typography>
                    </Grid>

                    <Grid className="form-block" item xs={4}>
                        {editopen ? (
                            <button
                                onClick={(e) => {
                                    updatePayment(e);
                                }}
                                style={{
                                    backgroundColor: "#1976d2",
                                    color: "#fff",
                                    padding: "12px 20px",
                                    marginTop: "50px",
                                    borderRadius: "5px",
                                    marginLeft: "60px",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                            >
                                Update
                            </button>
                        ) : (
                            <button
                                onClick={(e) => {
                                    handleSubmit(e);
                                }}
                                style={{
                                    backgroundColor: "#1976d2",
                                    color: "#fff",
                                    padding: "12px 20px",
                                    marginTop: "50px",
                                    borderRadius: "5px",
                                    marginLeft: "60px",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                            >
                                Submit
                            </button>
                        )}
                    </Grid>

                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Snackbar
                        open={showErrorAlert}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                        <Alert severity="error">
                            <AlertTitle>Failed</AlertTitle>
                            {message}
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={showSuccessAlert}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                        <Alert severity="success">
                            <AlertTitle>success</AlertTitle>
                            {message}
                        </Alert>
                    </Snackbar>
                </Grid>
            </Card>
            <Card
                className="personal-info"
                sx={{ width: "90ch", padding: "0px 2em" }}
                variant="outlined"
            >
                {payments &&
                    payments.map((pay, index) => (
                        <div key={index}>
                            <Accordion
                                expanded={expanded === index}
                                onChange={handleChange(index)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                        {pay.title}
                                    </Typography>
                                    <Typography sx={{ color: "Highlight" }}>
                                        {"₹" + pay.amount}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ color: "text.secondary" }}>
                                        {"Payment date : " + pay.payment_at}
                                    </Typography>
                                    <Typography sx={{ color: "text.secondary" }}>
                                        {"Payment through:  " + pay.payment_through}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        aria-label="download"
                                        onClick={(evt) => {
                                            deleteDocument(pay);
                                        }}
                                        sx={{ float: "right", color: "red" }}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                    <IconButton
                                        edge="end"
                                        aria-label="download"
                                        onClick={(evt) => {
                                            handlechangeValues(pay);
                                        }}
                                        sx={{ float: "right", color: "blue" }}
                                    >
                                        <ModeEditOutlineIcon />
                                    </IconButton>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))}
            </Card>
        </div>
    );
}
export default AddPayment;
