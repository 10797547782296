import { Card, Grid, Typography } from "@mui/material"
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

const StatisticsData = () => {

    const [statisticsDetails, setStatisticData] = useState()
    const [loading, setLoading] = useState(false);
    const AuthStateData = useSelector((state) => state.signIn);


    useEffect(() => {
        // getStatisticsData()
    }, [])


    //api call for get statisticsData
    const getStatisticsData = async () => {
        setLoading(true);
        try {
            const url = `${process.env.REACT_APP_API_BASE_URL}/`;
            const options = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`
                }),
            };
            const response = await fetch(url, options);
            const responseData = await response.json();
            if (responseData.success) {
                setStatisticData(responseData.data);
            } else {
                throw responseData;
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Card>
                <Typography variant="title">Statistics</Typography>
                <hr></hr>
                <Grid container>
                    <Grid xs={6}>
                        <Typography variant="h6">3055</Typography>
                        <Typography variant="subtitle1">Enquiries</Typography>
                    </Grid>
                    <Grid xs={6}>
                        <Typography variant="h6">30</Typography>
                        <Typography variant="subtitle1">Bookings</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={6}>
                        <Typography variant="h6">3055</Typography>
                        <Typography variant="subtitle1">Deliveries</Typography>
                    </Grid>
                    <Grid xs={6}>
                        <Typography variant="h6">3055</Typography>
                        <Typography variant="subtitle1">Sales Representatives</Typography>
                    </Grid>
                </Grid>
                <hr></hr>
                <Typography variant="h6">Total Sales Revenue</Typography>

            </Card>
        </>
    )
}
export default StatisticsData