import { useDispatch, useSelector } from "react-redux";
import { setEquriesList } from "../../src/storeSlices/equirySlice";
import { Link, useNavigate, useLocation } from "react-router-dom";


export const useGetEnquiries = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const signInSliceData = useSelector((state) => state.signIn);
  return async (pages, rows, search_string, dms, modal_name, version, phone_number) => {

    let response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/enquiries?page=${pages}&limit=${rows}&dms=${dms}&phone_number=${phone_number}&search_string=${search_string}&model_name=${modal_name}&version=${version}`,
      {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${signInSliceData.signInSuccess.userDetails.refresh_token} `
        }),
      }
    );
    let getcarsdata = await response.json();
    if (response.status == 200) {
      dispatch(setEquriesList(getcarsdata));

      return {
        eqnuries_list: getcarsdata
      }
    } else if (response.status == 403 || response.status == 401)
      navigate("/")
    dispatch(setEquriesList({}));
  };
};
