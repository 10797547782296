import { Typography } from '@mui/material';
import * as React from 'react';

 function NotFound() {
  
  return (
      <div style={{display: "flex", justifyContent: "center", "flex-direction": "column", "align-items": "center"}}>
         <Typography mt={4}>There's nothing here: 404!</Typography>
      </div>
  );
}


export default NotFound;
