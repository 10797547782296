//react
import { useEffect, useState } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";

//importing NumericFormat for numeric seperation
import { NumericFormat } from "react-number-format";

//import Redux Store
import { useSelector } from "react-redux";

//importing materialUI
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  Card,
  FormControl,
  Grid,
  Typography,
  TextField,
  IconButton,
  OutlinedInput,
  Button,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Select,
  MenuItem,
} from "@mui/material";

//import Local Components
import EventEmitter from "../../utils/Emitter";
import "../scss/add/AddBookingDiscounts.scss";

function AddBookingDiscounts(props) {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // local state
  const { inputRef, onChange } = props;

  const [loading, setLoading] = useState(false);

  const [type, setDiscountType] = useState("");
  const [price_after, setPriceAfter] = useState("");
  const [price, setDiscountPrice] = useState("");
  const [less_party_regn, setLesPartyRegn] = useState("");
  const [less_party_insurance, setLessPartyInsurance] = useState("");
  const [allValidationErrors, setAllValidationErrors] = useState({});
  const [addedDiscounts, setaddedDiscounts] = useState(0);
  const [addedNetPrice, setAddedNetPrice] = useState(0);
  const [roadPrice, setRoadPrice] = useState();
  const [allDiscounts, setAllDiscounts] = useState([]);
  const [defaultDiscounts, setDefaultDiscounts] = useState([
    "Free Insurance",
    "Corporate Discount",
    "Two ka Four Discounts",
    "Wheels of India",
    "Premium WOI",
    "Exchange Bonus",
    "School Bus Offer",
    "Deal Based Offer",
    "Gold Coin Offer",
    "Extra Discount",
  ]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const MAX_VAL = 1000000000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };

  // function to remove the added discounts
  const handleRemove = (indexRemove) => {
    let updatedArray = props.enquriyInfo.booking_info.discounts.filter(
      (x, index) => index !== indexRemove
    );
    setAllDiscounts([...updatedArray]);
    props.enquriyInfo.booking_info.discounts = [...updatedArray];
    addDiscounts();
  };

  const setOnRoadPriceEmitter = () => {
    EventEmitter.emit("net_price", {
      value:
        props.enquriyInfo.booking_info.price_after_discounts -
        ((props.enquriyInfo.booking_info.less_by_party_reg || 0) +
          (props.enquriyInfo.booking_info.less_by_party_insurance || 0)),
    });
  };

  const onNewDiscountAdd = () => {
    setOnRoadPriceEmitter();
    // Adding to table
    let copy = [...props.enquriyInfo.booking_info.discounts];
    copy = [
      ...copy,
      {
        title: type,
        type: type,
        discount_price: price,
      },
    ];
    setAllDiscounts(copy);
    props.enquriyInfo.booking_info.discounts = [...copy];

    setDiscountPrice("");
    setDiscountType("");
    addDiscounts();
  };

  //Adding the values of the Discounts selected
  const addDiscounts = () => {
    let sum = 0;
    let arr = props.enquriyInfo.booking_info.discounts;
    for (let i = 0; i < arr.length; i++) {
      if (!arr[i].discount_price) {
        arr[i].discount_price = 0;
      }
      sum += arr[i].discount_price;
    }
    setaddedDiscounts(sum);
    props.enquriyInfo.booking_info.total_discounts = sum;
    settingPriceAfterDiscounts();
  };

  //setting the Price after the Discounts
  const settingPriceAfterDiscounts = () => {
    props.enquriyInfo.booking_info.price_after_discounts =
      props.enquriyInfo.car_information.on_road_price +
      (parseInt(
        props.enquriyInfo.booking_info.e_w
          ? props.enquriyInfo.booking_info.e_w_amount || 0
          : 0
      ) +
        parseInt(
          props.enquriyInfo.booking_info.auto_card
            ? props.enquriyInfo.booking_info.auto_card_amount || 0
            : 0
        )) -
      props.enquriyInfo.booking_info.total_discounts;

    setPriceAfter(props.enquriyInfo.booking_info.price_after_discounts);
    netPrice();
  };

  const netPrice = () => {
    props.enquriyInfo.booking_info.net_price_to_collect =
      (parseInt(props.enquriyInfo.booking_info.price_after_discounts) || 0) -
      (parseInt(props.enquriyInfo.booking_info.less_by_party_reg) || 0) -
      (parseInt(props.enquriyInfo.booking_info.less_by_party_insurance) || 0);

    setAddedNetPrice(props.enquriyInfo.booking_info.net_price_to_collect);
  };

  //Updating the Discounts in the dicounts table
  const updateDiscounts = (value, index) => {
    for (let i = 0; i < props.enquriyInfo.booking_info.discounts.length; i++) {
      if (index === i) {
        props.enquriyInfo.booking_info.discounts[i].discount_price = value;
      }
    }
    setAllDiscounts(props.enquriyInfo.booking_info.discounts);

    addDiscounts();
    netPrice();
  };

  // pagination url

  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    });

    //Event Emitter function to receive the data from other emitters
    const setOnRoadPrice = (eventData) => {
      setRoadPrice(eventData.value);
      props.enquriyInfo.booking_info.price_after_discounts =
        eventData.value - props.enquriyInfo.booking_info.total_discounts || "";
    };
    const listener = EventEmitter.addListener("on_road_price", setOnRoadPrice);
    return () => {
      listener.remove();
    };
  }, [roadPrice]);

  return (
    <div id="addDiscounts">
      <Card className="discounts-info" variant="outlined">
        <Grid className="block-inner" container spacing={3}>
          <Grid item className="form-block" xs={12}>
            <Typography className="title" style={{ fontSize: "18px" }}>
              Discounts
            </Typography>
            {/* <Divider />  */}
          </Grid>
        </Grid>

        <Grid className="block-inner" container spacing={3} mt={1}>
          <Grid className="form-block" item xs={4}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading-1">Type</Typography>
              <Select
                className="text-field"
                displayempty="true"
                // sx={{ width: "27ch" }}
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={type}
                onChange={(e) => {
                  setDiscountType(e.target.value);
                }}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
              >
                {defaultDiscounts.map((discount, index) => {
                  let elementIndex =
                    props.enquriyInfo.booking_info.discounts.findIndex((x) => {
                      return x.title === discount;
                    });
                  return elementIndex === -1 ? (
                    <MenuItem value={discount} key={index}>
                      {discount}
                    </MenuItem>
                  ) : (
                    <MenuItem value={discount} key={index} disabled>
                      {discount}
                    </MenuItem>
                  );
                })}
              </Select>
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["type"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading-1">Price</Typography>

              <NumericFormat
                className="text-field"
                customInput={TextField}
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{
                  // width: "240px",
                  cursor: "pointer",
                  border: "1px blue",
                }}
                prefix="₹"
                isAllowed={withValueCap}
                getInputRef={inputRef}
                value={price}
                onValueChange={(e) => {
                  setDiscountPrice(e.floatValue);
                  addDiscounts();
                  props.allValidationErrors["price"] = "";
                }}
                thousandSeparator
                thousandsGroupStyle="lakh"
                // isNumericString
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors["price"]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["price"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>

          <Grid item className="block-item" xs={4}>
            {!type || !price ? (
              <Button
                variant="contained"
                disabled
                style={{
                  float: "right",
                  overflow: "hidden",
                }}
              >
                <AddIcon></AddIcon>Add
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  float: "right",
                  overflow: "hidden",
                }}
                onClick={onNewDiscountAdd}
              >
                <AddIcon></AddIcon>Add
              </Button>
            )}
          </Grid>
          <Grid item className="form-block" xs={8} mb={2}>
            {props.enquriyInfo.booking_info.discounts.length !== 0 && (
              <div>
                <Table
                  id="alter"
                  sx={{
                    border: "2px solid  #ddd ",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="head">Type</TableCell>
                      <TableCell className="head">Price</TableCell>
                      <TableCell className="head">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.enquriyInfo.booking_info.discounts &&
                      props.enquriyInfo.booking_info.discounts.map(
                        (discount, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{discount.title}</TableCell>
                              <TableCell>
                                <NumericFormat
                                  className="text-field"
                                  customInput={TextField}
                                  labelid="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  style={{
                                    cursor: "pointer",
                                    border: "1px blue",
                                    background: "#f6f7f9",
                                  }}
                                  prefix="₹"
                                  isAllowed={withValueCap}
                                  getInputRef={inputRef}
                                  value={discount.discount_price}
                                  onValueChange={(e) => {
                                    updateDiscounts(e.floatValue, index);
                                    setOnRoadPriceEmitter();
                                    props.allValidationErrors[
                                      "discount.discount_price"
                                    ] = "";
                                  }}
                                  thousandSeparator
                                  thousandsGroupStyle="lakh"
                                  error={
                                    props.allValidationErrors
                                      ? props.allValidationErrors[
                                          "discount.discount_price"
                                        ]
                                      : ""
                                  }
                                />
                                <Typography
                                  variant="h6"
                                  color="error"
                                  style={{
                                    fontSize: "12px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  {props.allValidationErrors
                                    ? props.allValidationErrors[
                                        "discount.discount_price"
                                      ]
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  variant="outlined"
                                  color="error"
                                  aria-label="close"
                                  onClick={() => {
                                    handleRemove(index);
                                    setOnRoadPriceEmitter();
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                </Table>
              </div>
            )}
          </Grid>
        </Grid>

        <Grid className="block-inner" container spacing={3}>
          <Grid item className="form-block" xs={5}>
            <FormControl className="form-inner">
              <Typography className="heading-1">Total Discounts</Typography>
              <NumericFormat
                className="text-field"
                customInput={TextField}
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{
                  cursor: "pointer",
                  border: "1px blue",
                }}
                inputProps={{ readOnly: true }}
                prefix="₹"
                isAllowed={withValueCap}
                getInputRef={inputRef}
                value={
                  props.enquriyInfo.booking_info.total_discounts
                    ? props.enquriyInfo.booking_info.total_discounts
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                    : ""
                }
                thousandSeparator
                thousandsGroupStyle="lakh"
                helpertext={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                        "booking_info.price_after_discounts"
                      ]
                    : ""
                }
                error={
                  props.allValidationErrors &&
                  props.enquriyInfo.booking_info.total_discounts
                    ? ""
                    : props.allValidationErrors["booking_info.total_discounts"]
                }
              />
              {props.enquriyInfo.booking_info.total_discounts ? (
                ""
              ) : (
                <Typography
                  variant="h6"
                  color="error"
                  style={{ fontSize: "12px", paddingLeft: "15px" }}
                >
                  {props.allValidationErrors
                    ? props.allValidationErrors["booking_info.total_discounts"]
                    : ""}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item className="form-block" xs={5}>
            <FormControl className="form-inner">
              <Typography className="heading-1">
                Price After Discounts
              </Typography>
              <NumericFormat
                className="text-field"
                customInput={TextField}
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{
                  cursor: "pointer",
                  border: "1px blue",
                }}
                inputProps={{ readOnly: true }}
                prefix="₹"
                isAllowed={withValueCap}
                getInputRef={inputRef}
                value={
                  props.enquriyInfo.booking_info.price_after_discounts
                    ? props.enquriyInfo.booking_info.price_after_discounts
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                    : ""
                }
                onValueChange={(e) => {
                  setPriceAfter(e.floatValue);
                  setOnRoadPriceEmitter();
                }}
                thousandSeparator
                thousandsGroupStyle="lakh"
                helpertext={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                        "booking_info.price_after_discounts"
                      ]
                    : ""
                }
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                        "booking_info.price_after_discounts"
                      ]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors[
                      "booking_info.price_after_discounts"
                    ]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item className="form-block" xs={5}>
            <FormControl className="form-inner">
              <Typography className="heading-1">
                Less: By Party Regn.
              </Typography>
              <NumericFormat
                className="text-field"
                customInput={TextField}
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{
                  cursor: "pointer",
                  border: "1px blue",
                }}
                prefix="₹"
                isAllowed={withValueCap}
                getInputRef={inputRef}
                value={props.enquriyInfo.booking_info.less_by_party_reg}
                name="booart"
                onValueChange={(e) => {
                  setLesPartyRegn(e.floatValue);
                  props.enquriyInfo.booking_info.less_by_party_reg =
                    e.floatValue;
                  setOnRoadPriceEmitter();
                  netPrice();
                  props.allValidationErrors["booking_info.less_by_party_reg"] =
                    "";
                }}
                thousandSeparator
                thousandsGroupStyle="lakh"
                helpertext={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                        "booking_info.less_by_party_reg"
                      ]
                    : ""
                }
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                        "booking_info.less_by_party_reg"
                      ]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["booking_info.less_by_party_reg"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item className="form-block" xs={5}>
            <FormControl className="form-inner">
              <Typography className="heading-1">
                Less: By Party Insurance
              </Typography>
              <NumericFormat
                className="text-field"
                customInput={TextField}
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{
                  cursor: "pointer",
                  border: "1px blue",
                }}
                prefix="₹"
                isAllowed={withValueCap}
                getInputRef={inputRef}
                value={props.enquriyInfo.booking_info.less_by_party_insurance}
                name="booart"
                onValueChange={(e) => {
                  setLessPartyInsurance(e.floatValue);
                  props.enquriyInfo.booking_info.less_by_party_insurance =
                    e.floatValue;
                  setOnRoadPriceEmitter();
                  netPrice();
                  props.allValidationErrors[
                    "booking_info.less_by_party_insurance"
                  ] = "";
                }}
                thousandSeparator
                thousandsGroupStyle="lakh"
                helpertext={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                        "booking_info.less_by_party_insurance"
                      ]
                    : ""
                }
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                        "booking_info.less_by_party_insurance"
                      ]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors[
                      "booking_info.less_by_party_insurance"
                    ]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default AddBookingDiscounts;
