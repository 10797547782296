// react
import React, { useState, useEffect } from "react";
import {
  useLocation,
  useSearchParams,
  useNavigate,
  Link,
} from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Card,
  Table,
  Container,
  TablePagination,
  IconButton,
  Button,
  TableHead,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Breadcrumbs,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useGetCarList } from "../services/carListService";
import "../components/enquiries/scss/CarList.scss";

const CarList = () => {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);
  const stateData = useSelector((state) => state.car);
  const getCarListRequest = useGetCarList();

  // route methods
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const page = new URLSearchParams(search).get("page");
  const take = new URLSearchParams(search).get("take");

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  // local state
  const [delalertshow, setDelAlertShow] = useState(null);
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/carlist"
      onClick={handleClick}
    >
      CarList
    </Link>,
    <Typography key="3" color="text.primary">
      Add Cars
    </Typography>,
  ];
  const [showLoading, setShowLoading] = useState(false);
  const [rows, setRowData] = useState([]);
  const navigate = useNavigate();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [carslistCount, setCarsListCount] = useState(0);

  // pagination total and page variable
  const [controller, setController] = useState({
    page: page || 0,
    rowsPerPage: take || 10,
  });

  // React Hooks
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    getData();
  }, [controller]);

  // get all cars data
  const getData = async () => {
    setShowLoading(true);
    let result = await getCarListRequest(controller);

    let options = result.cars_list.data;

    let data = options.filter((item) => item.model != 'Maruti Brezza');

    setRowData(data);
    setCarsListCount(result.cars_list.total);
    setShowLoading(false);
  };

  // pagination change page , no change take
  const handlePageChange = (event, newPage) => {
    setSearchParams({ page: newPage, take: controller.rowsPerPage });
    setController({
      ...controller,
      page: newPage,
    });
    getData();
  };
  // pagination no change page , change change take
  const handleChangeRowsPerPage = (event) => {
    setSearchParams({ page: 0, take: event.target.value });
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
    getData();
  };

  // delete row single data
  const handleDelete = async (carlst) => {
    try {
      // we have to ask confirmation
      setConfirmDialogOpen(true);
      setDelAlertShow(true);
      let url = `${process.env.REACT_APP_API_BASE_URL}/v1.0/cars/${carlst._id}`;
      let options = {
        method: "delete",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let result = await fetch(url, options);
      result = await result
        .json()
        .then((result) => result.json())
        .then((data) => {
          setTimeout(() => {
            setDelAlertShow(false);
          }, 1000);
          getData();
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <div id="CarList">
      <div>
        <div role="presentation" onClick={handleClick}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div className="action">
          <Link to="/addcar">
            <Button variant="contained">
              <AddIcon></AddIcon>
              Add
            </Button>
          </Link>
        </div>
        <div className="table-data">
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="custm-th">Model</TableCell>
                  <TableCell className="custm-th">Version</TableCell>
                  <TableCell className="custm-th">Transmission</TableCell>
                  <TableCell className="custm-th">Color</TableCell>
                  <TableCell className="custm-th">Cost</TableCell>
                  <TableCell className="custm-th">Cresco Price</TableCell>
                  <TableCell className="custm-th">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((carlst) => (
                  <TableRow>
                    <TableCell className="table-lst">{carlst.model}</TableCell>
                    <TableCell className="table-lst">
                      {carlst.version}
                    </TableCell>
                    <TableCell className="table-lst">
                      {carlst.transmission}
                    </TableCell>
                    <TableCell className="table-lst">
                      {carlst.colours}
                    </TableCell>
                    <TableCell className="table-lst">{carlst.cost}</TableCell>
                    <TableCell className="table-lst">
                      {carlst.cresco_price}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        variant="outlined"
                        color="error"
                        aria-label="delete"
                        onClick={() => handleDelete(carlst)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              onPageChange={handlePageChange}
              page={controller.page}
              count={carslistCount}
              rowsPerPage={controller.rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {showLoading === true ? (
              <p className="custm-loader">
                <CircularProgress />
              </p>
            ) : (
              ""
            )}
          </Card>
        </div>
      </div>
      {delalertshow && (
        <Alert className="custm-alert-update">
          <p>Car deleted successfully</p>
        </Alert>
      )}
      <Dialog
        open={confirmDialogOpen}
        close={handleClose}
        aria-labelledby="alert-dialog-title"
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
            maxHeight: 435,
            backdropFilter: "blur(8px)",
          },
        }}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to Delete the Row?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>YES</Button>

          <Button onClick={handleClose} autoFocus>
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CarList;
