import { Button, Card, Container, TableCell, TableHead, Typography } from "@mui/material";
import { Table, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getDate } from "../pipes/timePipe";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

const RecentEnquiries = () => {


    const navigate = useNavigate();
    const AuthStateData = useSelector((state) => state.signIn);

    const [recentEnquiriesData, setRecentEnquiriesData] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getRecentEnquiries();
    }, []);

    const getRecentEnquiries = async () => {
        setLoading(true);
        try {
            const url = `${process.env.REACT_APP_API_BASE_URL}/enquiries?page=${1}&limit=${10}`;
            const options = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`
                }),
            };
            const response = await fetch(url, options);
            const responseData = await response.json();
            if (responseData.success) {
                setRecentEnquiriesData(responseData.data);
            } else {
                throw responseData;
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>

            <Card style={{ marginLeft: "30px" }}>
                <div style={{ display: "flex" }}>
                    <Typography variant="h5">Recent Enquiries</Typography>
                    <Button onClick={() => navigate('/enquiries')}>View All</Button >
                </div>
                <div style={{ height: "350px", overflow: "scroll" }}>
                    <Table>
                        {recentEnquiriesData && recentEnquiriesData.length ?
                            recentEnquiriesData.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <img
                                                src={require("../../img/sign-in-bg.png")}
                                                alt="bg-image"
                                                height='80px'
                                                width='100px'
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <b>{item.sales_representative_id.name}</b>
                                                <p>
                                                    {item.car_information.model + " (" + item.car_information.version + ")"}
                                                </p>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <i>{getDate(item.enquiry_date)}</i>
                                                <p>
                                                    {item.status}
                                                </p>
                                            </div>
                                        </TableCell>

                                    </TableRow>)
                            }) : "No recent Enquiries"}
                    </Table>
                </div>
            </Card>
        </div>
    )
}
export default RecentEnquiries