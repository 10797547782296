//react
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
import Button from "@mui/material/Button";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";

import {
  Card,
  OutlinedInput,
  MenuItem,
  Select,
  Typography,
  Grid,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Document, Page, pdfjs } from "react-pdf";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import List from "@mui/material/List";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";

import axios from "axios";
import { unwrapResult } from "@reduxjs/toolkit";

import "../scss/view/_viewUpload.scss";

//styles to selectcomponent
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ViewEnquiryUpload() {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();
  const params = useParams();
  const Swal = require("sweetalert2");

  //values for select components
  const names = [
    { label: "Aadhaar", value: "Aadhaar" },
    { label: "Pan Card", value: "Pan_Card" },
  ];

  // local state
  const [allDocuments, setDcoumentList] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState();
  const [filename, setFileName] = useState();
  const [file_type, setFile_type] = useState();
  const [file_size, setFile_Size] = useState();
  const [dms_number, setDms_number] = useState("123456");
  const [document_type, setDocument_type] = useState("");
  const [enquiry_id, setEnquiry_id] = useState(params.data);
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [slug1, setSlug] = useState();
  const [persondetails, setPerson] = useState();
  const [imageurl, setImageurl] = useState();
  const [open, setOpen] = useState();
  const [doctype, setDocType] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [downloadurl, setDownloadurl] = useState();
  // const [show, setShow] = React.useState(false);

  //preview for pdfs
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  //function to preview pdfs
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  //event
  const handleChange = (event) => {
    setDocument_type(event.target.value);
  };

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
  }, []);

  //event for logout
  const handleLogout = () => {
    localStorage.removeItem("payload");
    navigate("/");
  };

  // pagination url
  useEffect(() => {
    fetchDdocuments();
  }, []);

  //show sucess message
  const handleSuccess = () => {
    Swal.fire({
      position: "center",
      imageUrl:
        "https://assets.materialup.com/uploads/c8a1e109-dca0-4b9e-9aa6-1e339b5ba903/preview.gif",
      imageAlt: "image",
      customClass: "swal-upload",
      showConfirmButton: false,
      timer: 4500,
    });
  };
  //popup for deletedoc
  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  //fetch the documents for a person
  const fetchDdocuments = async () => {
    try {
      let docUrl = `${process.env.REACT_APP_API_BASE_URL}/enquiries/${params.id}`;
      let docOptions = {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let res = await fetch(docUrl, docOptions);
      let details = await res.json();
      console.log(details)
      if (res.status == 200 || res.status == 201) {
        console.log(details.data["enquiries_documents"]);
        setPerson(details.data);
        setDms_number(details.data.dms_number);
        setEnquiry_id(params.id);
        let documentlist1 = [...details.data.data.enquiries_documents];
        setDcoumentList(documentlist1);
        console.log(documentlist1)
      } else if (res.status == 422) {
        setMessage(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  //delete document
  const deleteDocument = async (doc) => {
    console.log(doc)
    setLoading(true);
    try {
      let deleteDocUrl = `${process.env.REACT_APP_API_BASE_URL}/enquiries/${params.id}/remove-document/${doc._id}`;
      let deleteDocOptions = {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let res = await fetch(deleteDocUrl, deleteDocOptions);
      let result = await res.json();
      if (res.status == 200 || res.status == 201) {
        persondetails.data["enquiries_documents"].splice(
          persondetails.data["enquiries_documents"].findIndex(
            (a) => a._id === doc._id
          ),
          1
        );

        Toast.fire({
          icon: "success",
          title: "File removed successfully",
        });
        setDcoumentList(persondetails.data["enquiries_documents"]);
        setImageurl("");
        setDocument_type("");
        setLoading(false);
      } else if (res.status == 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    }
  };

  //document
  const viewDocument = async (doc) => {
    setDocType(doc.file_type);
    setLoading(true);
    try {
      let viewDocUrl = `${process.env.REACT_APP_API_BASE_URL}/documents/download`;
      let requestOptions = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
        body: JSON.stringify({
          path: doc.path,
          slug: doc.slug,
        }),
      };
      let res = await fetch(viewDocUrl, requestOptions);
      let result = await res.json();

      if (res.status == 200 || res.status == 201) {
        setImageurl(result.data.download_url);
        setOpen(false);
        setLoading(false);
      } else if (res.status == 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  //download documents
  const downloadDocuments = async (doc) => {
    setDocType(doc.file_type);
    setLoading(true);
    try {
      let viewDocUrl = `${process.env.REACT_APP_API_BASE_URL}/documents/download`;
      let requestOptions = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
        body: JSON.stringify({
          path: doc.path,
          slug: doc.slug,
        }),
      };
      let res = await fetch(viewDocUrl, requestOptions);
      let result = await res.json();
      if (res.status == 200 || res.status == 201) {
        window.open(result.data.download_url, "_self");
        setLoading(false);
      } else if (res.status == 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  //event
  const handleFileChange = (e) => {
    console.log(e);
    setSelectedFile(e.target.files[0]);
    setIsSelected(true);
    console.log(e.target.files[0]);
    const fileObj = e.target.files && e.target.files[0];
    console.log(fileObj);

    if (!fileObj) {
      return;
    }
    setFileName(fileObj.name);
    setFile_type(fileObj.type);
    setFile_Size(1);
    console.log(fileObj.size);
  };

  const resetFileInput = () => {
    // 👇️ reset input value
    setSelectedFile(null);
    setImageurl("");
    setDownloadurl("");
    setDocument_type("");

    document.getElementById("documentUpload").value = "";
  };

  //event
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let details = {
        file: filename,
        file_size: file_size,
        file_type: file_type,
        dms_number: dms_number,
        document_type: document_type,
        enquiry_id: enquiry_id,
      };

      let url = `${process.env.REACT_APP_API_BASE_URL}/documents`;
      let options = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
        body: JSON.stringify(details),
      };
      let res = await fetch(url, options);
      let resJson = await res.json();
      if (res.status === 200) {
        setUrl(resJson.data.path);
        setSlug(resJson.data.slug);
        viewDocument();
        downloadDocuments();
        setDocument_type("")
        //file reader
        const reader = new FileReader();
        reader.onload = async (evt) => {
          const metadata = `name: ${filename}, type: ${file_type}, size: ${file_size}, contents:`;
          const contents = evt.target.result;
          console.log(metadata, contents);
          let details1 = {
            document_type: document_type,
            file_name: filename,
            file_size: file_size,
            file_type: file_type,
          };
          let pAxios = axios.create({});
          await pAxios.put(resJson.data.target_url, contents);
          details1.slug = resJson.data.slug;
          details1.path = resJson.data.path;

          const requestOptions = {
            method: "PATCH",
            body: JSON.stringify(details1),
            headers: new Headers({
              "Content-Type": "application/json",
              authorization: `${AuthStateData.signInSuccess.userDetails.access_token}`,
            }),
          };
          let res = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/enquiries/${params.id}/add-document`,
            requestOptions
          );
          if (res.status == 200 || res.status == 201) {
            setLoading(false);
            handleSuccess();
            resetFileInput();
            setTimeout(() => {
              fetchDdocuments();
            }, 4000);
          } else if (res.status == 422) {
            setMessage("Filemust be less than 30 mb!!");
            setShowErrorAlert(true);
            setTimeout(() => {
              setLoading(false);
              resetFileInput();

              setShowErrorAlert(false);
            }, 2000);
          } else if (res.status === 403) {
            navigate("/");
            handleLogout();
          }
        };

        reader.readAsArrayBuffer(selectedFile);
      } else {
        setMessage("Some this went wrong !");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div id="viewUpload">
      <Card className="upload" variant="outlined">
        <div>
          <Typography className="title">Upload Documents</Typography>
        </div>
        <List>
          {allDocuments.map((doc, index) => {
            return (
              <div key={index}>
                <Box sx={{ p: 1, my: 1, width: "250px" }}>
                  {doc.file_type == "application/pdf" ? (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      className="icon-btn"
                    >
                      <PictureAsPdfIcon />
                      <span sx={{ marginLeft: "10px" }}>
                        {doc.document_type
                          ? doc.document_type.split("/").pop()
                          : "-"}
                      </span>
                    </IconButton>
                  ) : (
                    <Button
                      edge="end"
                      aria-label="file"
                      className="icon-btn"
                    >
                      <InsertPhotoRoundedIcon />
                      <span>
                        {doc.document_type
                          ? doc.document_type.split("/").pop()
                          : "-"}
                      </span>
                    </Button>
                  )}

                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(evt) => deleteDocument(doc)}
                    className="arrow-down-button"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(evt) => {
                      downloadDocuments(doc);
                    }}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                  {/* open==false?
                  <IconButton
                    edge="end"
                    aria-label="visiblityoff"
                    onClick={(e)=>setOpen(true)}
                    sx={{ float: "right" }}
                  >
                    <VisibilityOffIcon />
                  </IconButton> 
                  :""} */}
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={(evt) => {
                      viewDocument(doc);
                    }}
                    className="eye-icon"
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Box>
                <Box sx={{ p: 1, my: 1, width: "30px" }} />
              </div>
            );
          })}
        </List>

        <Grid className="block-inner" container spacing={2}>
          <Grid container className="address-proof">
            <Grid item xs={4}>
              <Typography className="text-view" style={{lineHeight: "1"}}>Address Proof</Typography>
              <Select
                displayempty="true"
                value={document_type}
                onChange={handleChange}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                className="selective"
                style={{ margin: "5px 0 30px"}}
                size="small"
              >
                <MenuItem disabled value="">
                  <em>Selective type</em>
                </MenuItem>
                {allDocuments.find((item, index) => item.document_type == "Aadhaar") ?
                  <MenuItem value={"Aadhaar"} disabled>
                    Aadhaar
                  </MenuItem> : <MenuItem value={"Aadhaar"}>
                    Aadhaar
                  </MenuItem>
                }
                {allDocuments.find((item, index) => item.document_type == "Pan_Card") ?
                  <MenuItem value={"Pan_Card"} disabled>
                    Pan Card
                  </MenuItem> : <MenuItem value={"Pan_Card"}>
                    Pan Card
                  </MenuItem>}
              </Select>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{marginTop: "22px", padding: "12px 10px", borderRadius:"3px", backgroundColor: "#F6F7F9", display: "inline-flex"}}
              >
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, application/pdf,"
                  name="file"
                  id="documentUpload"
                  onChange={handleFileChange}
                />
              </div>
              {selectedFile && document_type ? (
                <button onClick={handleSubmit} className="btn">
                  Submit
                </button>
              ) : (
                <button disabled onClick={handleSubmit} className="btn1">
                  Submit
                </button>
              )}
              {selectedFile ? (
                <IconButton color="error" size="large" onClick={resetFileInput} style={{marginLeft: "10px", backgroundColor: "#ffeded"}}>
                  <DeleteOutlinedIcon className="delete" />
                </IconButton>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
        {showLoading === true ? (
          <p className="custm-loader">
            <CircularProgress />
          </p>
        ) : (
          ""
        )}
      </Card>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={showSuccessAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showErrorAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">
          <AlertTitle>Failed</AlertTitle>
          {message}
        </Alert>
      </Snackbar>

      {open == false && imageurl ? (
        <Card className="upload" variant="outlined" style={{display: "flex", gap: "10px"}}>
          
          <div>
            {doctype == "application/pdf" ? (
              <Document file={imageurl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
            ) : (
              <img src={imageurl} width="300px" style={{ border: "1px solid #efefef", padding: "10px"}} />
            )}

            {/* <embed src={imageurl} width="800px" height="2100px" /> */}
          </div>
          <div>
            <IconButton type="button" color="error" size="large" onClick={(e) => setOpen(true)}>
              <ClearIcon />
            </IconButton>
          </div>
        </Card>
      ) : (
        ""
      )}
    </div>
  );
}

export default ViewEnquiryUpload;
