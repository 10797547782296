import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setEquriesList } from '../../storeSlices/equirySlice';

const SearchModelAutoComplete = ({ data, captureValue }) => {


    const AuthStateData = useSelector((state) => state.signIn);
    const [loading, setLoading] = React.useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!AuthStateData.signInSuccess) {
            navigate("/");
        }
        getModelData("");
    }, [])


    //get all model data
    const [modeldata, setModelData] = React.useState([]);
    const getModelData = async (value) => {
        setLoading(true)
        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/cars/models?page=${1}&limit${10}&model_name=${value}`;
            let options = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
                }),
            };
            let modeldatafetch = await fetch(url, options);
            let modeldatafetched = await modeldatafetch.json();
            if (modeldatafetch.ok) {
                let options = modeldatafetched.data;
                let data = options.filter((item) => item.model != 'Maruti Brezza');
                setModelData(data);
            } else if (modeldatafetch.status == 403 || modeldatafetch.status == 401) {
                dispatch(setEquriesList({}));
                navigate("/");
            }

        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false)
        }
    };

    const [value, setValue] = React.useState("");
    const [inputValue, setInputValue] = React.useState('');

    const [searchString, setSearchString] = React.useState('');


    return (
        <>
            <Autocomplete
                loading={loading}
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    captureValue(newValue)

                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    getModelData(newInputValue)
                    setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={modeldata}
                getOptionLabel={(option) => option.model ? option.model : ""}
                sx={{
                    width: 300, height: "50px", marginLeft: "30px",
                    '& .MuiTextField-root': {
                        border: "1px solid black"
                    }

                }}
                renderInput={(params) => <TextField {...params} label="Models" />}
            />
        </>
    )
}
export default SearchModelAutoComplete