//react
import React, { useState, useEffect } from "react";

// importing the Numeric Format
import { NumericFormat } from "react-number-format";

//importing Store
import { useSelector } from "react-redux";

//importing Material UI
import {
  Card,
  FormControl,
  OutlinedInput,
  MenuItem,
  Typography,
  Grid,
  Select,
  TextField,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

//importing local Components(Event Emitter)
import EventEmitter from "../../utils/Emitter";
import "../scss/add/AddEnquiryCarInfo.scss";

function AddEnquiryCarInfo(props) {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // local state
  const { inputRef } = props;
  const [year, setYear] = useState();
  const [transmission, setTransmission] = useState("");
  const [fuel, setFuel] = useState("");
  const [loading, setLoading] = useState(false);

  // model and version variable
  const [modelVersions, setModelVersions] = useState([]);
  const [modeldata, setModelData] = useState([]);
  const [modelname, setModelName] = useState("");
  const [modelId, setModelId] = useState();

  //  version variables
  const [versioncolor, setVersionColor] = useState([]);
  const [versionname, setVersionName] = useState("");
  const [versionColors, setVersionColors] = useState([]);
  const [versionYears, setVersionYears] = useState([]);
  const [versionTransmission, setVersionTransmission] = useState([]);
  const [versionBodyType, setversionBodyType] = useState("");
  const [versionFuelType, setVersionFuelType] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

  const [exShowRoomPrice, setExShowRoomPrice] = useState();
  const [registrationPrice, setRegistrationPrice] = useState();
  const [insurance, setInsurance] = useState();
  const [otherCharges, setOtherCharges] = useState();
  const [onRoadPrice, setOnRoadPrice] = useState();

  // prices variable for different colors in the version
  const [prices, setPrices] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const MAX_VAL = 1000000000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };

  // get car model data on entering to add or edit page
  const getModelData = async () => {
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/cars/models`;
      let options = {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let modeldatafetch = await fetch(url, options);
      let modeldatafetched = await modeldatafetch.json();

      if (modeldatafetch.ok) {
        setModelData(modeldatafetched.data);
        checkCarModelInfo(modeldatafetched.data);
      }
    } catch (err) { }
  };

  //checking the data in the edit page to fill the data from the response
  const checkCarModelInfo = async (modeldatafetched) => {
    if (
      props.enquriyInfo.car_information.model &&
      props.enquriyInfo.car_information.model.length
    ) {
      let modelIndex = modeldatafetched.findIndex(
        (x) => props.enquriyInfo.car_information.model === x.model
      );
      const modelId = modeldatafetched[modelIndex]._id;
      let result = await getVersionData(modelId);

      if (props.enquriyInfo.car_information.version) {
        const found = result.versions.find((obj) => {
          return obj.title === props.enquriyInfo.car_information.version;
        });
        if (found) {
          setVersionColors(found.colours);
          setVersionYears(found.years);
          setversionBodyType(found.body_type);
          setVersionTransmission(found.transmissions);
          setVersionFuelType(found.fuel_types);

          versionDetails(
            props.enquriyInfo.car_information.model,
            modeldatafetched
          );
          handleVersion(props.enquriyInfo.car_information.version);
        }
      }
    }
  };

  //getting the version details by model
  const versionDetails = async (event, modeldatafetched) => {
    let index = modeldatafetched.findIndex(
      (x) => x.model === props.enquriyInfo.car_information.model
    );

    let dataVersions = await getVersionData(modeldatafetched[index]._id);

    setModelVersions(dataVersions.versions);
    let versionIndex = dataVersions.versions.findIndex(
      (x) => x.title === props.enquriyInfo.car_information.version
    );

    setVersionColors(dataVersions.versions[versionIndex].colors);
    setVersionYears(dataVersions.versions[versionIndex].years);
    setVersionTransmission(dataVersions.versions[versionIndex].transmissions);
    setversionBodyType(dataVersions.versions[versionIndex].body_type);
    setVersionFuelType(dataVersions.versions[versionIndex].fuel_types);
    setPrices(dataVersions.versions[versionIndex].prices);

    let fuelType = dataVersions.versions[versionIndex].fuel_types.find(
      (x) => x === props.enquriyInfo.car_information.fuel_type
    );
    setFuel(fuelType);
  };

  //clearing the selected data onChange of Version and model
  const clearAutoFilledData = () => {
    props.enquriyInfo.car_information.color = "";
    props.enquriyInfo.car_information.year = "";
    props.enquriyInfo.car_information.transmission = "";
    props.enquriyInfo.car_information.body_type = "";
    props.enquriyInfo.car_information.fuel_type = "";
    setFuel("");
    setYear();
    setVersionTransmission("");
    setSelectedColor("");
    clearPrices();
    setVersionColors("");

    setExShowRoomPrice("");
    setRegistrationPrice("");
    setInsurance("");
    setOtherCharges("");
    setOnRoadPrice("");
  };

  //clearing Price On changing Model,Version,Color
  const clearPrices = () => {
    props.enquriyInfo.car_information.ex_showroom_price = "";
    props.enquriyInfo.car_information.individual_registration_price = "";
    props.enquriyInfo.car_information.insurance = "";
    props.enquriyInfo.car_information.other_charges = "";
    props.enquriyInfo.car_information.on_road_price = "";
  };

  // setting the data on change of Version
  const settingVersionData = (versionIndex) => {
    setVersionColors(modelVersions[versionIndex].colors);
    setVersionYears(modelVersions[versionIndex].years);
    setVersionTransmission(modelVersions[versionIndex].transmissions);
    setversionBodyType(modelVersions[versionIndex].body_type);
    setVersionFuelType(modelVersions[versionIndex].fuel_types);
    setPrices(modelVersions[versionIndex].prices);

    props.enquriyInfo.car_information.body_type =
      modelVersions[versionIndex].body_type;

    if (modelVersions[versionIndex].years.length === 1) {
      props.enquriyInfo.car_information.year =
        modelVersions[versionIndex].years[0];
    }

    if (modelVersions[versionIndex].transmissions.length === 1) {
      props.enquriyInfo.car_information.transmission =
        modelVersions[versionIndex].transmissions[0];
    }

    if (modelVersions[versionIndex].fuel_types.length === 1) {
      props.enquriyInfo.car_information.fuel_type =
        modelVersions[versionIndex].fuel_types[0];
      setFuel(modelVersions[versionIndex].fuel_types[0]);
    }
  };

  // On change of Model
  const handleModel = async (event) => {
    setModelName(event);
    props.enquriyInfo.car_information.model = event;

    let index = modeldata.findIndex(
      (x) => x.model === props.enquriyInfo.car_information.model
    );
    setModelId(modeldata[index]._id);

    let allVersionsData = await getVersionData(modeldata[index]._id);
    let allVersions = allVersionsData.versions;

    setModelVersions(allVersions);
    props.enquriyInfo.car_information.version = "";
    clearAutoFilledData();

    setTotalPriceEventEmiter();
    setNetPriceEventEmitter();
  };

  // On Change of Version
  const handleVersion = async (event) => {
    const versionget = event;
    setVersionName(event);
    props.enquriyInfo.car_information.version = event;

    let versionIndex = modelVersions.findIndex((x) => x.title === versionget);
    if (versionIndex !== -1) {
      clearAutoFilledData();

      setTotalPriceEventEmiter();
      setNetPriceEventEmitter();

      settingVersionData(versionIndex);
    } else console.error("Version Not Found");
  };

  const settingPrices = (color) => {
    let pricesIndex = prices.findIndex((x) => x.color === color);

    props.enquriyInfo.car_information.ex_showroom_price =
      prices[pricesIndex].ex_showroom_price;
    props.enquriyInfo.car_information.individual_registration_price =
      prices[pricesIndex].individual_registration_price;
    props.enquriyInfo.car_information.insurance = prices[pricesIndex].insurance;
    props.enquriyInfo.car_information.other_charges =
      prices[pricesIndex].other_charges;
    props.enquriyInfo.car_information.on_road_price =
      prices[pricesIndex].on_road_price;

    setExShowRoomPrice(prices[pricesIndex].ex_showroom_price);
    setRegistrationPrice(prices[pricesIndex].individual_registration_price);
    setInsurance(prices[pricesIndex].insurance);
    setOtherCharges(prices[pricesIndex].other_charges);
    setOnRoadPrice(prices[pricesIndex].on_road_price);
  };

  // onChange of Color
  const settingColor = (color) => {
    setSelectedColor(color);

    setTimeout(clearPrices(), 2000);
    console.log("This was called", color);
    setTimeout(settingPrices(color), 3000);

    console.log("This was called after the time out", color);
    // settingPrices(color)

    // Emitting Data to other components
    setTotalPriceEventEmiter();
    setNetPriceEventEmitter();
  };

  // Net Price to other components
  const setNetPriceEventEmitter = () => {
    EventEmitter.emit("net_price", {
      value:
        (props.enquriyInfo.car_information.on_road_price || 0) +
        (parseInt(
          props.enquriyInfo.booking_info.e_w
            ? props.enquriyInfo.booking_info.e_w_amount || 0
            : 0
        ) +
          parseInt(
            props.enquriyInfo.booking_info.auto_card
              ? props.enquriyInfo.booking_info.auto_card_amount || 0
              : 0
          )) -
        props.enquriyInfo.booking_info.total_discounts -
        (props.enquriyInfo.booking_info.less_by_party_reg +
          props.enquriyInfo.booking_info.less_by_party_insurance),
    });
  };

  //emmitter to transfer On Road Price data to other components
  const setTotalPriceEventEmiter = () => {
    EventEmitter.emit("on_road_price", {
      value:
        (props.enquriyInfo.car_information.on_road_price || 0) +
        (parseInt(
          props.enquriyInfo.booking_info.e_w
            ? props.enquriyInfo.booking_info.e_w_amount || 0
            : 0
        ) +
          parseInt(
            props.enquriyInfo.booking_info.auto_card
              ? props.enquriyInfo.booking_info.auto_card_amount || 0
              : 0
          )),
    });
  };

  //get car version data from the API
  const getVersionData = async (id) => {
    let result = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/cars/models/${id}`,
      {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      }
    );

    result = await result.json();
    return result.data;
  };

  //pagination url
  //getting the car data by default
  useEffect(() => {
    getModelData();

    setExShowRoomPrice(props.enquriyInfo.car_information.ex_showroom_price);
    setRegistrationPrice(
      props.enquriyInfo.car_information.individual_registration_price
    );
    setInsurance(props.enquriyInfo.car_information.insurance);
    setOtherCharges(props.enquriyInfo.car_information.other_charges);
    setOnRoadPrice(props.enquriyInfo.car_information.on_road_price);
  }, []);

  return (
    <div id="addCar">
      <Card className="car-block" variant="outlined">
        {!loading ? (
          <Grid className="block-inner" container spacing={3}>
            <Grid item className="block-item" xs={12}>
              <Typography className="title">Car Information</Typography>
              {/* <Divider /> */}
            </Grid>
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  Model<strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>

                <Select
                  className="text-field"
                  name="car"
                  // sx={{ width: "26ch" }}
                  displayempty="true"
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={props.enquriyInfo.car_information.model}
                  onChange={(e) => {
                    handleModel(e.target.value);
                  }}
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  error={
                    props.allValidationErrors && modelname
                      ? ""
                      : props.allValidationErrors["car_information.model"]
                  }
                >
                  {modeldata.map((modelget, index) => {
                    if (modelget?.model != 'Maruti Brezza')
                      return <MenuItem key={index} value={modelget.model}>
                        {modelget.model}
                      </MenuItem>
                  })}
                </Select>
                {modelname ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors["car_information.model"]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  Versions<strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>

                <Select
                  input={<OutlinedInput />}
                  className="text-field"
                  name="carn"
                  displayempty="true"
                  // sx={{ width: "26ch" }}
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  onChange={(e) => {
                    handleVersion(e.target.value);
                  }}
                  value={props.enquriyInfo.car_information.version}
                  MenuProps={MenuProps}
                  error={
                    props.allValidationErrors && versionname
                      ? ""
                      : props.allValidationErrors["car_information.version"]
                  }
                >
                  {modelVersions.map((versionget) => (
                    <MenuItem key={versionget._id} value={versionget.title}>
                      {versionget.title}
                    </MenuItem>
                  ))}
                </Select>
                {versionname ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors["car_information.version"]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  Body Type
                  <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>
                <Select
                  className="text-field"
                  name="carype"
                  selected
                  // sx={{ width: "26ch" }}
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={props.enquriyInfo.car_information.body_type}
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  error={
                    props.allValidationErrors && versionname
                      ? ""
                      : props.allValidationErrors["car_information.body_type"]
                  }
                >
                  <MenuItem key={0} value={versionBodyType}>
                    {props.enquriyInfo.car_information.body_type}
                  </MenuItem>
                </Select>
                {versionname ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors["car_information.body_type"]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  Year<strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>
                {versionYears.length === 1 ? (
                  <Select
                    className="text-field"
                    name="car"
                    readOnly
                    // sx={{ width: "26ch" }}
                    labelid="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={+year}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                  ></Select>
                ) : (

                  <Select
                    className="text-field"
                    displayempty="true"
                    // sx={{ width: "26ch" }}
                    labelid="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={+props.enquriyInfo.car_information.year}
                    onChange={(e) => {
                      console.log(+e.target.value);
                      setYear(+e.target.value);
                      props.enquriyInfo.car_information.year = +e.target.value;
                    }}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    error={
                      props.allValidationErrors && year
                        ? ""
                        : props.allValidationErrors["car_information.year"]
                    }
                  >
                    {versionYears &&
                      versionYears.map((year, index) => {
                        return (
                          <MenuItem key={index} value={+year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
                {year ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors["car_information.year"]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  Transmission
                  <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>
                <Select
                  className="text-field"
                  displayempty="true"
                  // sx={{ width: "26ch" }}
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  selected={versionTransmission.length === 1}
                  value={props.enquriyInfo.car_information.transmission}
                  onChange={(e) => {
                    props.enquriyInfo.car_information.transmission = e.target.value;
                    setTransmission(e.target.value);
                    props.allValidationErrors["car_information.transmission"] = "";
                  }}
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  error={
                    props.allValidationErrors && versionname
                      ? ""
                      : props.allValidationErrors["car_information.transmission"]
                  }
                >
                  {versionTransmission &&
                    versionTransmission.map((transmission, index) => {
                      return (
                        <MenuItem key={index} value={transmission}>
                          {transmission}
                        </MenuItem>
                      );
                    })}
                </Select>
                {transmission ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors[
                      "car_information.transmission"
                      ]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  Fuel Type
                  <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>
                {versionFuelType.length === 1 ? (
                  <Select
                    className="text-field"
                    name="carype"
                    value={0}
                    displayEmpty
                    // sx={{ width: "26ch" }}
                    labelid="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={0}>{fuel}</MenuItem>
                  </Select>
                ) : (
                  <Select
                    className="text-field"
                    displayempty="true"
                    // sx={{ width: "26ch" }}
                    labelid="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={fuel}
                    onChange={(e) => {
                      setFuel(e.target.value);
                      props.enquriyInfo.car_information.fuel_type =
                        e.target.value;
                    }}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    error={
                      props.allValidationErrors && versionname
                        ? ""
                        : props.allValidationErrors["car_information.fuel_type"]
                    }
                  >
                    {versionFuelType &&
                      versionFuelType.map((fuel, index) => {
                        return (
                          <MenuItem key={index} value={fuel}>
                            {fuel}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
                {fuel ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors["car_information.fuel_type"]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  Colors<strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>
                <Select
                  className="text-field"
                  name="car"
                  displayempty="true"
                  // sx={{ width: "26ch" }}
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={props.enquriyInfo.car_information.color}
                  onChange={(e) => {
                    settingColor(e.target.value);
                    props.enquriyInfo.car_information.color = e.target.value;
                    setVersionColor(e.target.value);
                  }}
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  error={
                    props.allValidationErrors && selectedColor
                      ? ""
                      : props.allValidationErrors["car_information.color"]
                  }
                >
                  {versionColors &&
                    versionColors.map((e, index) => {
                      return (
                        <MenuItem key={index} value={e}>
                          {e}
                        </MenuItem>
                      );
                    })}
                </Select>
                {selectedColor ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors["car_information.color"]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  Ex Show Room Price
                  <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>
                <NumericFormat
                  className="text-field"
                  name="carwro"
                  customInput={TextField}
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  variant="standard"
                  sx={{ cursor: "pointer" }}
                  prefix="₹"
                  isAllowed={withValueCap}
                  getInputRef={inputRef}
                  input={<OutlinedInput />}
                  value={exShowRoomPrice}
                  onValueChange={(e) => {
                    setExShowRoomPrice(e.floatValue);
                    props.enquriyInfo.car_information.ex_showroom_price =
                      e.floatValue;
                  }}
                  thousandSeparator
                  thousandsGroupStyle="lakh"
                  error={
                    props.allValidationErrors && exShowRoomPrice
                      ? ""
                      : props.allValidationErrors[
                      "car_information.ex_showroom_price"
                      ]
                  }
                />
                {exShowRoomPrice ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors[
                      "car_information.ex_showroom_price"
                      ]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  Registration Price
                  <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>
                <NumericFormat
                  className="text-field"
                  name="cardua"
                  customInput={TextField}
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  variant="standard"
                  sx={{ cursor: "pointer" }}
                  // inputProps={{ readOnly: true }}
                  prefix="₹"
                  isAllowed={withValueCap}
                  getInputRef={inputRef}
                  value={registrationPrice}
                  onValueChange={(e) => {
                    setRegistrationPrice(e.floatValue);
                    props.enquriyInfo.car_information.individual_registration_price =
                      e.floatValue;
                  }}
                  thousandSeparator
                  thousandsGroupStyle="lakh"
                  error={
                    props.allValidationErrors && registrationPrice
                      ? ""
                      : props.allValidationErrors[
                      "car_information.individual_registration_price"
                      ]
                  }
                />
                {registrationPrice ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors[
                      "car_information.individual_registration_price"
                      ]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  Insurance
                  <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>
                <NumericFormat
                  className="text-field"
                  name="carnce"
                  customInput={TextField}
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  variant="standard"
                  sx={{ cursor: "pointer" }}
                  // inputProps={{ readOnly: true }}
                  prefix="₹"
                  isAllowed={withValueCap}
                  getInputRef={inputRef}
                  value={insurance}
                  onValueChange={(e) => {
                    setInsurance(e.floatValue);
                    props.enquriyInfo.car_information.insurance = e.floatValue;
                  }}
                  thousandSeparator
                  thousandsGroupStyle="lakh"
                  error={
                    props.allValidationErrors && insurance
                      ? ""
                      : props.allValidationErrors["car_information.insurance"]
                  }
                />
                {insurance ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors["car_information.insurance"]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  Other Charges
                  <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>
                <NumericFormat
                  className="text-field"
                  name="carcha"
                  customInput={TextField}
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  variant="standard"
                  sx={{ cursor: "pointer" }}
                  // inputProps={{ readOnly: true }}
                  prefix="₹"
                  isAllowed={withValueCap}
                  getInputRef={inputRef}
                  value={otherCharges}
                  onValueChange={(e) => {
                    setOtherCharges(e.floatValue);
                    props.enquriyInfo.car_information.other_charges =
                      e.floatValue;
                  }}
                  thousandSeparator
                  thousandsGroupStyle="lakh"
                  error={
                    props.allValidationErrors && otherCharges
                      ? ""
                      : props.allValidationErrors[
                      "car_information.other_charges"
                      ]
                  }
                />
                {otherCharges ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors[
                      "car_information.other_charges"
                      ]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading">
                  On Road Price
                  <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
                </Typography>
                <NumericFormat
                  className="text-field"
                  name="car_p"
                  customInput={TextField}
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  variant="standard"
                  sx={{ cursor: "pointer" }}
                  // inputProps={{ readOnly: true }}
                  prefix="₹"
                  isAllowed={withValueCap}
                  getInputRef={inputRef}
                  value={onRoadPrice}
                  onValueChange={(e) => {
                    props.enquriyInfo.car_information.on_road_price =
                      e.floatValue;
                    setOnRoadPrice(e.floatValue);
                    setNetPriceEventEmitter();
                    setTotalPriceEventEmiter();
                  }}
                  thousandSeparator
                  thousandsGroupStyle="lakh"
                  error={
                    props.allValidationErrors && onRoadPrice
                      ? ""
                      : props.allValidationErrors[
                      "car_information.on_road_price"
                      ]
                  }
                />
                {onRoadPrice ? (
                  ""
                ) : (
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors[
                      "car_information.on_road_price"
                      ]
                      : ""}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <Skeleton variant="rectangular" width="100%">
            <div style={{ paddingTop: "57%" }} />
          </Skeleton>
        )}
      </Card>
    </div>
  );
}

export default AddEnquiryCarInfo;
