import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import EditProgress from "./enquiries/Add/EditProgress";
// import "../scss/EditEnquiry.scss";
import { Container } from "semantic-ui-react";
import { Box } from "@mui/material";

function EditEnquiry() {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
  }, []);

  return (
    // <div id="EditEnquiry">
    <div
      id="addEnquiry"
      style={{
        padding: "1rem 1.25em 0.75rem",
        background: "#F2F2F2",
      }}
    >
      <Box style={{ display: "contents" }}>
        <Container>
          <EditProgress></EditProgress>
        </Container>
      </Box>
    </div>
  );
}

export default EditEnquiry;
