//react
import { useEffect, useState } from "react";
import * as React from "react";

//importing Routes
import { useNavigate } from "react-router-dom";

import { NumericFormat } from "react-number-format";

//importing Redux
import { useSelector } from "react-redux";

//importing materialUI
import {
  Card,
  FormControl,
  Grid,
  Select,
  TextField,
  Typography,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

//importing moment
import moment from "moment";

//importing from local files
import EventEmitter from "../../utils/Emitter";
import "../scss/add/AddBookingInfo.scss";

function AddBookingInfo(props) {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // local state
  const { inputRef } = props;
  const [loading, setLoading] = useState(false);

  const [vehicle_status, setVehicleStatus] = useState("");
  const [exchange, setExchange] = useState("");
  const [ew, setEW] = useState("");
  const [ew_amount, setEWAmount] = useState("");
  const [auto_card_amount, setACAmount] = useState("");
  const [auto_card, setAC] = useState("");
  const [ba, setBA] = useState("");
  const [special_no, setSpecialNo] = useState("");
  const [affidavit, setAffidavit] = useState("");
  const [other_vehicles_own, setOtherVehicles] = useState("");
  const [allValidationErrors, setAllValidationErrors] = useState({});
  const [deliveryDate, setDeliveryDateValue] = React.useState(null);
  const [bookingDateValue, setBookingDateValue] = React.useState(null);
  const [registrationDateValue, setRegistrationDateValue] =
    React.useState(null);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  // Expected Date of Delivery
  const handleDeliveryDate = (newValue) => {
    setDeliveryDateValue(newValue);
    props.enquriyInfo.booking_info.expected_date_of_delivery = new Date(
      moment(new Date(newValue)).format("YYYY-MM-DD")
    );
  };

  // Booking Date
  const handleBookingDate = (evt) => {
    setBookingDateValue(evt);
    props.enquriyInfo.booking_info.booking_date = new Date(
      moment(new Date(evt)).format("YYYY-MM-DD")
    );
  };

  // Expected Date of Registration
  const handleRegistrationDate = (newdateValue) => {
    setRegistrationDateValue(newdateValue);
    props.enquriyInfo.booking_info.expected_date_of_registration = new Date(
      moment(new Date(newdateValue)).format("YYYY-MM-DD")
    );
  };

  // On Change of Exchange
  const handleExchange = (event) => {
    setExchange(event.target.value);

    if (event.target.value == "true") {
      props.enquriyInfo.booking_info.exchange = true;
    } else {
      props.enquriyInfo.booking_info.exchange = false;
    }
    props.allValidationErrors["booking_info.exchange"] = "";
  };

  // On change of other Vehicles
  const handleOtherVehiclesChanged = (event) => {
    setOtherVehicles(event.target.value);
    if (event.target.value == "true") {
      props.enquriyInfo.booking_info.other_vehicles_own = true;
    } else {
      props.enquriyInfo.booking_info.other_vehicles_own = false;
    }
    props.allValidationErrors["booking_info.other_vehicles_own"] = "";
  };

  // On Change of EW
  const handleEwChanged = (event) => {
    setEW(event.target.value);
    if (event.target.value == "true") {
      props.enquriyInfo.booking_info.e_w = true;
    } else {
      props.enquriyInfo.booking_info.e_w_amount = null;
      props.enquriyInfo.booking_info.e_w = false;
    }
    props.allValidationErrors["booking_info.e_w"] = "";

    setTotalPriceEventEmiter();
    setNetPriceEventEmitter();
  };

  // On Change of Auto Card(AC)
  const handleAcChanged = (event) => {
    setAC(event.target.value);
    if (event.target.value == "true") {
      props.enquriyInfo.booking_info.auto_card = true;
    } else {
      props.enquriyInfo.booking_info.auto_card_amount = null;
      props.enquriyInfo.booking_info.auto_card = false;
    }

    setTotalPriceEventEmiter();
    setNetPriceEventEmitter();
    props.allValidationErrors["booking_info.auto_card"] = "";
  };

  // On Change of BA
  const handleBaChanged = (event) => {
    setBA(event.target.value);
    if (event.target.value == "true") {
      props.enquriyInfo.booking_info.b_a = true;
    } else {
      props.enquriyInfo.booking_info.b_a = false;
    }
    props.allValidationErrors["booking_info.b_a"] = "";
  };

  // On Change of Special Number
  const handleSpecialNoChanged = (event) => {
    setSpecialNo(event.target.value);
    if (event.target.value == "true") {
      props.enquriyInfo.booking_info.special_no = true;
    } else {
      props.enquriyInfo.booking_info.special_no = false;
    }
    props.allValidationErrors["booking_info.special_no"] = "";
  };

  // On Change od Affidavit
  const handleAffidavitChanged = (event) => {
    setAffidavit(event.target.value);
    if (event.target.value == "true") {
      props.enquriyInfo.booking_info.affidavit = true;
    } else {
      props.enquriyInfo.booking_info.affidavit = false;
    }
    props.allValidationErrors["booking_info.affidavit"] = "";
  };

  //Calculated Price after adding EW and AC on change of Ew or Ac amount
  const setTotalPriceEventEmiter = () => {
    EventEmitter.emit("on_road_price", {
      value:
        (props.enquriyInfo.car_information.on_road_price || 0) +
        (parseInt(
          props.enquriyInfo.booking_info.e_w
            ? props.enquriyInfo.booking_info.e_w_amount || 0
            : 0
        ) +
          parseInt(
            props.enquriyInfo.booking_info.auto_card
              ? props.enquriyInfo.booking_info.auto_card_amount || 0
              : 0
          )),
    });
  };

  // Net Price to other components on change of Ew and Ac amount
  const setNetPriceEventEmitter = () => {
    EventEmitter.emit("net_price", {
      value:
        (props.enquriyInfo.car_information.on_road_price || 0) +
        (parseInt(
          props.enquriyInfo.booking_info.e_w
            ? props.enquriyInfo.booking_info.e_w_amount || 0
            : 0
        ) +
          parseInt(
            props.enquriyInfo.booking_info.auto_card
              ? props.enquriyInfo.booking_info.auto_card_amount || 0
              : 0
          )) -
        props.enquriyInfo.booking_info.total_discounts -
        (props.enquriyInfo.booking_info.less_by_party_reg +
          props.enquriyInfo.booking_info.less_by_party_insurance),
    });
  };

  const MAX_VAL = 1000000000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // pagination url

  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div id="addBooking">
      <Card className="booking-info" variant="outlined">
        <Typography className="booking-title" mb={1}>
          Booking Details
        </Typography>
        {/* <Divider /> */}
        <Grid className="block-inner" container spacing={3}>
          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading-1">Booking Date   <strong style={{ color: "rgb(228 12 15)" }}>*</strong></Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  className="text-field"
                  inputFormat="DD/MM/YYYY"
                  mask="__/__/____"
                  value={props.enquriyInfo.booking_info.booking_date}
                  onChange={handleBookingDate}
                  renderInput={(params) => (
                    <TextField name="boodat" {...params} />
                  )}
                  disablePast={true}
                />
              </LocalizationProvider>
              {bookingDateValue ? (
                ""
              ) : (
                <Typography
                  variant="h6"
                  color="error"
                  style={{ fontSize: "12px", paddingLeft: "15px" }}
                >
                  {props.allValidationErrors
                    ? props.allValidationErrors["booking_info.booking_date"]
                    : ""}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading-1">
                Expected Dt Of Delivery  <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>


              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3} className="text-field">
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={
                      props.enquriyInfo.booking_info.expected_date_of_delivery
                    }
                    disablePast={true}
                    name="boodat"
                    onChange={handleDeliveryDate}
                    renderInput={(params) => (
                      <TextField name="boodat" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              {deliveryDate ? (
                ""
              ) : (
                <Typography
                  variant="h6"
                  color="error"
                  style={{ fontSize: "12px", paddingLeft: "15px" }}
                >
                  {props.allValidationErrors
                    ? props.allValidationErrors[
                    "booking_info.expected_date_of_delivery"
                    ]
                    : ""}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading-1">
                Expected Dt Of Registration   <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3} className="text-field">
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={
                      props.enquriyInfo.booking_info
                        .expected_date_of_registration
                    }
                    disablePast={true}
                    onChange={handleRegistrationDate}
                    renderInput={(params) => (
                      <TextField name="boodat" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              {registrationDateValue ? (
                ""
              ) : (
                <Typography
                  variant="h6"
                  color="error"
                  style={{ fontSize: "12px", paddingLeft: "15px" }}
                >
                  {props.allValidationErrors
                    ? props.allValidationErrors[
                    "booking_info.expected_date_of_registration"
                    ]
                    : ""}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item className="block-item" xs={4}>
            <Typography className="heading-1">Other Vehicles Owned</Typography>
            <div className="select-radio">
              <div  className="radio-group">
                <input
                  type="radio"
                  value={true}
                  checked={
                    props.enquriyInfo.booking_info.other_vehicles_own === true
                  }
                  onChange={handleOtherVehiclesChanged}
                />
                <span className="radio-text">Yes</span>
              </div>
              <div  className="radio-group">
                <input
                  type="radio"
                  value={false}
                  checked={
                    props.enquriyInfo.booking_info.other_vehicles_own === false
                  }
                  onChange={handleOtherVehiclesChanged}
                />
                <span className="radio-text">No</span>
              </div>
            </div>

            <Typography
              variant="h6"
              color="error"
              style={{ fontSize: "12px", paddingLeft: "15px" }}
            >
              {props.allValidationErrors
                ? props.allValidationErrors["booking_info.other_vehicles_own"]
                : ""}
            </Typography>
          </Grid>

          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading-1">Exchange</Typography>
              <div className="select-radio">
                <div className="radio-group">
                  <input
                    type="radio"
                    value="true"
                    checked={props.enquriyInfo.booking_info.exchange === true}
                    onChange={handleExchange}
                  />
                  <span className="radio-text">Yes</span>
                </div>
                <div  className="radio-group">
                  <input
                    type="radio"
                    value="false"
                    checked={props.enquriyInfo.booking_info.exchange === false}
                    onChange={handleExchange}
                  />
                  <span className="radio-text">No</span>
                </div>
              </div>

              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["booking_info.exchange"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>

          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Vehicle Status   <strong style={{ color: "rgb(228 12 15)" }}>*</strong></Typography>
              <Select
                className="text-field"
                displayempty="true"
                // sx={{ width: "30ch" }}
                name="bootat"
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={props.enquriyInfo.booking_info.vehicle_status}
                onChange={(e) => {
                  props.enquriyInfo.booking_info.vehicle_status =
                    e.target.value;
                  setVehicleStatus(e.target.value);
                }}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
              >
                <MenuItem value="INDENT">Indent</MenuItem>
                <MenuItem value="TRANSIT">Transit</MenuItem>
                <MenuItem value="FREE STOCK">Free Stock</MenuItem>
              </Select>
              {props.enquriyInfo.booking_info.vehicle_status ? (
                ""
              ) : (
                <Typography
                  variant="h6"
                  color="error"
                  style={{ fontSize: "12px", paddingLeft: "15px" }}
                >
                  {props.allValidationErrors
                    ? props.allValidationErrors["booking_info.vehicle_status"]
                    : ""}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading-1">BA</Typography>
              <div className="select-radio">
                <div  className="radio-group">
                  <input
                    type="radio"
                    value="true"
                    checked={props.enquriyInfo.booking_info.b_a === true}
                    onChange={handleBaChanged}
                  />
                  <span className="radio-text">Yes</span>
                </div>
                <div  className="radio-group">
                  <input
                    type="radio"
                    value="false"
                    checked={props.enquriyInfo.booking_info.b_a === false}
                    onChange={handleBaChanged}
                  />
                  <span className="radio-text">No</span>
                </div>
              </div>
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["booking_info.b_a"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>

          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading-1">Special No</Typography>
              <div className="select-radio">
                <div  className="radio-group">
                  <input
                    type="radio"
                    value="true"
                    checked={props.enquriyInfo.booking_info.special_no === true}
                    onChange={handleSpecialNoChanged}
                  />
                  <span className="radio-text">Yes</span>
                </div>
                <div  className="radio-group">
                  <input
                    type="radio"
                    value="false"
                    name="booo"
                    checked={
                      props.enquriyInfo.booking_info.special_no === false
                    }
                    onChange={handleSpecialNoChanged}
                  />
                  <span className="radio-text">No</span>
                </div>
              </div>
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["booking_info.special_no"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>

          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading-1">Affidavit</Typography>
              <div className="select-radio">
                <div  className="radio-group">
                  <input
                    type="radio"
                    value={true}
                    name="boo"
                    checked={props.enquriyInfo.booking_info.affidavit === true}
                    onChange={handleAffidavitChanged}
                  />
                  <span className="radio-text">Yes</span>
                </div>
                <div  className="radio-group">
                  <input
                    type="radio"
                    value={false}
                    name="boo"
                    checked={props.enquriyInfo.booking_info.affidavit === false}
                    onChange={handleAffidavitChanged}
                  />
                  <span className="radio-text">No</span>
                </div>
              </div>
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["booking_info.affidavit"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>

          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner" sx={{ width: 300 }}>
              <Typography className="heading-1">EW</Typography>
              <div className="select-radio">
                <div  className="radio-group">
                  <input
                    type="radio"
                    value="true"
                    checked={props.enquriyInfo.booking_info.e_w === true}
                    onChange={handleEwChanged}
                  />
                  <span className="radio-text">Yes</span>
                </div>
                <div  className="radio-group">
                  <input
                    type="radio"
                    value="false"
                    checked={props.enquriyInfo.booking_info.e_w === false}
                    onChange={handleEwChanged}
                  />
                  <span className="radio-text">No</span>
                </div>
              </div>

              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["booking_info.e_w"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>

          <Grid item className="form-block" xs={8}>
            <FormControl className="form-inner">
              <Typography className="heading-1">AC</Typography>
              <div className="select-radio">
                <div  className="radio-group">
                  <input
                    type="radio"
                    value="true"
                    checked={props.enquriyInfo.booking_info.auto_card === true}
                    onChange={handleAcChanged}
                  />
                  <span className="radio-text">Yes</span>
                </div>
                <div  className="radio-group">
                  <input
                    type="radio"
                    value="false"
                    checked={props.enquriyInfo.booking_info.auto_card === false}
                    onChange={handleAcChanged}
                  />
                  <span className="radio-text">No</span>
                </div>
              </div>
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["booking_info.auto_card"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>

          {props.enquriyInfo.booking_info.e_w === true && (
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading-1">EW Amount  <strong style={{ color: "rgb(228 12 15)" }}>*</strong></Typography>
                <NumericFormat
                  className="text-field"
                  customInput={TextField}
                  name="boot"
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  style={{
                    cursor: "pointer",
                    border: "1px blue",
                  }}
                  prefix="₹"
                  isAllowed={withValueCap}
                  getInputRef={inputRef}
                  value={props.enquriyInfo.booking_info.e_w_amount}
                  onValueChange={(e) => {
                    setEWAmount(parseInt(e.floatValue));
                    props.enquriyInfo.booking_info.e_w_amount = e.floatValue;
                    setTotalPriceEventEmiter();
                    setNetPriceEventEmitter();
                    props.allValidationErrors["booking_info.e_w_amount"] = "";
                  }}
                  thousandSeparator
                  thousandsGroupStyle="lakh"
                  helpertext={
                    props.allValidationErrors
                      ? props.allValidationErrors["booking_info.e_w_amount"]
                      : ""
                  }
                  error={
                    props.allValidationErrors
                      ? props.allValidationErrors["booking_info.e_w_amount"]
                      : ""
                  }
                />
                <Typography
                  variant="h6"
                  color="error"
                  style={{ fontSize: "12px", paddingLeft: "15px" }}
                >
                  {props.allValidationErrors &&
                    props.allValidationErrors["booking_info.e_w_amount"]
                    ? props.allValidationErrors["booking_info.e_w_amount"]
                    : ""}
                </Typography>
              </FormControl>
            </Grid>
          )}

          {props.enquriyInfo.booking_info.auto_card === true && (
            <Grid item className="form-block" xs={4}>
              <FormControl className="form-inner">
                <Typography className="heading-1">AC Amount   <strong style={{ color: "rgb(228 12 15)" }}>*</strong></Typography>
                <NumericFormat
                  className="text-field"
                  customInput={TextField}
                  name="boo_am"
                  labelid="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  style={{
                    cursor: "pointer",
                    border: "1px blue",
                  }}
                  prefix="₹"
                  isAllowed={withValueCap}
                  getInputRef={inputRef}
                  value={props.enquriyInfo.booking_info.auto_card_amount}
                  onValueChange={(e) => {
                    setACAmount(parseInt(e.floatValue));
                    props.enquriyInfo.booking_info.auto_card_amount = parseInt(
                      e.floatValue
                    );
                    setTotalPriceEventEmiter();
                    setNetPriceEventEmitter();
                    props.allValidationErrors["booking_info.auto_card_amount"] =
                      "";
                  }}
                  thousandSeparator
                  thousandsGroupStyle="lakh"
                  helpertext={
                    props.allValidationErrors
                      ? props.allValidationErrors[
                      "booking_info.auto_card_amount"
                      ]
                      : ""
                  }
                  error={
                    props.allValidationErrors
                      ? props.allValidationErrors[
                      "booking_info.auto_card_amount"
                      ]
                      : ""
                  }
                />
                <Typography
                  variant="h6"
                  color="error"
                  style={{ fontSize: "12px", paddingLeft: "15px" }}
                >
                  {props.allValidationErrors &&
                    props.allValidationErrors["booking_info.auto_card_amount"]
                    ? props.allValidationErrors["booking_info.auto_card_amount"]
                    : ""}
                </Typography>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
}

export default AddBookingInfo;
