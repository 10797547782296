//react
import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { Button, Card, IconButton, Grid, Typography, Tooltip, Zoom } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadFileIcon from "@mui/icons-material/UploadFile";

import moment from "moment";
import { store } from "./ViewProgress";

import "../scss/view/_viewPersonal.scss";

function ViewEnquiryPersonal({ enquriyInfo }) {

  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const ref = useRef(null);
  const navigate = useNavigate();
  const params = useParams();

  const [visible, setVisible] = useState(true);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 0) {
      setVisible(false);
    } else if (scrolled <= 0) {
      setVisible(true);
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
    });
  };
  const handleSubmit = () => {
    navigate(`/enquiries/${params.id}/edit`)
  };

  const handleBack = () => {
    navigate(`/enquiries`)
  };

  window.addEventListener("scroll", toggleVisible);

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
  }, []);

  return (
    <div id="viewPersonal">
      <Card className="personal-info" variant="outlined">
        <div className="title-block">
          <span className="title">Personal Information</span>
        </div>
        <div className="btn">
          <IconButton>
            <UploadFileIcon
              onClick={scrollToBottom}
              style={{ display: visible ? "inline" : "none" }}
            />
          </IconButton>
        </div>
        <div className="floatEditBtn"  style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zindex: "9999",
            display: "flex",
            gap: "12px"
          }}>
            <Tooltip title="Clear" arrow  placement="top" TransitionComponent={Zoom}>
              <IconButton aria-label="delete" size="large" onClick={handleBack} style={{ backgroundColor: "#224466", color: "#ffffff" }}>
                  <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Enquiry" arrow  placement="top" TransitionComponent={Zoom}>
              <IconButton aria-label="delete" size="large" onClick={handleSubmit} style={{ backgroundColor: "#ff5555", color: "#ffffff", fontSize: "50px" }}>
                  <EditIcon />
              </IconButton>
            </Tooltip>
        </div>

        <Grid container spacing={2} rowGap={2}>
          <Grid item xs={6}>
            <Typography className="viewtitle">First Name</Typography>
            <span className="details" style={{ textTransform: "capitalize" }}>
              {enquriyInfo.personal_information?.first_name
                ? enquriyInfo.personal_information?.first_name
                : "-"}
            </span>
          </Grid>
          <Grid item xs={6}>
            <Typography className="viewtitle">Last Name</Typography>
            <span className="details" style={{ textTransform: "capitalize" }}>
              {enquriyInfo.personal_information.last_name}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Mobile Number(R)</Typography>
            <span className="details">
              {enquriyInfo.personal_information.phone_number_r}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Mobile Number(O)</Typography>
            <span className="details">
              {enquriyInfo.personal_information.phone_number_o
                ? enquriyInfo.personal_information.phone_number_o
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Mobile Number(C)</Typography>
            <span className="details">
              {enquriyInfo.personal_information.phone_number_c
                ? enquriyInfo.personal_information.phone_number_c
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Fax</Typography>
            <span className="details">
              {enquriyInfo.personal_information.fax
                ? enquriyInfo.personal_information.fax
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Email Id</Typography>
            <span className="details">
              {enquriyInfo.personal_information.email_id}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Date Of Birth</Typography>
            <span className="details">
              {moment(enquriyInfo.personal_information.date_of_birth).format(
                "DD-MM-YYYY"
              )}
            </span>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default ViewEnquiryPersonal;
