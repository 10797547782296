//react
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Alert, Box, Button, Card, TextField, Typography } from "@mui/material";
import ProfileUploader from "./ProfileUploader";

import Avatar from "@mui/joy/Avatar";
import "../components/enquiries/scss/EditProfile.scss";

const EditProfile = () => {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();
  let params = useParams();

  // local state
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [user_type, setUserType] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [profile_pic, setProfilePic] = useState("");
  const [message, setMessage] = useState("");
  const [upAlertShow, setUpAlertShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edituser, setEditUser] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    phone: "",
    email: "",
  });
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    phone: "",
    email: "",
  });

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    getuserdata();
  }, []);

  // get user profile edit data

  const getuserdata = async () => {
    try {
      let url = "${process.env.REACT_APP_API_BASE_URL}/users/profile/";
      let options = {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let result = await fetch(url, options);
      result = await result.json().then((edituser) => {
        console.log(edituser.data);
        setEditUser(edituser.data);
        setProfilePic(edituser.data.profile_pic);
        setFirstName(edituser.data.first_name);
        setLastName(edituser.data.last_name);
        setUserType(edituser.data.user_type);
        setEmail(edituser.data.email);
        setPhone(edituser.data.phone);
      });
    } catch (err) {
      console.error(err);
    }
  };
  //event
  // edit and update department names
  let handleUpdate = async (e) => {
    console.log("onclick Update buton");
    e.preventDefault();
    try {
      setLoading(true);

      let updateUrl = `${process.env.REACT_APP_API_BASE_URL}/users/update-user/${params.data}`;
      let updateOptions = {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
        body: JSON.stringify({
          first_name: first_name,
          last_name: last_name,
          user_type: user_type,
          email: email,
          phone: phone,
        }),
      };
      let res = await fetch(updateUrl, updateOptions);
      res = await res.json();
      setLoading(false);
      let resJson = await res.json();
      if (res.status === 200 || res.status === 201) {
        console.log(first_name);
        setUpAlertShow(true);
        setTimeout(() => {
          setUpAlertShow(false);
          navigate("/userProfile");
        }, 1500);

        setFirstName("");
        setLastName("");
        setUserType("");
        setEmail("");
        setPhone("");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id="EditProfile">
      <p>Edit User Profile</p>
      <div>
        <Box className="box">
          <Card className="card" variant="outlined">
            <div className="title">
              <Link to="/profile">
                <Button variant="btn btn-primary">View Profile</Button>
              </Link>
            </div>

            <Box className="form-block">
              <form onSubmit={handleUpdate}>
                <ProfileUploader></ProfileUploader>
                <div>
                  <Avatar src={userData.profile_pic} />
                  <Typography>First Name </Typography>
                  <TextField
                    sx={{ background: "#f6f7f9" }}
                    type="text"
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div>
                  <Typography>Last Name</Typography>
                  <TextField
                    sx={{ background: "#f6f7f9" }}
                    type="text"
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div>
                  <Typography>User Type</Typography>
                  <TextField
                    sx={{ background: "#f6f7f9" }}
                    type="text"
                    value={user_type}
                    onChange={(e) => setUserType(e.target.value)}
                  />
                </div>
                <div>
                  <Typography>Email Id</Typography>
                  <TextField
                    sx={{ background: "#f6f7f9" }}
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <Typography>Phone Number</Typography>
                  <TextField
                    sx={{ background: "#f6f7f9" }}
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="message">
                  {message ? <p>{message}</p> : null}
                </div>
                <div style={{ float: "right" }}>
                  <Button className="button" type="submit" variant="success">
                    Update
                  </Button>
                </div>
              </form>
              {loading ? <div className="spinner"></div> : <div></div>}
            </Box>
          </Card>
        </Box>
      </div>

      {upAlertShow && (
        <Alert className="custm-alert-update">
          <p>Profile Updated successfully</p>
        </Alert>
      )}
    </div>
  );
};

export default EditProfile;
