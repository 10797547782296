//react
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//import redux store
import { useSelector } from "react-redux";

//import MaterialUI
import { Card, FormControl, TextField, Typography, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import moment
import moment from "moment";
import "../scss/add/AddEnquiryPersonal.scss";

function AddEnquiryPersonal(props) {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // local state
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone_number_r, setPhoneNumberR] = useState("");
  const [phone_number_o, setPhoneNumberO] = useState("");
  const [phone_number_c, setPhoneNumberC] = useState("");
  const [fax, setFax] = useState("");
  const [email_id, setEmailId] = useState("");
  const [value, setValue] = useState("01/01/1900");
  const [errorMessage, setErrorMessage] = useState('');


  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
  }, []);

  //setting the Default Date of Birth
  const handleSettingDOB = () => {
    const date = new Date("2000-01-01");
    props.enquriyInfo.personal_information.date_of_birth = moment(
      new Date(date)
    ).format("YYYY-MM-DD");
  };

  // handle on Date of Birth Change
  const [isValid, setIsValid] = useState(true);

  const handleDOBChange = (newValue) => {
    if (moment(new Date(newValue)).format("YYYY-MM-DD") == "Invalid date") {
      setValue("01/01/1900");
      setErrorMessage("Please Enter valid Date")
    }
    else if (newValue < new Date("01/01/1900")) {
      setValue("01/01/1900");
      setErrorMessage('Please select a date after 1900.');

      props.enquriyInfo.personal_information.date_of_birth = new Date(1990, 0, 1)
    }
    else {
      setErrorMessage("")
      setValue(newValue);
      props.enquriyInfo.personal_information.date_of_birth = moment(
        new Date(newValue)
      ).format("YYYY-MM-DD");
    }

  };


  const validateDate = (dateString) => {
    console.log(dateString)


    const pattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

    if (!pattern.test(dateString)) {
      return false;
    }

    const [day, month, year] = dateString.split('/');
    const parsedMonth = parseInt(month, 10);

    // Validate month value
    if (parsedMonth < 1 || parsedMonth > 12) {
      return false;
    }

    // Additional date validation checks if needed

    return true;
  };

  return (
    <div id="Personal">
      <Card className="personal-info" variant="outlined">
        <Grid className="block-inner" container spacing={3}>
          <Grid className="block-item" item xs={12}>
            <Typography className="title">Personal Information</Typography>
            <Typography className="paragraph">
              Please enter customer primary details
            </Typography>
          </Grid>
          <Grid className="form-block" item xs={6}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">
                First Name<strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              <TextField
                className="text-field"
                type="text"
                name="perirs"
                variant="standard"
                maxLength={20}
                id="filled-basic"
                value={props.enquriyInfo.personal_information.first_name}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  props.enquriyInfo.personal_information.first_name =
                    e.target.value;
                  props.allValidationErrors["personal_information.first_name"] =
                    null;
                }}
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                    "personal_information.first_name"
                    ]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["personal_information.first_name"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={6}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">
                Last Name<strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              <TextField
                className="text-field"
                name="perast"
                id="outlined-basic"
                variant="standard"
                value={props.enquriyInfo.personal_information.last_name}
                onChange={(e) => {
                  setLastName(e.target.value);
                  props.enquriyInfo.personal_information.last_name =
                    e.target.value;
                  props.allValidationErrors["personal_information.last_name"] =
                    "";
                }}
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                    "personal_information.last_name"
                    ]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["personal_information.last_name"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={4}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">
                Mobile Number(R)
                <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              <TextField
                className="text-field"
                name="perhon"
                type="number"
                id="outlined-basic"
                variant="standard"
                inputProps={{
                  maxLength: 10,
                }}
                value={props.enquriyInfo.personal_information.phone_number_r}
                onChange={(e) => {
                  setPhoneNumberR(e.target.value);
                  props.enquriyInfo.personal_information.phone_number_r =
                    e.target.value;
                  props.allValidationErrors[
                    "personal_information.phone_number_r"
                  ] = "";
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                    "personal_information.phone_number_r"
                    ]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors[
                  "personal_information.phone_number_r"
                  ]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={4}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Mobile Number(O)</Typography>
              <TextField
                className="text-field"
                id="outlined-basic"
                type="number"
                variant="standard"
                value={props.enquriyInfo.personal_information.phone_number_o}
                onChange={(e) => {
                  setPhoneNumberO(e.target.value);
                  props.enquriyInfo.personal_information.phone_number_o =
                    e.target.value;
                  props.allValidationErrors[
                    "personal_information.phone_number_o"
                  ] = "";
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                    "personal_information.phone_number_o"
                    ]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors[
                  "personal_information.phone_number_o"
                  ]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={4}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Mobile Number(C)</Typography>
              <TextField
                className="text-field"
                type="number"
                id="outlined-basic"
                variant="standard"
                value={props.enquriyInfo.personal_information.phone_number_c}
                onChange={(e) => {
                  setPhoneNumberC(e.target.value);
                  props.enquriyInfo.personal_information.phone_number_c =
                    e.target.value;
                  props.allValidationErrors[
                    "personal_information.phone_number_c"
                  ] = "";
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                    "personal_information.phone_number_c"
                    ]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors[
                  "personal_information.phone_number_c"
                  ]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={4}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Fax</Typography>
              <TextField
                className="text-field"
                id="outlined-basic"
                type="number"
                variant="standard"
                value={props.enquriyInfo.personal_information.fax}
                onChange={(e) => {
                  setFax(e.target.value);
                  props.enquriyInfo.personal_information.fax = e.target.value;
                  props.allValidationErrors["personal_information.fax"] = "";
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors["personal_information.fax"]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["personal_information.fax"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading">
                Email Id<strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              <TextField
                className="text-field"
                id="outlined-basic"
                name="permai"
                variant="standard"
                value={props.enquriyInfo.personal_information.email_id}
                inputProps={{ style: { textTransform: "lowercase" } }}
                onChange={(e) => {
                  setEmailId(e.target.value);
                  props.enquriyInfo.personal_information.email_id =
                    e.target.value;
                  props.allValidationErrors["personal_information.email_id"] =
                    "";
                }}
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors["personal_information.email_id"]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["personal_information.email_id"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading">
                Date of Birth
                <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack className="text-field" spacing={3}>
                  <DatePicker
                    orientation="landscape"
                    openTo="day"
                    inputFormat="DD/MM/YYYY"
                    value={props.enquriyInfo.personal_information.date_of_birth}
                    type="date"
                    views={["year", "month", "day"]}
                    minDate={'01/01/1900'}
                    variant="standard"
                    id="outlined-basic"
                    className="example-custom-input"
                    disableFuture={true}
                    renderInput={(params) => (
                      <TextField name="perate" {...params}

                        error={!!errorMessage}
                        helperText={errorMessage}
                      />
                    )}
                    onChange={handleDOBChange}
                    error={
                      props.allValidationErrors
                        ? props.allValidationErrors[
                        "personal_information.date_of_birth"
                        ]
                        : ""
                    }
                  />
                </Stack>
              </LocalizationProvider>
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors[
                  "personal_information.date_of_birth"
                  ]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default AddEnquiryPersonal;
