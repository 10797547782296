//react
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import "./SalesRepresentativeEdit.css";

function SalesRepresentativeEdit() {
  //store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();
  let params = useParams();

  // local state
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUserName] = useState("");
  const [message, setMessage] = useState("");

  // React Hooks
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    getData();
  }, []);

  // get single edit  enquiry data
  const getData = async () => {
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/sales-representatives/" +params.id`;
      let options = {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let getviewedata = await fetch(url, options);
      getviewedata = await getviewedata.json().then((getviewedata) => {
        setName(getviewedata.name);
        setEmail(getviewedata.email);
        setPhone(getviewedata.phone);
        setUserName(getviewedata.username);
      });
    } catch (err) {
      console.error(err);
    }
  };

  let handleUpdate = async (e) => {
    console.log("onclick submit buton");
    e.preventDefault();
    try {
      let Url = `${process.env.REACT_APP_API_BASE_URL}/sales-representatives/${params.id}`;
      let updateOptions = {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
        body: JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          username: username,
        }),
      };
      let res = await fetch(Url, updateOptions);
      res = await res.json();
      let resJson = await res.json();
      if (res.status === 200 || res.status === 201) {
        navigate("/sales-representative");
        console.log(name);
        setName("");
        setEmail("");
        setPhone("");
        setUserName("");
        setMessage("");
        getData();
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="salesRep-editPage">
      
      <Box style={{ display: "contents" }}>
        
          <form onSubmit={handleUpdate}>
            <Card
              className="cardList"
              sx={{ width: "95ch",margin:"2rem auto", padding: "2rem" }}
              variant="outlined"
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl variant="standard">
                    <Typography>Name</Typography>
                    <TextField
                      sx={{ width: "40ch", background: "#f6f7f9" }}
                      id="outlined-basic"
                      variant="outlined"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard">
                    <Typography>Email</Typography>
                    <TextField
                      sx={{ width: "40ch", background: "#f6f7f9" }}
                      id="outlined-basic"
                      variant="outlined"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard">
                    <Typography>Phone</Typography>
                    <TextField
                      sx={{ width: "40ch", background: "#f6f7f9" }}
                      id="outlined-basic"
                      variant="outlined"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard">
                    <Typography>User Name</Typography>
                    <TextField
                      sx={{ width: "40ch", background: "#f6f7f9" }}
                      id="outlined-basic"
                      variant="outlined"
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ width: "87ch" }}>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ float: "left", marginTop: "2rem" }}
                    >
                      Update
                    </Button>
                  </Typography>
                </Grid>
                <div className="message">
                  {message ? <p>{message}</p> : null}
                </div>
              </Grid>
            </Card>
          </form>
      
      </Box>
    </div>
  );
}

export default SalesRepresentativeEdit;
