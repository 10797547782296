//react
import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { store } from "./ViewProgress";
import { Card, Typography, Grid, FormControl } from "@mui/material";

import "../scss/view/_viewOtherInfo.scss";

function ViewEnquiryOtherInfo({ enquriyInfo }) {

  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
  }, []);

  return (
    <div id="viewOtherInfo">
      <Card className="other-details carlist" variant="outlined">
        <div className="heading">
          <Typography className="title">Other Information</Typography>
        </div>
        <div className="other-info">
          <Grid item xs={6} mt={1}>
            <Typography className="viewtitle">Occupation</Typography>
            <span className="details" style={{ textTransform: "capitalize" }}>
              {enquriyInfo.occupation || "-"}
            </span>
          </Grid>

          {enquriyInfo.occupation == "Corporate" && (
            <div>
              <div>
                <Typography className="title-2">
                  Corporation Details:
                </Typography>
              </div>
              <Grid container spacing={1} mt={1} rowGap={2}>
                <Grid item xs={6}>
                  <Typography className="viewtitle">Company Name</Typography>
                  <span className="details">
                    {enquriyInfo.corporation_details.company_name || "-"}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="viewtitle">Contact Person</Typography>
                  <span className="details">
                    {enquriyInfo.corporation_details.contact_person || "-"}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="viewtitle">Contact Number</Typography>
                  <span className="details">
                    {enquriyInfo.corporation_details.contact_number || "-"}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="viewtitle">Designation</Typography>
                  <span className="details">
                    {enquriyInfo.corporation_details.designation || "-"}
                  </span>
                </Grid>
              </Grid>
            </div>
          )}

          <Grid className="form-block" item xs={12}>
            <FormControl className="form-inner">
              <Typography className="viewtitle">Enquiry Through</Typography>
              <Grid item xs={6}>
                <span
                  className="details"
                  style={{ textTransform: "capitalize" }}
                >
                  {enquriyInfo.enquiry_through || "-"}
                </span>
              </Grid>
              <Grid item>
                {enquriyInfo.enquiry_through == "Others" && (
                  <Grid className="form-block" item xs={4} mt={2}>
                    <FormControl className="form-inner" variant="standard">
                      <Typography className="viewtitle">
                        Other Enquiry Details
                      </Typography>
                      <Grid item xs={6}>
                        <span
                          className="details"
                          style={{ textTransform: "capitalize" }}
                        >
                          {enquriyInfo.other_details}
                        </span>
                      </Grid>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Typography className="viewtitle">Notes</Typography>
            <span className="details">{enquriyInfo.enquiry_notes || "-"}</span>
          </Grid>
        </div>
      </Card>
    </div>
  );
}
export default ViewEnquiryOtherInfo;
