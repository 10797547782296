//react
import { useEffect, useContext } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { store } from "./ViewProgress";

import { useSelector } from "react-redux";

import {
  Card,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";

import "../scss/view/_viewDiscounts.scss";

function ViewDiscounts({ enquriyInfo }) {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
  }, []);

  return (
    <div id="ViewDiscounts">
      <Card className="discounts-info" variant="outlined">
        <div className="heading">
          <span className="title">Discounts</span>
        </div>
        {/* Discounts */}
        <Grid className="block-inner" container spacing={1} rowGap={2}>
          <Grid item xs={12}>
            <Grid item className="form-block" xs={6}>
              {enquriyInfo.booking_info.discounts.length ? (
                <div>
                  {/* <p className="viewtitle">All Discounts</p> */}
                  <Table
                    sx={{
                      border: "2px solid  #ddd ",
                    }}
                  >
                    <TableHead>
                      <TableRow sx={{ background: "#FBFBFB" }}>
                        <TableCell className="custm-th table-head">
                          Type
                        </TableCell>
                        <TableCell className="custm-th">Price</TableCell>
                      </TableRow>
                    </TableHead>
                    {enquriyInfo.booking_info.discounts.map((discount) => {
                      return (
                        <TableRow>
                          <TableCell>
                            {discount.type ? discount.type : "-"}
                          </TableCell>
                          <TableCell>
                            {discount.discount_price
                              ? "₹ " +
                              discount.discount_price
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                              : "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </Table>
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Total Discounts</Typography>
            <span className="details">
              {enquriyInfo.booking_info.total_discounts
                ? "₹ " +
                enquriyInfo.booking_info.total_discounts
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Price After Discounts</Typography>
            <span className="details">
              {enquriyInfo.booking_info.price_after_discounts
                ? "₹ " +
                enquriyInfo.booking_info.price_after_discounts
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Less: By Party Regn.</Typography>
            <span className="details">
              {enquriyInfo.booking_info.less_by_party_reg
                ? "₹ " +
                enquriyInfo.booking_info.less_by_party_reg
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">
              Less: By Party Insurance
            </Typography>
            <span className="details">
              {enquriyInfo.booking_info.less_by_party_insurance
                ? "₹ " +
                enquriyInfo.booking_info.less_by_party_insurance
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default ViewDiscounts;
