//react
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//importing Redux Store
import { useSelector } from "react-redux";

//importing MaterialUI
import {
  Card,
  FormControl,
  TextField,
  Typography,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  FormControlLabel,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import "../scss/add/AddEnquiryCommunicationAddress.scss";

function AddEnquiryCommunicationAddress(props) {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // local state

  // communication Address States
  const [address_line1, setAddressLine1] = useState("");
  const [address_line2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");

  const [registrationVisible, setRegistrationVisible] = useState(false);
  const [registration, setRegistration] = useState("");

  //Registration Addrees States
  const [regAdd_line_1, setRegAdd_line_1] = useState("");
  const [regAdd_line_2, setRegAdd_line_2] = useState("");
  const [regAdd_city, setRegAdd_city] = useState("");
  const [regAdd_state, setRegAdd_state] = useState("");
  const [regAdd_pincode, setRegAdd_pincode] = useState("");
  const [regAddressDetails, setRegAddressDetails] = useState({
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    pincode: "",
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // pagination url
  useEffect(() => {
    registration === "communication_registration_same"
      ? setRegistrationVisible(true)
      : setRegistrationVisible(false);

    if (
      !props.enquriyInfo.communication_registration_same &&
      props.enquriyInfo.registration_address
    ) {
      setRegAdd_line_1(props.enquriyInfo.registration_address.address_line_1);
      setRegAdd_line_2(props.enquriyInfo.registration_address.address_line_2);
      setRegAdd_city(props.enquriyInfo.registration_address.city);
      setRegAdd_state(props.enquriyInfo.registration_address.state);
      setRegAdd_pincode(props.enquriyInfo.registration_address.pincode);
    }
  }, []);

  //set Registraction Address to null if all the fields are empty
  const settingRegAddressIfEmpty = () => {
    // if (
    //   props.enquriyInfo.registration_address.address_line_1 === "" &&
    //   props.enquriyInfo.registration_address.address_line_2 === "" &&
    //   props.enquriyInfo.registration_address.city === "" &&
    //   props.enquriyInfo.registration_address.state === "" &&
    //   props.enquriyInfo.registration_address.pincode === ""
    // ) {
    //   props.enquriyInfo.registration_address = null;
    // }
  };

  //set the Registration Address if it is same as communication Address
  const setRegistrationCommunicationSame = (condition, id = 0) => {
    let regAddress = {};
    if (condition) {
      regAddress = {
        address_line_1: props.enquriyInfo.communication_address.address_line_1,
        address_line_2: props.enquriyInfo.communication_address.address_line_2,
        city: props.enquriyInfo.communication_address.city,
        state: props.enquriyInfo.communication_address.state,
        pincode: props.enquriyInfo.communication_address.pincode,
      };
      props.enquriyInfo.registration_address = regAddress;
    }

    // if both addresses are not same setting Registration address to null
    if (!condition && id) {
      props.enquriyInfo.registration_address.address_line_1 = regAdd_line_1;
      props.enquriyInfo.registration_address.address_line_2 = regAdd_line_2;
      props.enquriyInfo.registration_address.city = regAdd_city;
      props.enquriyInfo.registration_address.state = regAdd_state;
      props.enquriyInfo.registration_address.pincode = regAdd_pincode;

      settingRegAddressIfEmpty();
    }
  };

  //set Registration Address Line 1
  const setRegistrationAddressLine_1 = (e) => {
    if (!props.enquriyInfo.registration_address) {
      props.enquriyInfo.registration_address = regAddressDetails;
    } else {
      props.enquriyInfo.registration_address.address_line_1 = e.target.value;
    }
    setRegAdd_line_1(e.target.value);
    regAddressDetails["address_line_1"] = e.target.value;
    settingRegAddressIfEmpty();

    props.allValidationErrors["registration_address.address_line_1"] = "";
  };

  //set Registration Address Line 2
  const setRegistrationAddressLine_2 = (e) => {
    if (!props.enquriyInfo.registration_address) {
      props.enquriyInfo.registration_address = regAddressDetails;
    } else {
      props.enquriyInfo.registration_address.address_line_2 = e.target.value;
    }

    setRegAdd_line_2(e.target.value);
    regAddressDetails["address_line_2"] = e.target.value;
    settingRegAddressIfEmpty();
    props.allValidationErrors["registration_address.address_line_2"] = "";
  };

  //set Registration Address City
  const setRegistrationAddressCity = (e) => {
    if (!props.enquriyInfo.registration_address) {
      props.enquriyInfo.registration_address = regAddressDetails;
    } else {
      props.enquriyInfo.registration_address.city = e.target.value;
    }
    setRegAdd_city(e.target.value);
    regAddressDetails["city"] = e.target.value;
    settingRegAddressIfEmpty();
    props.allValidationErrors["registration_address.city"] = "";
  };

  //set Registration Address State
  const setRegistrationAddressState = (e) => {
    if (!props.enquriyInfo.registration_address) {
      props.enquriyInfo.registration_address = regAddressDetails;
    } else {
      props.enquriyInfo.registration_address.state = e.target.value;
    }
    setRegAdd_state(e.target.value);
    regAddressDetails["state"] = e.target.value;
    settingRegAddressIfEmpty();
  };

  //set Registration Address Pincode
  const setRegistrationAddressPincode = (e) => {
    if (!props.enquriyInfo.registration_address) {
      props.enquriyInfo.registration_address = regAddressDetails;
    } else {
      props.enquriyInfo.registration_address.pincode = e.target.value;
    }
    setRegAdd_pincode(e.target.value);
    regAddressDetails["pincode"] = e.target.value;
    settingRegAddressIfEmpty();
    props.allValidationErrors["registration_address.pincode"] = "";
  };

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
  }, []);

  return (
    <div id="addAddress">
      <Card className="address-block" variant="outlined">
        <Grid className="block-inner" container spacing={3}>
          <Grid className="block-item" item xs={12}>
            <Typography className="title">Address Details</Typography>
          </Grid>
          <Grid className="form-block" item xs={12}>
            <Typography>Address For Communication :</Typography>
          </Grid>
          <Grid className="form-block" item xs={6}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">
                Address Line 1
                <strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              <TextField
                className="text-field"
                name="comadd"
                id="outlined-basic"
                variant="standard"
                placeholder="address line 1"
                value={props.enquriyInfo.communication_address.address_line_1}
                // inputProps={{ style: { textTransform: "lowercase" } }}
                onChange={(e) => {
                  setAddressLine1(e.target.value);
                  props.enquriyInfo.communication_address.address_line_1 =
                    e.target.value;
                  setRegistrationCommunicationSame(
                    props.enquriyInfo.communication_registration_same
                  );
                  props.allValidationErrors[
                    "communication_address.address_line_1"
                  ] = "";
                }}
                helpertext={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                    "communication_address.address_line_1"
                    ]
                    : ""
                }
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                    "communication_address.address_line_1"
                    ]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors[
                  "communication_address.address_line_1"
                  ]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={6}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">Address Line 2</Typography>
              <TextField
                className="text-field"
                id="outlined-basic"
                variant="standard"
                placeholder="address line 2"
                value={props.enquriyInfo.communication_address.address_line_2}
                // inputProps={{ style: { textTransform: "lowercase" } }}
                onChange={(e) => {
                  setAddressLine2(e.target.value);
                  props.enquriyInfo.communication_address.address_line_2 =
                    e.target.value;
                  setRegistrationCommunicationSame(
                    props.enquriyInfo.communication_registration_same
                  );
                  props.allValidationErrors[
                    "communication_address.address_line_2"
                  ] = "";
                }}
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors[
                    "communication_address.address_line_2"
                    ]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors[
                  "communication_address.address_line_2"
                  ]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={4}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">
                Town/City<strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              <TextField
                className="text-field"
                name="comcit"
                id="outlined-basic"
                variant="standard"
                value={props.enquriyInfo.communication_address.city}
                // inputProps={{ style: { textTransform: "lowercase" } }}
                onChange={(e) => {
                  setCity(e.target.value);
                  props.enquriyInfo.communication_address.city = e.target.value;
                  setRegistrationCommunicationSame(
                    props.enquriyInfo.communication_registration_same
                  );
                  props.allValidationErrors["communication_address.city"] = "";
                }}
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors["communication_address.city"]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["communication_address.city"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={4}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">
                State<strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              <Select
                className="text-field"
                name="comsta"
                displayempty="true"
                variant="standard"
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={props.enquriyInfo.communication_address.state}
                onChange={(e) => {
                  setState(e.target.value);
                  props.enquriyInfo.communication_address.state =
                    e.target.value;
                  setRegistrationCommunicationSame(
                    props.enquriyInfo.communication_registration_same
                  );
                }}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                error={
                  props.allValidationErrors && state
                    ? ""
                    : props.allValidationErrors["communication_address.state"]
                }
              >
                <MenuItem value={"Andhra Pradesh"}>Andhra Pradesh</MenuItem>
                <MenuItem value={"Telangana"}>Telangana</MenuItem>
                <MenuItem value={"Tamil Nadu"}>Tamil Nadu</MenuItem>
                <MenuItem value={"Kerala"}>Kerala</MenuItem>
                <MenuItem value={"Karnataka"}>Karnataka</MenuItem>
              </Select>
              {state ? (
                ""
              ) : (
                <Typography
                  variant="h6"
                  color="error"
                  style={{ fontSize: "12px", paddingLeft: "15px" }}
                >
                  {props.allValidationErrors
                    ? props.allValidationErrors["communication_address.state"]
                    : ""}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={4}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading">
                PinCode<strong style={{ color: "rgb(228 12 15)" }}>*</strong>
              </Typography>
              <TextField
                className="text-field"
                name="compin"
                id="outlined-basic"
                type="number"
                variant="standard"
                value={props.enquriyInfo.communication_address.pincode}
                onChange={(e) => {
                  setPincode(e.target.value);
                  props.enquriyInfo.communication_address.pincode =
                    e.target.value;
                  setRegistrationCommunicationSame(
                    props.enquriyInfo.communication_registration_same
                  );
                  props.allValidationErrors["communication_address.pincode"] =
                    "";
                  props.allValidationErrors["registration_address.pincode"] =
                    "";


                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 6);
                }}
                helpertext={
                  props.allValidationErrors
                    ? props.allValidationErrors["communication_address.pincode"]
                    : ""
                }
                error={
                  props.allValidationErrors
                    ? props?.allValidationErrors[
                    "communication_address.pincode"
                    ]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["communication_address.pincode"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid className="form-block" item xs={12}>
            <Typography>
              Address For Registration
              <FormControlLabel
                sx={{ display: "contents" }}
                fontSize={12}
                control={
                  <Checkbox
                    checked={props.enquriyInfo.communication_registration_same}
                    onChange={(e) => {
                      setRegistration(e.target.checked);
                      props.enquriyInfo.communication_registration_same =
                        e.target.checked;
                      setRegistrationCommunicationSame(
                        props.enquriyInfo.communication_registration_same,
                        1
                      );
                    }}
                    value={props.enquriyInfo.communication_registration_same}
                  />
                }
                label="(Same as Communication Address)"
              />
            </Typography>
          </Grid>
          {props.enquriyInfo.communication_registration_same ? (
            <Grid item container spacing={2}>
              <Grid className="form-block" item xs={6}>
                <FormControl className="form-inner" variant="standard">
                  <Typography className="heading">Address Line 1</Typography>
                  <TextField
                    className="text-field"
                    id="outlined-basic"
                    placeholder="address line 1"
                    variant="standard"
                    value={
                      props.enquriyInfo.communication_address.address_line_1
                    }
                  // inputProps={{ style: { textTransform: "lowercase" } }}
                  // readonly
                  />
                </FormControl>
              </Grid>
              <Grid className="form-block" item xs={6}>
                <FormControl className="form-inner" variant="standard">
                  <Typography className="heading">Address Line 2</Typography>
                  <TextField
                    className="text-field"
                    id="outlined-basic"
                    variant="standard"
                    placeholder="address line 2"
                    value={
                      props.enquriyInfo.communication_address.address_line_2
                    }
                  // inputProps={{ style: { textTransform: "lowercase" } }}
                  />
                </FormControl>
              </Grid>
              <Grid className="form-block" item xs={4}>
                <FormControl className="form-inner" variant="standard">
                  <Typography className="heading">Town/City</Typography>
                  <TextField
                    className="text-field"
                    id="outlined-basic"
                    variant="standard"
                    value={props.enquriyInfo.communication_address.city}
                  // inputProps={{ style: { textTransform: "lowercase" } }}
                  />
                </FormControl>
              </Grid>
              <Grid className="form-block" item xs={4}>
                <FormControl className="form-inner" variant="standard">
                  <Typography className="heading">State</Typography>
                  <Select
                    className="text-field"
                    displayempty="true"
                    labelid="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={props.enquriyInfo.communication_address.state}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value={props.enquriyInfo.communication_address.state}
                    >
                      {props.enquriyInfo.communication_address.state}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid className="form-block" item xs={4}>
                <FormControl className="form-inner" variant="standard">
                  <Typography className="heading">PinCode</Typography>
                  <TextField
                    className="text-field"
                    type="number"
                    id="outlined-basic"
                    variant="standard"
                    value={props.enquriyInfo.communication_address.pincode}
                    error={
                      props.allValidationErrors
                        ? props.allValidationErrors[
                        "registration_address.pincode"
                        ]
                        : ""
                    }
                  />
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors[
                      "registration_address.pincode"
                      ]
                      : ""}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <Grid item container spacing={2}>
              <Grid className="form-block" item xs={6}>
                <FormControl className="form-inner" variant="standard">
                  <Typography className="heading">Address Line 1</Typography>
                  <TextField
                    className="text-field"
                    id="outlined-basic"
                    name="regddr"
                    placeholder="address line 1"
                    variant="standard"
                    value={
                      props.enquriyInfo.registration_address
                        ? props.enquriyInfo.registration_address.address_line_1
                        : regAdd_line_1
                    }
                    // inputProps={{ style: { textTransform: "lowercase" } }}
                    onChange={setRegistrationAddressLine_1}
                    error={
                      props.allValidationErrors
                        ? props.allValidationErrors[
                        "registration_address.address_line_1"
                        ]
                        : ""
                    }
                  />
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors[
                      "registration_address.address_line_1"
                      ]
                      : ""}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid className="form-block" item xs={6}>
                <FormControl className="form-inner" variant="standard">
                  <Typography className="heading">Address Line 2</Typography>
                  <TextField
                    className="text-field"
                    id="outlined-basic"
                    variant="standard"
                    placeholder="address line 2"
                    value={
                      props.enquriyInfo.registration_address
                        ? props.enquriyInfo.registration_address.address_line_2
                        : regAdd_line_2
                    }
                    // inputProps={{ style: { textTransform: "lowercase" } }}
                    onChange={setRegistrationAddressLine_2}
                    helpertext={
                      props.allValidationErrors
                        ? props.allValidationErrors[
                        "registration_address.address_line_2"
                        ]
                        : ""
                    }
                    error={
                      props.allValidationErrors
                        ? props.allValidationErrors[
                        "registration_address.address_line_2"
                        ]
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item className="form-block" xs={4}>
                <FormControl className="form-inner" variant="standard">
                  <Typography className="heading">Town/City</Typography>
                  <TextField
                    className="text-field"
                    id="outlined-basic"
                    name="regtat"
                    variant="standard"
                    value={
                      props.enquriyInfo.registration_address
                        ? props.enquriyInfo.registration_address.city
                        : regAdd_city
                    }
                    // inputProps={{ style: { textTransform: "lowercase" } }}
                    onChange={setRegistrationAddressCity}
                    error={
                      props.allValidationErrors
                        ? props.allValidationErrors["registration_address.city"]
                        : ""
                    }
                  />
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors["registration_address.city"]
                      : ""}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item className="form-block" xs={4}>
                <FormControl className="form-inner" variant="standard">
                  <Typography className="heading">State</Typography>
                  <Select
                    className="text-field"
                    displayempty="true"
                    // sx={{ width: "26ch" }}
                    name="regtat"
                    labelid="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={
                      props.enquriyInfo.registration_address
                        ? props.enquriyInfo.registration_address.state
                        : regAdd_state
                    }
                    // inputProps={{ style: { textTransform: "lowercase" } }}
                    onChange={setRegistrationAddressState}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    error={
                      props.allValidationErrors && regAddressDetails["state"]
                        ? ""
                        : props.allValidationErrors[
                        "registration_address.state"
                        ]
                    }
                  >
                    <MenuItem value={""}>
                      <em>---Select---</em>
                    </MenuItem>
                    <MenuItem value={"Andhra Pradesh"}>Andhra Pradesh</MenuItem>
                    <MenuItem value={"Telangana"}>Telangana</MenuItem>
                    <MenuItem value={"Tamil Nadu"}>Tamil Nadu</MenuItem>
                    <MenuItem value={"Banglore"}>Banglore</MenuItem>
                    <MenuItem value={"Kerala"}>Kerala</MenuItem>
                    <MenuItem value={"Karnataka"}>Karnataka</MenuItem>
                  </Select>
                  {regAddressDetails["state"] ? (
                    ""
                  ) : (
                    <Typography
                      variant="h6"
                      color="error"
                      style={{ fontSize: "12px", paddingLeft: "15px" }}
                    >
                      {props.allValidationErrors
                        ? props.allValidationErrors[
                        "registration_address.state"
                        ]
                        : ""}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item className="form-block" xs={4}>
                <FormControl className="form-inner" variant="standard">
                  <Typography className="heading">PinCode</Typography>
                  <TextField
                    className="text-field"
                    type="number"
                    id="outlined-basic"
                    variant="standard"
                    name="reginc"
                    // sx={{ width: "26ch" }}
                    value={
                      props.enquriyInfo.registration_address
                        ? props.enquriyInfo.registration_address.pincode
                        : regAdd_pincode
                    }
                    // inputProps={{ style: { textTransform: "lowercase" } }}
                    onChange={setRegistrationAddressPincode}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    error={
                      props.allValidationErrors
                        ? props.allValidationErrors[
                        "registration_address.pincode"
                        ]
                        : ""
                    }
                  />
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ fontSize: "12px", paddingLeft: "15px" }}
                  >
                    {props.allValidationErrors
                      ? props.allValidationErrors[
                      "registration_address.pincode"
                      ]
                      : ""}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
}

export default AddEnquiryCommunicationAddress;
