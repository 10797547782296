// react
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import './enquiries/scss/CarList.scss';

const AddCar = () => {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // local state
  const [category, setCategory] = useState("");
  const [model, setModel] = useState("");
  const [transmiossion, setTransmiossion] = useState("");
  const [colours, setColours] = useState("");
  const [cost, setCost] = useState("");
  const [cresco_price, setCrescoPrice] = useState("");
  const [version, setVersion] = useState("");
  const [fuel_type, setFuelType] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");

  // React Hooks
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    handleSubmit();
  }, []);

  // events
  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/car/create`;
      let options = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
        body: JSON.stringify({
          // hospital_id : parseInt(1),
          category: category,
          model: model,
          transmiossion: transmiossion,
          colours: colours,
          cost: cost,
          cresco_price: cresco_price,
          version: version,
          fuel_type: fuel_type,
          type: type,
        }),
      };
      let result = await fetch(url, options);
      result = await result.json();
      if (result.status === 200 || result.status === 201) {
        navigate("/carlist");
        setMessage("");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div id="addNew-carPage">
      
      <Container maxWidth="md" className="container">
        <h4>Add New Car</h4>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="form-container">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <Typography>Category</Typography>
                    <TextField
                      className="form-control"
                      sx={{ background: "#f6f7f9" }}
                      variant="outlined"
                      size="small"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography>Model</Typography>
                    <TextField
                     className="form-control"
                      sx={{ background: "#f6f7f9" }}
                      variant="outlined"
                      size="small"
                      value={model}
                      onChange={(e) => setModel(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography>Transmiossion</Typography>
                    <TextField
                     className="form-control"
                      sx={{ background: "#f6f7f9" }}
                      variant="outlined"
                      size="small"
                      value={transmiossion}
                      onChange={(e) => setTransmiossion(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className="form-container">
              <Box sx={{ flexGrow: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <Typography>Colours</Typography>
                    <TextField
                     className="form-control"
                      sx={{ background: "#f6f7f9" }}
                      variant="outlined"
                      size="small"
                      value={colours}
                      onChange={(e) => setColours(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography>Cost</Typography>
                    <TextField
                     className="form-control"
                      sx={{ background: "#f6f7f9" }}
                      variant="outlined"
                      size="small"
                      value={cost}
                      onChange={(e) => setCost(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography>Cresco Price</Typography>
                    <TextField
                     className="form-control"
                      sx={{ background: "#f6f7f9" }}
                      variant="outlined"
                      size="small"
                      value={cresco_price}
                      onChange={(e) => setCrescoPrice(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className="form-container">
              <Box sx={{ flexGrow: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <Typography>Version</Typography>
                    <TextField
                     className="form-control"
                      sx={{ background: "#f6f7f9" }}
                      variant="outlined"
                      size="small"
                      value={version}
                      onChange={(e) => setVersion(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography>Fuel Type</Typography>
                    <TextField
                     className="form-control"
                      sx={{ background: "#f6f7f9" }}
                      variant="outlined"
                      size="small"
                      value={fuel_type}
                      onChange={(e) => setFuelType(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography>Type</Typography>
                    <TextField
                     className="form-control"
                      sx={{ background: "#f6f7f9" }}
                      variant="outlined"
                      size="small"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className="button-box">
              <Button className="submit-btn" type="submit" variant="contained">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default AddCar;
