
import { setSignInFailed, setSignInSuccess, setSignInValidation } from '../../src/authSlices/signInSlice';
import { useDispatch } from 'react-redux'

export const useSignInRequest = () => {
    const dispatch = useDispatch()

    return async (email, password) => {

        let res = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/users/signin`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accepts: "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            }
        );
        let responseData = await res.json();
        let details = {

        }

        if (res.status === 200 || res.status === 201) {
            await dispatch(setSignInSuccess(responseData.data))
            details.signInSuccess = responseData.data
        }
        else if (res.status === 401) {
            await dispatch(setSignInFailed(responseData.message))
            details.signInFailed = responseData.message

        }
        else if (res.status === 422) {
            await dispatch(setSignInFailed(responseData.message))
            await dispatch(setSignInValidation(responseData.errors))
            details.signInFailed = responseData.message
            details.signInValidation = responseData.errors
        }
        else {
            await dispatch(setSignInFailed(responseData.message))
            details.signInFailed = responseData.message
        }
        return details
    }

}