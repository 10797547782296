//react
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useParams, useLocation } from "react-router-dom";

//material UI
import { Box, Card, FormControl, MenuItem, Select, Typography, Tooltip, IconButton, Zoom } from "@mui/material";
import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";

import AddEnquiryDMS from "./AddEnquiryDMS";
import AddEnquiryPersonal from "./AddEnquiryPersonal";
import AddEnquiryCarInfo from "./AddEnquiryCarInfo";
import AddEnquiryOthers from "./AddEnquiryOthers";
import Progress from "../../Progress";
import AddBookingInfo from "./AddBookingInfo";
import AddBookingDiscounts from "./AddBookingDiscounts";
import AddBookingAccessories from "./AddBookingAccessories";
import MyButton from "./MyButton";
import AddEnquiryCommunicationAddress from "./AddEnquiryCommunicationAddress";
import moment from "moment";
import { Grid } from "semantic-ui-react";
import { setStatusValidations } from "../../../storeSlices/equirySlice";
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AddCustomerCareInfo from "./AddCustomerCareInfo";
import AddFinanceInfo from "./AddFinanceInfo";
import RetailInfo from "./Retail/RetailInfo";
// import ViewEnquiryUpload from "../View/ViewEnquiryUpload";


function EditProgress() {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);
  const statusValidationErrors = useSelector((state) => state.equiry.statusValidations)
  const ChangedStatusName = useSelector((state) => state.equiry.setStatusValue)

  console.log(statusValidationErrors)
  const Swal = require("sweetalert2");

  // route methods
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // local state
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramStatusName = searchParams.get('status');
  const paramFormName = searchParams.get('from');

  const [BookingStatus, setBookingStatus] = useState(0);
  const [message, setMessage] = useState("Something went wrong !");
  const [loading, setLoading] = useState(false);
  const [showFailedAlert, setShowFailedAlert] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [allValidationErrors, setAllValidationErrors] = useState("");
  const [statusInfo, setStatusInfo] = useState();
  const [statusBasedValidations, setStatusBasedValidations] = useState(statusValidationErrors)
  const [statusName, setStatusName] = useState()

  console.log(paramStatusName);
  //event for logout
  const handleForbidenError = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });

    navigate("/");
  };
  console.log(params)
  //creating object
  const [enquriyInfo, setEnquriyInfo] = React.useState({
    dms_number: "",
    enquiry_date: "",
    sales_representative_id: "",
    occupation: "",
    enquiry_through: "",
    other_enquiry_through: "",
    enquiry_notes: "",
    personal_information: {
      first_name: "",
      last_name: "",
      address_line_1: "",
      address_line_2: "",
      town: "",
      state: "",
      state1: "",
      pincode: "",
      phone_number1: "",
      phone_number2: "",
      phone_number3: "",
      fax: "",
      email_id: "",
      occupation: "",
      date_of_birth: "",
    },
    car_information: {
      model: "",
      version: "",
      color: "",
      year: null,
      transmission: "",
      body_type: "",
      fuel_type: "",
      ex_showroom_price: null,
      individual_registration_price: null,
      corporate_registration_price: 0,
      insurance: null,
      other_charges: null,
      on_road_price: null,
    },
    communication_address: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      pincode: "",
    },
    communication_registration_same: null,
    registration_address: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      pincode: "",
    },
    corporation_details: {
      company_name: "",
      contact_person: "",
      contact_number: "",
      designation: "",
    },
    booking_info: {
      booking_date: null,
      expected_date_of_registration: null,
      expected_date_of_delivery: null,
      other_vehicles_own: null,
      exchange: null,
      exchange_type: null,
      e_w: null,
      e_w_amount: null,
      auto_card: null,
      auto_card_amount: null,
      vehicle_status: null,
      // in_house_finance_company: null,
      b_a: null,
      special_no: null,
      affidavit: null,
      // hypothecation: null,
      // normal_pricing: {
      //   ex_showroom: null,
      //   insurance: null,
      //   reg_charges_incl_of_all_taxes: null,
      // },
      // on_road_price: null,
      discounts: [],
      total_discounts: 0,
      price_after_discounts: null,
      less_by_party_reg: null,
      less_by_party_insurance: null,
      net_price_to_collect: null,
      extra_accessories_to_be_put_free_of_cost: {
        title: null,
        price: null,
      },
      booking_amount: null,
    },
    retail_info: {
      insurance: {
        by_us: null,
        by_party: null,
        by_financier: null,
        insurance_co: '',
        cover_note_no: '',
        cover_note_date: ''
      },
      registration: {
        by_us: null,
        by_party: null,
        temporary: null,
        permanent: null
      },
      extra_accessories: []
    },
    customer_feedback_info: {
      service_follow_up: "",
      customer_call_notes: ""
    },
    finance_info: {
      financier_status: null,
      direct_payment_status: null,
      financier: {
        financier_name: "",
        finance_amount: "",
        finance_expected_date: ""
      },
      direct_payment: {
        amount: null,
        expected_date: ""
      },
      d_s_a: "",
      contact_person: "",
      contact_number_o: "",
      contact_number_c: ""
    },
    status: "BOOKING"
  });

  // pagination url
  useEffect(() => {
    { paramFormName == "view" && handleOkClick(statusValidationErrors); }
    if (searchParams.get("tab") == "BOOKING") {
      getEnquriyDetails(true);
    } else {
      getEnquriyDetails();
    }

  }, []);

  //event for logout
  const handleOnForbidden = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });

    navigate("/");
  };

  // fetching the enquiry details from API
  let getEnquriyDetails = async (check = false) => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/enquiries/${params.id}`;
      let options = {
        method: "GET",
        headers: new Headers({
          "content-type": "application-json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let response = await fetch(url, options);
      console.log(response);
      let getData = await response.json();
      console.log(getData);
      if (response.status == 200 || response.status == 201) {
        setStatusInfo(getData.data.status);

        let data = getData.data?.data;
        data.sales_representative_id = data.sales_representative_id._id;
        data.enquiry_date = new Date(
          moment(new Date(data.enquiry_date)).format("YYYY-MM-DD")
        ).toISOString();

        data.actual_status = data.status;
        data.car_information.year = +data.car_information.year
        if (check) {
          data.status = "BOOKING";
          setBookingStatus(1);
        } else {
          if (data.status == "BOOKING") {
            setBookingStatus(1);
          } else {
            setBookingStatus(0);
          }
        }
        setEnquriyInfo({ ...enquriyInfo, ...data });
        setLoading(false);
      } else if (response.status == 422) {
        setMessage(response.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // On Submit Data will be updated/Validated
  let handleSubmit = async () => {

    dispatch(setStatusValidations([]))
    try {
      setLoading(true);
      let submitUrl = `${process.env.REACT_APP_API_BASE_URL}/enquiries`;
      let submitOptions = {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
        body: JSON.stringify({
          // personal_information: personal_information,
          ...enquriyInfo,
          id: params.id,
          status: paramStatusName ? ChangedStatusName : enquriyInfo.status
        }),
      };
      let response = await fetch(submitUrl, submitOptions);
      let responseData = await response.json();
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        setTimeout(() => {
          handleOnSuccess();
          navigate(`/enquiries/${params.id}`);
        }, 1500);
      } else if (response.status == 422) {
        setValidationErrors(responseData.message);
        let allError = responseData.errors;
        console.log(allError);
        setAllValidationErrors(allError);
        handleOkClick(allError);

        if (!allError || !allError.length) {
          setMessage(responseData.message || "Something went wrong !");
          setShowFailedAlert(true);
          setTimeout(() => {
            setShowFailedAlert(false);
          }, 4000);
        }
      } else if (response.status == 403) {
        handleOnForbidden();
      } else {
        alert(responseData.message);
        setMessage(responseData.message || "Something went wrong !");
        setShowFailedAlert(true);
        setTimeout(() => {
          setShowFailedAlert(false);
        }, 4000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  // pop up on success
  const handleOnSuccess = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: `${enquriyInfo.status[0] + enquriyInfo.status.toLowerCase().slice(1)
        } updated succesfully`,
      showConfirmButton: false,
      timer: 2000,
    });
  };

  // adding to error list
  const addToErrorList = (object) => {
    let option = "";
    let optionItems;

    object.map(
      (item, index) =>
        (option += `<li value="${item}" style="text-align:left">${item}</li>`)
    );

    optionItems = `<ol style="height:200px; width:100%;overflow:hidden; overflow-y:scroll">${option}</ol>`;
    return optionItems;
  };

  // handle on Click OK in Popup
  const handleOkClick = (all) => {
    Swal.fire({
      icon: "error",
      title: "Please clear the required fields!",
      html: addToErrorList(Object.values(all).filter((e) => !!e)),
      customClass: "swal-wide",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          autoScrolltoFirstError(all);
        }, 1000);
      }
    });
  };

  // autoscroll to the first error function
  const autoScrolltoFirstError = (error) => {
    console.log(error)

    let allErrorKeys = Object.keys(error);
    const fromIndex = allErrorKeys.indexOf("enquiry_through");
    const toIndex = allErrorKeys.length;
    const element = allErrorKeys.splice(fromIndex, 1)[0];
    allErrorKeys.splice(toIndex, 0, element);

    const fromIndex1 = allErrorKeys.indexOf("occupation");
    const toIndex1 = allErrorKeys.length;
    const element1 = allErrorKeys.splice(fromIndex1, 1)[0];
    allErrorKeys.splice(toIndex1, 0, element1);

    let allErrorKeyNames = [];

    allErrorKeys &&
      allErrorKeys.map((item, index) => {
        allErrorKeyNames.push(item.slice(0, 3) + item.slice(22, 25));
      });

    console.log(allErrorKeyNames)
    if (allErrorKeyNames.length) {
      const input = document.querySelector(
        `input[name=${allErrorKeyNames[0]}]`
      );
      input.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
    console.log(allErrorKeyNames)
  };


  //event for Status Change
  // const handleStatusChange = async (e) => {
  //   console.log(enquriyInfo);
  //   console.log(e.target.value);
  //   setStatusName(e.target.value);

  // setLoading(true);
  // try {
  //   let enquriyInfoModifiedData = enquriyInfo;
  //   enquriyInfoModifiedData.id = enquriyInfoModifiedData._id;
  //   delete enquriyInfoModifiedData._id;
  //   enquriyInfoModifiedData.sales_representative_id = enquriyInfoModifiedData.sales_representative_id._id;
  //   enquriyInfoModifiedData.car_information.year = +enquriyInfoModifiedData.car_information.year;
  //   let data = {
  //     ...enquriyInfoModifiedData,
  //     status: e.target.value,
  //   }
  //   console.log(data);
  //   // let docUrl = `${process.env.REACT_APP_API_BASE_URL}/enquiries/update-status/${params.id}`;
  //   let docUrl = `${process.env.REACT_APP_API_BASE_URL}/enquiries`;

  //   let docOptions = {
  //     method: "PUT",
  //     headers: new Headers({
  //       "Content-Type": "application/json",
  //       authorization: `${AuthStateData.signInSuccess.access_token}`,
  //     }),
  //     body: JSON.stringify(data),
  //   };
  //   let response = await fetch(docUrl, docOptions);
  //   console.log(response)
  //   let details = await response.json();
  //   console.log(details)

  //   if (response.status == 200 || response.status == 201) {
  //     setLoading(false);
  //     Swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: `Your status changed to ${e.target.value[0] + e.target.value.toLowerCase().slice(1)
  //         }`,
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 1000);
  //   } else if (details.status == 422) {
  //     dispatch(setStatusValidations(details?.errors))
  //     Swal.fire({
  //       icon: "error",
  //       title: "Validation failed",
  //       text: "Status can't be Change!",
  //       showClass: {
  //         popup: "animate__animated animate__fadeInDown",
  //       },
  //       hideClass: {
  //         popup: "animate__animated animate__fadeOutUp",
  //       },
  //     }).then(() => {
  //       navigate(`/enquiries/${enquriyInfo.id}/edit?from=view `);
  //     });;
  //   } else if (details.status == 403) {
  //     handleForbidenError();
  //   } else {
  //     setMessage(details.message || "Something went wrong !");
  //     Swal.fire({
  //       title: details.error,
  //       icon: "info",
  //     });
  //   }
  // } catch (err) {
  //   console.error(err);
  // } finally {
  //   setLoading(false);
  // }
  // };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Grid container xs={12}>
        <div style={{ display: "inline-flex", gap: "20px" }}>
          <Grid item xs={8}>
            {!loading ? (
              <div>
                <form style={{ display: "inline-grid", gap: "20px" }}>
                  <AddEnquiryDMS
                    enquriyInfo={enquriyInfo}
                    allValidationErrors={allValidationErrors ? allValidationErrors : statusBasedValidations}
                  ></AddEnquiryDMS>
                  <AddEnquiryPersonal
                    enquriyInfo={enquriyInfo}
                    allValidationErrors={allValidationErrors ? allValidationErrors : statusBasedValidations}
                  />
                  <AddEnquiryCommunicationAddress
                    enquriyInfo={enquriyInfo}
                    allValidationErrors={allValidationErrors ? allValidationErrors : statusBasedValidations}
                  />
                  <AddEnquiryCarInfo
                    enquriyInfo={enquriyInfo}
                    allValidationErrors={allValidationErrors ? allValidationErrors : statusBasedValidations}
                  />
                  <AddEnquiryOthers
                    enquriyInfo={enquriyInfo}
                    allValidationErrors={allValidationErrors ? allValidationErrors : statusBasedValidations}
                  />
                  <AddBookingInfo
                    enquriyInfo={enquriyInfo}
                    allValidationErrors={allValidationErrors ? allValidationErrors : statusBasedValidations}
                  />
                  <AddBookingDiscounts
                    enquriyInfo={enquriyInfo}
                    allValidationErrors={allValidationErrors ? allValidationErrors : statusBasedValidations}
                  />
                  <AddBookingAccessories
                    enquriyInfo={enquriyInfo}
                    allValidationErrors={allValidationErrors ? allValidationErrors : statusBasedValidations}
                  />
                  {/* <RetailInfo
                    enquriyInfo={enquriyInfo}
                    allValidationErrors={allValidationErrors}
                  />


                  <AddCustomerCareInfo
                    enquriyInfo={enquriyInfo}
                    allValidationErrors={allValidationErrors ? allValidationErrors : statusBasedValidations}
                  />
                  <AddFinanceInfo
                    enquriyInfo={enquriyInfo}
                    allValidationErrors={allValidationErrors ? allValidationErrors : statusBasedValidations}
                  /> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "2em",
                    }}
                  >

                    <div style={{
                      position: "fixed",
                      bottom: "20px",
                      right: "20px",
                      zindex: "9999",
                      display: "flex",
                      gap: "12px"
                    }}>
                      <Tooltip title="Clear" arrow placement="top" TransitionComponent={Zoom}>
                        <IconButton aria-label="delete" size="large" onClick={handleBack} style={{ backgroundColor: "#224466", color: "#ffffff" }}>
                          <ArrowBackIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Save Enquiry" arrow placement="top" TransitionComponent={Zoom}>
                        <IconButton aria-label="delete" size="large" onClick={handleSubmit} style={{ backgroundColor: "#00c700", color: "#ffffff" }}>
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </form >
              </div >
            ) : (
              ""
            )
            }
          </Grid >

          <Grid item xs={4}>
            <div
              style={{
                position: "sticky",
                right: "0px",
                top: "120px",
                zIndex: "999",
              }}
            >
              {/* <Card sx={{ padding: "12px" }}>
                <Typography className="title">Status :</Typography>
                <FormControl sx={{ width: "120px", paddingLeft: "10px" }}>
                  <Select
                    labelid="demo-simple-select-label"
                    id="demo-simple-select"
                    value={statusName ? statusName : paramStatusName}
                    defaultValue={statusName ? statusName : paramStatusName}
                    selected={true}
                    onChange={handleStatusChange}
                    sx={{ border: "1px solid", color: "black" }}
                  >
                    <MenuItem sx={{ fontSize: "12px" }} value="ENQUIRY">
                      ENQUIRY
                    </MenuItem>
                    <MenuItem sx={{ fontSize: "12px" }} value="BOOKING">
                      BOOKING
                    </MenuItem>
                  </Select>
                </FormControl>
              </Card> */}
              {!loading ? <Progress
                equeryData={enquriyInfo ? enquriyInfo : paramStatusName}
                orientation="vertical"
              ></Progress> : ""}
            </div>
          </Grid>
        </div >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid >
    </Box >
  );
}

export default EditProgress;
