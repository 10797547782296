import React, { PureComponent } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const LeadsGraph = () => {
    const [leadsDetails, setLeadsGraphData] = useState()
    const [loading, setLoading] = useState(false);
    const AuthStateData = useSelector((state) => state.signIn);


    useEffect(() => {
        // getLeadsGraphData()
    }, [])


    //api call for get statisticsData
    const getLeadsGraphData = async () => {
        setLoading(true);
        try {
            const url = `${process.env.REACT_APP_API_BASE_URL}/`;
            const options = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`
                }),
            };
            const response = await fetch(url, options);
            const responseData = await response.json();
            if (responseData.success) {
                setLeadsGraphData(responseData.data);
            } else {
                throw responseData;
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const data = [
        {
            name: 'Enq',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Booking',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Cusc',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Finance',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Retail',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Del',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Cust care',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    return (
        <>
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                barSize={20}
            >
                <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                <YAxis />
                <Tooltip />
                <Legend />
                <CartesianGrid strokeDasharray="3 3" />
                <Bar dataKey="pv" fill="#8884d9" background={{ fill: '#eee' }} c />
            </BarChart>
        </>
    )
}
export default LeadsGraph