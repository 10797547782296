//react
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { Box, CircularProgress } from "@mui/material";
import './enquiries/scss/view/_viewDMS.scss';


import ViewProgress from "./enquiries/View/ViewProgress";

function ViewEnquiry(enquriyInfo) {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();
  // const { enquiryId } = location.state;

  // local state
  const [salesrepresentative, setSalesRepresentative] = useState([]);
  const [message, setMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    getSalesData();
  }, []);

  // get all setSalesRepresentative data

  const getSalesData = async () => {
    setShowLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/sales-representatives?page=1&take=10`;
      let options = {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };

      let getsalesdata = await fetch(url, options);

      getsalesdata = await getsalesdata
        .json()

        // .then((result) => result.json())
        .then((getsalesdata) => {
          console.log(getsalesdata.data);
          setSalesRepresentative(getsalesdata.data);
        });
      setShowLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      id="viewEnquiry"
      
    >
      <Box style={{ display: "block" }}>
        <div style={{ display: "inline-grid" }}>
          {/* <Progress /> */}
          <ViewProgress></ViewProgress>
        </div>
        <div className="message">{message ? <p>{message}</p> : null}</div>
        {showLoading === true ? (
          <p className="custm-loader">
            <CircularProgress />
          </p>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
}
export default ViewEnquiry;
