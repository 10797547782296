//react
import { useEffect, useContext } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { store } from "./ViewProgress";
import { Card, Grid, Typography } from "@mui/material";

import "../scss/view/_viewAccessories.scss";

function ViewAccessories({ enquriyInfo }) {

  console.log(enquriyInfo);

  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
  }, []);

  return (
    <div id="viewAccessories">
      <Card className="accessories" variant="outlined">
        <Grid className="block-inner" container spacing={1}>
          <Grid className="block-item" item xs={12}>
            <Typography className="title">Accessories</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Extra Accessories</Typography>
            <span className="details">
              {enquriyInfo.booking_info.extra_accessories_to_be_put_free_of_cost
                .title
                ? enquriyInfo.booking_info
                  .extra_accessories_to_be_put_free_of_cost.title
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Free Cost Amount</Typography>
            <span className="details">
              {enquriyInfo.booking_info.extra_accessories_to_be_put_free_of_cost
                .price
                ? "₹ " +
                enquriyInfo.booking_info.extra_accessories_to_be_put_free_of_cost.price
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
        </Grid>
      </Card>
      <Card className="accessories" variant="outlined">
        <Grid className="block-inner" container spacing={1}>
          <Grid item xs={4}>
            <Typography className="viewtitle">Net Price</Typography>
            <span className="details">
              {enquriyInfo.booking_info.net_price_to_collect
                ? "₹ " +
                enquriyInfo.booking_info.net_price_to_collect
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Booking Amount</Typography>
            <span className="details">
              {enquriyInfo.booking_info.booking_amount
                ? "₹ " +
                enquriyInfo.booking_info.booking_amount
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Balance Amount</Typography>
            <span className="details">
              {enquriyInfo.booking_info.net_price_to_collect
                ? "₹ " +
                (
                  enquriyInfo.booking_info.net_price_to_collect -
                  (enquriyInfo.booking_info.booking_amount || 0)
                )
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default ViewAccessories;
