import React from "react";
import ReactDOM from "react-dom/client";
import { useSelector } from "react-redux";

// External SCSS files
import "../src/app.scss";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import SignIn from "./Auth/SignIn.js";

import Enquiries from "./components/Enquiries";

import Deliveries from "./components/Deliveries";
import BaseLayout from "./layouts/BaseLayout";

import NotFound from "./components/NotFound.js";
import SalesRepresentative from "./components/SalesRepresentative";
import SalesRepresentativeEdit from "./components/SalesRepresentativeEdit";
import Sales from "./components/Sales";
import AddEnquiry from "./components/AddEnquiry";
import ViewEnquiry from "./components/ViewEnquiry";
import EditEnquiry from "./components/EditEnquiry.js";
import CarList from "./components/CarList";
import AddCar from "./components/AddCar";
import UserProfile from "./components/UserProfile.js";
import EditProfile from "./components/EditProfile.js";
import UpdatePassword from "./components/UpdatePassword.js";

import persistStore from "redux-persist/es/persistStore";
import Dashboard from "./components/dashboard/Dashboard";
// import SalesAnalysis from "./components/SalesAnalysis";
// import AnalysisDetails from "./components/AnalysisDetails.js";
// import CarSales from "./components/CarSales.js";
// import AnalysisPerformance from "./components/AnalysisPerformance.js";

let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<SignIn />} />
          <Route
            path="/"
            element={
              <RequireAuth redirectTo="/">
                <BaseLayout />
              </RequireAuth>
            }
          >
            {/* User Routes */}

            <Route path="/profile" element={<UserProfile />} />
            <Route path="/profile/edit/:id" element={<EditProfile />} />
            <Route path="/update-password" element={<UpdatePassword />} />

            {/* Enquiry Routes */}

            <Route path="/enquiries" element={<Enquiries />} />
            <Route>
              <Route path="/enquiries/add" element={<AddEnquiry />} />
              <Route path="/enquiries/:id" element={<ViewEnquiry />} />
              <Route>
                <Route path="/enquiries/:id/edit" element={<EditEnquiry />} />
              </Route>
            </Route>

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/deliveries" element={<Deliveries />} />

            {/* sales representative Routes */}

            <Route
              path="/sales-representative"
              element={<SalesRepresentative />}
            />
            <Route
              path="/sales-edit/:id"
              element={<SalesRepresentativeEdit />}
            />

            {/* sales Routes */}

            <Route path="/sales" element={<Sales />} />

            {/* <Route>
              <Route path="/analysis" element={<SalesAnalysis />} />
              <Route>
                <Route path="/analysis-details" element={<AnalysisDetails />} />
                <Route path="/car-sales" element={<CarSales />} />
                <Route
                  path="/analysis-performance"
                  element={<AnalysisPerformance />}
                />
              </Route>
            </Route> */}

            {/* car Routes */}

            <Route path="/cars" element={<CarList />} />
            <Route path="/addcar" element={<AddCar />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

function RequireAuth({ children, redirectTo }) {
  const AuthStateData = useSelector((state) => state.signIn);
  let isAuthenticated = AuthStateData.signInSuccess ? true : false;
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
