//react
import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { store } from "./ViewProgress";
import { Card, Grid, Typography } from "@mui/material";

import "../scss/view/_viewCarInfo.scss";

function ViewEnquiryCarInfo({ enquriyInfo }) {

  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // pagination url
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
  }, []);

  return (
    <div id="viewCar">
      <Card className="view-car-info carlist" variant="outlined">
        <div className="heading">
          <span className="title">Car Information</span>
        </div>
        <Grid container spacing={2} rowGap={2}>
          <Grid item xs={4}>
            <Typography className="viewtitle">Model</Typography>
            <span className="details">{enquriyInfo.car_information?.model}</span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Version</Typography>
            <span className="details" style={{ textTransform: "capitalize" }}>
              {enquriyInfo.car_information.version}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Body Type</Typography>
            <span className="details">
              {enquriyInfo.car_information.body_type}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Year</Typography>
            <span className="details">{enquriyInfo.car_information.year}</span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Transmission</Typography>
            <span className="details" style={{ textTransform: "capitalize" }}>
              {enquriyInfo.car_information.transmission
                ? enquriyInfo.car_information.transmission.toLowerCase()
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Fuel Type</Typography>
            <span className="details" style={{ textTransform: "capitalize" }}>
              {enquriyInfo.car_information.fuel_type
                ? enquriyInfo.car_information.fuel_type.toLowerCase()
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Colors</Typography>
            <span className="details" style={{ textTransform: "capitalize" }}>
              {enquriyInfo.car_information.color}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Ex Show Room Price</Typography>
            <span className="details">
              {enquriyInfo.car_information.ex_showroom_price
                ? "₹ " +
                enquriyInfo.car_information.ex_showroom_price
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Registration Price</Typography>
            <span className="details">
              {enquriyInfo.car_information.individual_registration_price
                ? "₹ " +
                enquriyInfo.car_information.individual_registration_price
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Insurance</Typography>
            <span className="details">
              {enquriyInfo.car_information.insurance
                ? "₹ " +
                enquriyInfo.car_information.insurance
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">Other Charges</Typography>
            <span className="details">
              {enquriyInfo.car_information.other_charges
                ? "₹ " +
                enquriyInfo.car_information.other_charges
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="viewtitle">On Road Price</Typography>
            <span className="details">
              {enquriyInfo.car_information.on_road_price
                ? "₹ " +
                enquriyInfo.car_information.on_road_price
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                : "-"}
            </span>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default ViewEnquiryCarInfo;
