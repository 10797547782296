import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AddProgress from "./enquiries/Add/AddProgress";
// import "../scss/AddEnquiry.scss";

function AddEnquiry() {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  // model and version variable
  const [getmodeldata, setModelData] = useState([]);

  //const [rows, setRowData] = useState([])
  const [salesrepresentative, setSalesRepresentative] = useState([]);
  useEffect(() => { });

  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
  }, []);

  // pagination url
  useEffect(() => {
    getSalesData();
  }, []);

  // get all setSalesRepresentative data
  const getSalesData = async () => {
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/sales-representatives?page=1&take=10`;
      let options = {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let getsalesdata = await fetch(url, options);
      getsalesdata = await getsalesdata.json();
      setSalesRepresentative(getsalesdata.data);
    } catch (err) {
      console.error(err);
    }
  };

  // pagination url
  useEffect(() => {
    getModelData();
  }, []);

  // get car model data
  const getModelData = async () => {
    try {
      let modelDataUrl = `${process.env.REACT_APP_API_BASE_URL}/cars/models`;
      let modelDataOptions = {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let getmodeldata = await fetch(modelDataUrl, modelDataOptions);
      getmodeldata = await getmodeldata.json();
      setModelData(getmodeldata.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      id="addEnquiry"
      style={{
        padding: "0.75em 1.25em",
        background: "#F2F2F2",
      }}
    >
      {/* <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/enquiries">
            <Typography style={{ color: "#6534C4", cursor: "pointer" }}>
              <HomeIcon fontSize="small" />
              All Enquiries
            </Typography>
          </Link>
        </Breadcrumbs>
      </div> */}
      <Box style={{ display: "contents" }}>
        <div>
          <AddProgress></AddProgress>
        </div>
      </Box>
    </div>
  );
}

export default AddEnquiry;
