import { Avatar, Badge, Button, Card, IconButton, LinearProgress, Table, TableCell, TableHead, Typography } from '@mui/material';
import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import CircleIcon from '@mui/icons-material/Circle';
import { TableBody, TableRow } from 'semantic-ui-react';
import { RemoveRedEye } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import isAfter from 'date-fns/isAfter';
import { getDateWithYearForApi } from '../pipes/timePipe';

const SalesRespEnquiries = () => {


    const [salesRespEnquiriesdata, setSalesRespEnquiriesData] = useState([])
    const [loading, setLoading] = useState(false);
    const AuthStateData = useSelector((state) => state.signIn);
    const [defaultFromDate, setDefaultFromDate] = useState(getDateWithYearForApi(new Date('2023-07-01').toISOString()));
    const [defaultToDate, setDefaultToDate] = useState(getDateWithYearForApi(new Date().toISOString()));
    const [fromDate, setFromDate] = useState();
    const [dataNoFound, setDataNotFound] = useState('');

    console.log(defaultFromDate);

    const navigate = useNavigate();


    //api call for get statisticsData
    const getSalesRespEnquiriesCount = async (fromDate = defaultFromDate, toDate = defaultToDate) => {
        setLoading(true);
        try {
            const url = `${process.env.REACT_APP_API_BASE_URL}/enquiries/salesrep/count?from_date=${fromDate}&to_date=${toDate}`;
            console.log(url);
            const options = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`
                }),
            };
            const response = await fetch(url, options);
            const responseData = await response.json();
            console.log(responseData?.data);
            if (response.status == 200) {
                setSalesRespEnquiriesData(responseData?.data);
            } else if (response.status == 401) {
                setSalesRespEnquiriesData([]);
                setDataNotFound(responseData?.message);
            }
            else {
                throw responseData;
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getSalesRespEnquiriesCount();
    }, []);
    return (


        <Card>
            <div style={{ padding: "20px", height: "50px" }}>
                <div style={{ display: "flex" }}>
                    <Typography variant='h5'>Sales Rep. -Enquiries</Typography>

                    <DateRangePicker
                        format="yyyy-MM-dd"
                        placeholder="Select Date"
                        value={fromDate}
                        defaultValue={[new Date(defaultFromDate), new Date()]}
                        showOneCalendar
                        disabledDate={date => isAfter(date, new Date(defaultToDate))}
                        onChange={(newDate) => {
                            setFromDate(newDate)
                            if (newDate) {
                                let date1 = new Date(moment(new Date(newDate[0])).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                                let date2 = new Date(moment(new Date(newDate[1])).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                                getSalesRespEnquiriesCount(date1, date2)

                            }
                            else {
                                let date1 = new Date(moment(new Date()).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                                let date2 = new Date(moment(new Date()).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                                getSalesRespEnquiriesCount(date1, date2)
                            }

                        }}
                        style={{ width: "200px" }}
                    />
                    <Button onClick={() => navigate('/enquiries')}>View All</Button >
                </div>
            </div>
            <div style={{ height: "300px", overflow: "scroll" }}>
                {loading ? <LinearProgress sx={{ marginTop: "20px" }} /> :
                    <Table >
                        <TableHead>
                            <TableCell></TableCell>
                            <TableCell>Sales Representative</TableCell>
                            <TableCell>Count</TableCell>
                            <TableCell>Action</TableCell>
                        </TableHead>
                        <TableBody>
                            {salesRespEnquiriesdata?.length ? salesRespEnquiriesdata.map((item, index) => {
                                return <TableRow key={index}>
                                    <TableCell> <Avatar src={require("../../img/Profile.png")} alt=""></Avatar></TableCell>
                                    <TableCell>{item.salesRepresentativeName}</TableCell>
                                    <TableCell>{item.count}</TableCell>
                                    <TableCell>
                                        <IconButton>
                                            <RemoveRedEye />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            }) :
                                'No Data'
                            }
                        </TableBody>
                    </Table>
                }
            </div>
        </Card >



    )
}
export default SalesRespEnquiries