import { createSlice } from '@reduxjs/toolkit'


export const signInSlice = createSlice({
    name: 'signIn',
    initialState: {
        signInSuccess: null,
        signInFailed: null,
        signInValidation: null,
        signOut: null,
    },

    reducers: {
        setSignInSuccess: (state, data) => {
            state.signInSuccess = data.payload
        },
        setSignInFailed: (state, data) => {
            state.signInFailed = data.payload
        },
        setSignInValidation: (state, data) => {
            state.signInValidation = data.payload
        },
        setSignOut: (state, data) => {
            state.signOut = data.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setSignInSuccess, setSignInFailed, setSignInValidation, setSignOut } = signInSlice.actions

export default signInSlice.reducer