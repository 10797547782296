//react
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//importing materialUI
import { Box, Card, IconButton, Tooltip, Zoom } from "@mui/material";
import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

//importing local components
import AddEnquiryDMS from "./AddEnquiryDMS";
import AddEnquiryPersonal from "./AddEnquiryPersonal";
import AddEnquiryCarInfo from "./AddEnquiryCarInfo";
import AddEnquiryOthers from "./AddEnquiryOthers";
import Progress from "./../../Progress";
import AddBookingInfo from "./AddBookingInfo";
import AddBookingDiscounts from "./AddBookingDiscounts";
import AddBookingAccessories from "./AddBookingAccessories";
import AddEnquiryCommunicationAddress from "./AddEnquiryCommunicationAddress";
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//importing moment
import moment from "moment";
import { Grid } from "semantic-ui-react";
import RetailInfo from "./Retail/RetailInfo";
import AddCustomerCareInfo from "./AddCustomerCareInfo";
import AddFinanceInfo from "./AddFinanceInfo";

function AddProgress() {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();
  const Swal = require("sweetalert2");
  const dispatch = useDispatch();

  // local state
  const [value, setValue] = React.useState(0);
  const [message, setMessage] = useState("Something went wrong !");
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailedAlert, setShowFailedAlert] = useState(false);
  const [allValidationErrors, setAllValidationErrors] = useState("");
  const [statusInfo, setStatusInfo] = useState();

  //Total enquiryInfo
  const [enquriyInfo, setEnquriyInfo] = React.useState({
    dms_number: "",
    enquiry_date: new Date(
      moment(new Date()).format("YYYY-MM-DD")
    ).toISOString(),
    sales_representative_id: "",
    occupation: "",
    enquiry_through: "",
    other_enquiry_through: "",
    enquiry_notes: "",
    expected_date_of_registration: null,
    expected_date_of_delivery: null,
    personal_information: {
      first_name: "",
      last_name: "",
      phone_number_r: "",
      phone_number_o: "",
      phone_number_c: "",
      fax: "",
      email_id: "",
      occupation: "",
      date_of_birth: "",
    },
    car_information: {
      model: "",
      version: "",
      color: "",
      year: null,
      transmission: "",
      body_type: "",
      fuel_type: "",
      ex_showroom_price: null,
      individual_registration_price: null,
      corporate_registration_price: 0,
      insurance: null,
      other_charges: null,
      on_road_price: null,
    },
    communication_address: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      pincode: "",
    },
    communication_registration_same: false,
    registration_address: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      pincode: "",
    },
    corporation_details: {
      company_name: "",
      contact_person: "",
      contact_number: "",
      designation: "",
    },
    booking_info: {
      booking_date: null,
      expected_date_of_registration: null,
      expected_date_of_delivery: null,
      other_vehicles_own: null,
      exchange: null,
      exchange_type: null,
      e_w: null,
      e_w_amount: null,
      auto_card: null,
      auto_card_amount: null,
      vehicle_status: null,
      // in_house_finance_company: null,
      b_a: null,
      special_no: null,
      affidavit: null,
      // hypothecation: null,
      // normal_pricing: {
      //   ex_showroom: null,
      //   insurance: null,
      //   reg_charges_incl_of_all_taxes: null,
      // },
      // on_road_price: null,
      discounts: [],
      total_discounts: 0,
      price_after_discounts: null,
      less_by_party_reg: null,
      less_by_party_insurance: null,
      net_price_to_collect: null,
      extra_accessories_to_be_put_free_of_cost: {
        title: null,
        price: null,
      },
      booking_amount: null,
    },
    retail_info: {
      insurance: {
        by_us: null,
        by_party: null,
        by_financier: null,
        insurance_co: '',
        cover_note_no: '',
        cover_note_date: ''
      },
      registration: {
        by_us: null,
        by_party: null,
        temporary: null,
        permanent: null
      },
      extra_accessories: []
    },
    customer_feedback_info: {
      service_follow_up: "",
      customer_call_notes: ""
    },
    finance_info: {
      financier_status: null,
      direct_payment_status: null,
      financier: {
        financier_name: "",
        finance_amount: "",
        finance_expected_date: ""
      },
      direct_payment: {
        amount: null,
        expected_date: ""
      },
      d_s_a: "",
      contact_person: "",
      contact_number_o: "",
      contact_number_c: ""
    },
    status: "ENQUIRY",
  });

  const handleForbidenError = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });

    navigate("/");
  };

  // Adding to Error PopUp List
  const addErrortoPopUpList = (object) => {
    let option = "";
    let optionItems;

    object.map(
      (item, index) =>
        (option += `<li value="${item}" style="text-align:left">${item}</li>`)
    );

    optionItems = `<ol style="height:200px; width:100%;overflow:hidden; overflow-y:scroll">${option}</ol>`;
    return optionItems;
  };

  //alert Success Message
  const handleSuccess = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Enquiry added succesfully",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  //autoscroll to the first Error
  const autoScroll = (error) => {
    console.log(error)
    let allErrorKeys = Object.keys(error);
    console.log(allErrorKeys);
    const fromIndex = allErrorKeys.indexOf("enquiry_through");
    const toIndex = allErrorKeys.length;
    const element = allErrorKeys.splice(fromIndex, 1)[0];
    allErrorKeys.splice(toIndex, 0, element);

    const fromIndex1 = allErrorKeys.indexOf("occupation");
    const toIndex1 = allErrorKeys.length;
    const element1 = allErrorKeys.splice(fromIndex1, 1)[0];
    allErrorKeys.splice(toIndex1, 0, element1);

    let allErrorKeyNames = [];
    allErrorKeys &&
      allErrorKeys.map((item, index) => {
        allErrorKeyNames.push(item.slice(0, 3) + item.slice(22, 25));
      });
    console.log(allErrorKeyNames);

    if (allErrorKeyNames.length) {
      const input = document.querySelector(
        `input[name=${allErrorKeyNames[0]}]`
      );

      input.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  // const MyButton = styled(Button)({
  //   background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  //   border: 0,
  //   borderRadius: 3,
  //   boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  //   color: "white",
  //   height: 48,
  //   padding: "0 30px",
  // });

  //function to handle the operation after clicking the OK on the PopUp
  const handleClickOk = (all) => {
    Swal.fire({
      icon: "error",
      title: "Please clear the required fields!",
      html: addErrortoPopUpList(Object.values(all).filter((e) => !!e)),
      customClass: "swal-wide",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          autoScroll(all);
        }, 1000);
      }
    });
  };

  //event
  let handleSubmit = async () => {
    try {
      setLoading(true);
      let url = `${process.env.REACT_APP_API_BASE_URL}/enquiries`;
      let options = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
        body: JSON.stringify({
          ...enquriyInfo,
        }),
      };
      let res = await fetch(url, options);
      let responseData = await res.json();
      setLoading(false);
      //Successful on adding enquiry
      if (res.status === 200 || res.status === 201) {
        setShowSuccessAlert(true);
        handleSuccess();
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate(`/enquiries`);
        }, 2500);
      }
      // handling Validation Errors
      else if (res.status === 422) {
        let allError = responseData.errors;
        setMessage(responseData.message);

        if (allError !== undefined) {
          setAllValidationErrors(allError);
          handleClickOk(allError);
        }

        if (!allError || !allError.length) {
          setMessage(responseData.message || "Something went wrong !");
          if (responseData.message == "DMS number already existed") {
            Swal.fire({
              title: "DMS number already existed!",
              icon: "error",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            });
          }
        }
      }
      //Forbidden Error
      else if (res.status == 403) {
        handleForbidenError();
      }
      //default Error Message
      else {
        setMessage(responseData.message || "Something went wrong !");
        setShowFailedAlert(true);
        setTimeout(() => {
          setShowFailedAlert(false);
        }, 4000);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleBack = () => {
    navigate(`/enquiries`)
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Grid container xs={12}>
        <div style={{ display: "inline-flex", gap: "20px" }}>
          <Grid item xs={8}>
            {!loading ? (
              <form style={{ display: "inline-grid", gap: "20px" }}>
                <AddEnquiryDMS
                  enquriyInfo={enquriyInfo}
                  allValidationErrors={allValidationErrors}
                ></AddEnquiryDMS>
                <AddEnquiryPersonal
                  enquriyInfo={enquriyInfo}
                  allValidationErrors={allValidationErrors}
                />
                <AddEnquiryCommunicationAddress
                  enquriyInfo={enquriyInfo}
                  allValidationErrors={allValidationErrors}
                />
                <AddEnquiryCarInfo
                  enquriyInfo={enquriyInfo}
                  allValidationErrors={allValidationErrors}
                />
                <AddEnquiryOthers
                  enquriyInfo={enquriyInfo}
                  allValidationErrors={allValidationErrors}
                />
                <AddBookingInfo
                  enquriyInfo={enquriyInfo}
                  allValidationErrors={allValidationErrors}
                />
                <AddBookingDiscounts
                  enquriyInfo={enquriyInfo}
                  allValidationErrors={allValidationErrors}
                />
                <AddBookingAccessories
                  enquriyInfo={enquriyInfo}
                  allValidationErrors={allValidationErrors}
                />

                {/* <RetailInfo
                  enquriyInfo={enquriyInfo}
                  allValidationErrors={allValidationErrors}
                />
                <AddCustomerCareInfo
                  enquriyInfo={enquriyInfo}
                  allValidationErrors={allValidationErrors}
                />
                <AddFinanceInfo
                  enquriyInfo={enquriyInfo}
                  allValidationErrors={allValidationErrors}
                /> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "2em",
                  }}
                >
                  <div style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    zindex: "9999",
                    display: "flex",
                    gap: "12px"
                  }}>
                    <Tooltip title="Clear" arrow placement="top" TransitionComponent={Zoom}>
                      <IconButton aria-label="delete" size="large" onClick={handleBack} style={{ backgroundColor: "#224466", color: "#ffffff" }}>
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Save Enquiry" arrow placement="top" TransitionComponent={Zoom}>
                      <IconButton aria-label="delete" size="large" onClick={handleSubmit} style={{ backgroundColor: "#00c700", color: "#ffffff" }}>
                        <SaveIcon />
                      </IconButton>
                    </Tooltip>
                  </div>


                </div>
              </form>
            ) : (
              ""
            )}
          </Grid>

          <Snackbar
            open={showFailedAlert}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="error">
              <AlertTitle>Failed</AlertTitle>
              {message}
            </Alert>
          </Snackbar>

          <Grid item xs={4} style={{ marginTop: "30px" }}>
            <div
              style={{
                position: "sticky",
                right: "0px",
                top: "120px",
                zIndex: "999",
              }}
            >
              {!loading ? <Progress
                equeryData={enquriyInfo}
                orientation="vertical"
              ></Progress> : ""}
            </div>
          </Grid>
        </div>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </Box>
  );
}

export default AddProgress;
