import { useDispatch, useSelector } from "react-redux";
import { setSalesProductsList } from '../storeSlices/salesProductsSlice'

export const useGetSales = () => {
  const dispatch = useDispatch();
  const signInSliceData = useSelector((state) => state.signIn);
  return async (controller) => {
    let result = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/enquiries/count/status?page=${controller.page + 1}&take=${controller.rowsPerPage}`,
      {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${signInSliceData.signInSuccess.userDetails.refresh_token}`
        }),
      }
    );

    let getcarsdata = await result.json();

    await dispatch(setSalesProductsList(getcarsdata))

  };
};
