//react
import React, { useState, useEffect } from "react";
import {
  useNavigate,
  Link,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  Table,
  TableHead,
  TableBody,
  IconButton,
  TableRow,
  TableCell,
  CircularProgress,
  Container,
  TablePagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { useGetSalesRepresentative } from "../services/salesRepresentativeService";

import "./SalesRepresentative.css";
import { setStatusValidations } from "../storeSlices/equirySlice";

const SalesRepresentative = () => {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);
  const stateData = useSelector((state) => state.sales);
  const dispatch = useDispatch()

  // service methods
  const getSalesRepresentativeRequest = useGetSalesRepresentative();

  //route methods
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const page = new URLSearchParams(search).get("page");
  const take = new URLSearchParams(search).get("take");
  const navigate = useNavigate();

  // local state
  const [showLoading, setShowLoading] = useState(false);
  const [rows, setRowData] = useState([]);
  const [saleslistCount, setSalesListCount] = useState(0);
  const [controller, setController] = useState({
    page: page || 0,
    rowsPerPage: take || 10,
  });


  // React Hooks
  useEffect(() => {
    dispatch(setStatusValidations([]))
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    getData();
  }, [controller]);

  // get all setSalesRepresentative data

  const getData = async () => {
    setShowLoading(true);
    let results = await getSalesRepresentativeRequest(controller);
    setRowData(results.sales_list.data);
    setSalesListCount(results.sales_list.total);
    setShowLoading(false);
  };

  // pagination change page , no change take
  const handlePageChange = (event, newPage) => {
    // setSearchParams({ page: newPage, take: controller.rowsPerPage });
    setController({
      ...controller,
      page: newPage,
    });
    getData();
  };
  // pagination no change page , change change take
  const handleChangeRowsPerPage = (event) => {
    setSearchParams({ page: 0, take: event.target.value });
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
    getData();
  };

  // delete row single data

  const handleDelete = async (carlst) => {
    console.log("delete data");
    try {
      let url = `${process.env_REACT_APP_API_BASE_URL}/sales-representatives/${carlst._id}`;
      let options = {
        method: "delete",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
        body: JSON.stringify({
          // name: name,
        }),
      };
      let result = await fetch(url, options);
      result = await result
        .json()
        .then((result) => result.json())
        .then((data) => {
          console.log(data);
          getData();
        });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div id="salesRepresentative">
     
        <div className="custm-table-main">
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="custm-th">Name</TableCell>
                  <TableCell className="custm-th">User Name</TableCell>
                  <TableCell className="custm-th">Email</TableCell>
                  <TableCell className="custm-th">Phone Number</TableCell>
                  <TableCell className="custm-th">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows && rows.map((carlst, index) => (
                  <TableRow key={index}>
                    <TableCell>{carlst.name}</TableCell>
                    <TableCell>{carlst.username}</TableCell>
                    <TableCell>{carlst.email}</TableCell>
                    <TableCell>{carlst.phone}</TableCell>
                    <TableCell>
                      <Link to={`/sales-edit/${carlst._id}`}>
                        <IconButton color="success" size="small">
                          <EditIcon />
                        </IconButton>
                      </Link>

                      <IconButton
                        variant="outlined"
                        color="error"
                        aria-label="delete"
                        onClick={() => handleDelete(carlst)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              onPageChange={handlePageChange}
              page={controller.page}
              count={saleslistCount}
              rowsPerPage={controller.rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {showLoading === true ? (
              <p className="custm-loader">
                {" "}
                <CircularProgress />{" "}
              </p>
            ) : (
              ""
            )}
          </Card>
        </div>
    
    </div>
  );
};

export default SalesRepresentative;
