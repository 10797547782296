import { createSlice } from '@reduxjs/toolkit'

export const salesProductsSlice = createSlice({
    name: 'products',
    initialState: {
        products_list: {

        }
    },
    reducers: {
        setSalesProductsList: (state, data) => {
            console.log("before",data.payload)
            state.products_list = data.payload
            console.log("after",data.payload)
        }
    }
})

// Action creators are generated for each case reducer function
export const { setSalesProductsList } = salesProductsSlice.actions

export default salesProductsSlice.reducer