//react
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  Card,
  Table,
  TableHead,
  TableBody,
  CircularProgress,
  TableRow,
  TableCell,
  IconButton,
  Button,
  Container,
  TablePagination,
  Typography,
  Select,
  MenuItem,
  TextField,
  Grid,
  Backdrop,
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";
import Pagination from '@mui/material/Pagination';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import { useGetEnquiries } from "../services/enquiryService";
import "./Enquiries.css";
import { setStatusName, setStatusValidations } from "../storeSlices/equirySlice";
import SearchAutoComplete from "./core/SearchModalAutoComplete";
import SearchModelAutoComplete from "./core/SearchModalAutoComplete";
import SearchVersionsAutoComplete from "./core/SearchVersionsAutoComplete";
import SearchByName from "./core/SearchByName";
import SearchByDmsValue from "./core/SearchByDmsNumber";
import SearchByPhoneNumber from "./core/SearchByPhoneNumber";
import { toBePartiallyChecked } from "@testing-library/jest-dom/dist/matchers";
// import "../components/enquiries/scss/enquiries.scss";

const Enquiries = () => {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);
  const dispatch = useDispatch();
  const Swal = require("sweetalert2");

  // service methods
  const getEnquiriesRequest = useGetEnquiries();

  // route methods
  const { search } = useLocation();
  const page = new URLSearchParams(search).get("page");
  const take = new URLSearchParams(search).get("take");
  const navigate = useNavigate();

  // local state
  const [search_string, setSearchString] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [rows, setRowData] = useState([]);
  const [enquiryCount, setEnquiryCount] = useState();
  const [message, setMessage] = useState();
  const [totalPagesCount, setTotalPagesCount] = useState()
  const [phone_number, setPhoneNumber] = useState("")
  const [dmsFilter, setDmsFilter] = useState(true)

  const [dms, setDms] = useState("")

  const [controller, setController] = useState({
    page: page || 1,
    rowsPerPage: take || 20,
  });

  // pagination url
  useEffect(() => {
    dispatch(setStatusValidations([]))

    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    getData(controller.page, controller.rowsPerPage, search_string, dms, modal_name, version, phone_number);
  }, [controller]);

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });

    navigate("/");
  };

  // pagination change page , no change take
  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
    getData(newPage, controller.rowsPerPage, search_string, dms, modal_name, version, phone_number);

  };


  // pagination no change page , change change take
  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: +event.target.value,
      page: 1,
    });
    getData(1, +event.target.value, search_string, dms, modal_name, version, phone_number);
  };

  const deleteEnquiryPopUp = (enurylist) => {
    Swal.fire({
      text: "Are you sure to delete this Enquiry!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#929292",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          handleDelete(enurylist)
          Swal.fire("Deleted!", "Enquiry has been deleted.", "success");
        }, 1000);
      }
    });
  }

  // delete row single data

  const handleDelete = async (enurylist) => {
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/enquiries/${enurylist._id}`;
      let options = {
        method: "delete",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
        }),
      };
      let response = await fetch(url, options);
      let data = await response.json();
      if (response.status == 200 || response.status == 201) {
        getData(controller.page, controller.rowsPerPage, search_string, dms, modal_name, version, phone_number);

      } else if (response.status == 422) {
        setMessage(response.message);
      } else if (response.status == 403) {
        handleLogout();
      }
    } catch (err) {
      console.error(err);
    }
  };

  //event
  const handleSearch = (event) => {
    setController({
      page: 1,
      rowsPerPage: 20,
    })
    setSearchString(event.target.value);

  };


  // get all enquiry data 
  const getData = async (pages, rows, search_string, dms, modal_name, version, phone_number) => {
    setShowLoading(true);
    let stateDataCopy = await getEnquiriesRequest(pages, rows, search_string, dms, modal_name, version, phone_number);
    setRowData(stateDataCopy.eqnuries_list.data);
    setEnquiryCount(stateDataCopy.eqnuries_list.total);
    setShowLoading(false);
    setTotalPagesCount(stateDataCopy?.eqnuries_list?.total_pages)
  };

  const getDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const dateToday = new Date();
    const dayToday = dateToday.getDate();
    const monthToday = dateToday.getMonth();
    const yearToday = dateToday.getFullYear();

    let duration = 0;
    if (yearToday === year) {
      if (monthToday === month) {
        duration += dayToday - day;
      } else {
        duration += (monthToday - month) * 30;
        duration += dayToday - day;
      }
    } else {
      duration += (yearToday - year) * 365;
      if (monthToday === month) {
        duration += dayToday - day;
      } else {
        duration += (monthToday - month) * 30;
        duration += dayToday - day;
      }
    }

    if (!duration) {
      return "Today";
    } else if (duration === 1) {
      return "Yesterday";
    } else {
      return `${duration} days ago`;
    }
  };

  //search filter logic
  const [filtersNames, setFilterNames] = useState([
    { label: "DMS Number", value: "Dms Number" },
    { label: "Phone Number", value: "Phone Number" },
    { label: "Customer Name", value: "Customer Name" },
    { label: "Car Modal/Version", value: "ModalVersion" },
  ])

  const [serachFilterName, setFilterName] = useState()

  //getvarsion and model of a car
  const [model_id, setModalId] = useState()
  const [modal_name, setModalName] = useState("")
  const ModalName = (modal) => {
    if (!modal) {
      setModalName("");
      getData(1, 20, "", "", "", "", "")
      setModalId("")
      setVersionName("")

    }
    setModalId(modal._id)
    setModalName(modal.model)
    getData(1, 20, "", "", modal.model, version, "")


  }
  const [version, setVersionName] = useState("")
  const VersionName = (version) => {
    if (!version) {
      setVersionName("")
      getData(1, 20, "", "", modal_name, "", "")
    }
    setVersionName(version.title)
    getData(1, 20, "", "", modal_name, version.title, "")
  }

  const searchByCustomerName = (name) => {
    setSearchString(name)
    getData(1, 20, name, "", "", "", "")
  }
  const searchByDmsValue = (dms) => {
    setDms(dms)
    getData(1, 20, "", dms, "", "", "")
  }

  const searchCustomerMobile = (phone) => {
    setPhoneNumber(phone)
    getData(1, 20, "", "", "", "", phone)

  }
  const setStatusColor = (status) => {

    switch (status) {
      case "ENQUIRY":
        return "#e66f0e";
        break;
      case "BOOKING":
        return "#1616c9";
        break;
      case "CUSTOMER CARE":
        return "#c54cc7";
        break;
      case "DELIVERY":
        return "#326e29"
        break;
      case "FINANCE":
        return "#bfb60b";
        break;
      case "RETAIL":
        return "#fc45aa";
        break;

      default:
        return "#25af60"
    }
  }
  return (
    <div id="enquiryPage">
      <div className="enquiry-page">
        <div className="search">
          <div className="search"  >
            <Box sx={{ minWidth: 180, marginRight: "1rem" }}>
              <FormControl fullWidth className="label">
                <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={serachFilterName}
                  onChange={(e) => {
                    setFilterName(e.target.value)
                    setDmsFilter(false)
                    getData(1, 20, "", "", "", "", "")
                    setModalName("");
                    setVersionName("")
                    setPhoneNumber("")
                    setDms("")
                    setStatusName("")
                    setSearchString("")
                    setModalId("")
                  }}
                  sx={{ border: "1px solid #cccccc", background: "#ffffff" }}
                >
                  {filtersNames && filtersNames.map((item, index) => (
                    <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {serachFilterName == "Phone Number" && <SearchByPhoneNumber captureValue={searchCustomerMobile} />}
            {serachFilterName == "Dms Number" || dmsFilter == true ? <SearchByDmsValue captureValue={searchByDmsValue} /> : ""}
            {serachFilterName == "Customer Name" && <SearchByName captureValue={searchByCustomerName} />}
            {serachFilterName == "ModalVersion" ?
              <div style={{ display: "flex", flexDirection: "row" }}>
                <SearchModelAutoComplete placeholder={""} captureValue={ModalName} />
                <SearchVersionsAutoComplete id={model_id} captureValue={VersionName} />
              </div> : ""}
          </div>
          <div >
            <Link to="/enquiries/add">
              <Button
                variant="contained"

              >
                <AddIcon></AddIcon>Add
              </Button>
            </Link>
          </div>
        </div>
        <div className="custm-table-main">
          <Card>
            <Table>
              <TableHead>
                <TableRow sx={{ background: "#FBFBFB" }}>
                  <TableCell className="custm-th table-head">
                    # DMS Number
                  </TableCell>
                  <TableCell className="custm-th">Model & Version</TableCell>
                  <TableCell className="custm-th">Sales Rep</TableCell>
                  <TableCell className="custm-th">Customer Name</TableCell>
                  <TableCell className="custm-th">Phone Number</TableCell>
                  <TableCell className="custm-th">Duration</TableCell>
                  <TableCell className="custm-th">Status</TableCell>
                  <TableCell className="custm-th" style={{ width: "14ch" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map((enurylist, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className="table-head"
                          style={{ fontSize: "clamp(.875rem, 0.729vw, 1.75rem) !important", color: "#6C34BA" }}
                        >
                          <Link
                            to={`/enquiries/${enurylist._id}`}
                            state={{ enquiryId: enurylist.dms_number }}
                            style={{ fontSize: "clamp(.875rem, 0.729vw, 1.75rem) !important", color: "#6C34BA" }}
                          >
                            {enurylist.dms_number}
                          </Link>
                        </TableCell>

                        <TableCell>
                          <span style={{ fontSize: "clamp(.875rem, 0.729vw, 1.75rem) !important", color: "#077cf0" }}>
                            {enurylist.car_information.model}
                          </span>
                          <br />
                          <span style={{ fontSize: "clamp(.875rem, 0.729vw, 1.75rem) !important", color: "#787885" }}>
                            {enurylist.car_information.version}
                          </span>
                        </TableCell>
                        <TableCell style={{ width: "clamp(150px,12vw,300px)", whiteSpace: "normal" }}>
                          {enurylist && enurylist.sales_representative_id
                            ? enurylist.sales_representative_id.name
                            : "NA"}
                        </TableCell>
                        <TableCell style={{ width: "clamp(150px,12vw,300px)", whiteSpace: "normal" }}>
                          {enurylist.personal_information.first_name +
                            " " +
                            enurylist.personal_information.last_name}
                        </TableCell>
                        <TableCell style={{ width: "clamp(120px,10vw,200px)", }}>
                          {enurylist.personal_information.phone_number_r}
                        </TableCell>
                        <TableCell>
                          {getDate(new Date(enurylist.enquiry_date))}
                        </TableCell>
                        <TableCell style={{ width: "100px" }}>
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: setStatusColor(enurylist.status),
                              fontSize: "10px",
                              borderRadius: "30px",
                              textTransform: "capitalize",
                              cursor: "auto",
                            }}
                          >
                            {enurylist.status}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/enquiries/${enurylist._id}`}
                            state={{ enquiryId: enurylist.dms_number }}
                          >
                            <IconButton
                              variant="outlined"
                              color="primary"
                              aria-label="remove"
                            >
                              <RemoveRedEyeIcon />
                            </IconButton>
                          </Link>

                          <Link to={`/enquiries/${enurylist._id}/edit`}>
                            <IconButton color="success" size="small">
                              <EditIcon />
                            </IconButton>
                          </Link>

                          {/* <Link to={`/enquiries/${enurylist._id}/note`}>
                          <IconButton sx={{ color: "pink[500]" }} size="small">
                            <SpeakerNotesIcon />
                          </IconButton>
                        </Link> */}

                          <IconButton
                            variant="outlined"
                            color="error"
                            aria-label="delete"
                            onClick={() => deleteEnquiryPopUp(enurylist)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>

            {/* <TablePagination
              component="div"
              onPageChange={handlePageChange}
              page={controller.page}
              count={enquiryCount}
              rowsPerPage={controller.rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              getrowid={(row) => row._id}
            /> */}
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={showLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Card>

        </div>


        {enquiryCount == 0 ? <img src={require("../img/no-data.png")} width={650} height={650} style={{ marginLeft: "20%" }}></img> : ""}

        {showLoading == false && enquiryCount != 0 ?
          <div style={{ marginTop: "0.5rem" }}>
            <Grid container className="pagination-container" >
              <Grid item xs={6} sx={{ textAlign: "end" }}>
                <Pagination shape="rounded"
                  className="pagination"
                  sx={{
                    '& .MuiButtonBase-root': {
                      height: "25px !important",
                      width: "25px !important",
                      minWidth: "inherit",

                    },
                  }}
                  count={totalPagesCount}
                  page={controller.page}
                  onChange={handlePageChange}
                />
              </Grid>

              <Grid xs={6} className="right-pagination">
                <Typography sx={{ fontSize: "0.8rem !important" }} variant="subtitle2" >Row per Page</Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={controller.rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                  defaultValue={20}
                  size="medium"
                  sx={{
                    height: "30px !important",
                    borderRadius: "3px !important",
                    fontSize: "11px",
                    border: "1px solid grey",
                    '& .MuiSvgIcon-root': {
                      right: "0 !important",
                    }
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>

          </div > : ""}

      </div >
    </div>
  );
};

export default Enquiries;
