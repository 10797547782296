import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";
import "./Deliveries.css";

function Deliveries() {
  const navigate = useNavigate();

  const AuthStateData = useSelector((state) => state.signIn);

  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }

    getData();
  }, []);

  const getData = async () => {};

  return (
    <div id="Deliveries">
      <Container>
        
        <div className="content">
          <p>No Data</p>
        </div>
      </Container>
    </div>
  );
}

export default Deliveries;
