//react
import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useSearchParams,
  useLocation,
  Link,
} from "react-router-dom";

import { useSelector } from "react-redux";

import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useGetSales } from "../services/salesService";
import CircularProgress from "@mui/material/CircularProgress";
import PercentIcon from "@mui/icons-material/Percent";
import { IconButton, TablePagination } from "@mui/material";
import "../components/enquiries/scss/Sales.scss";

const Sales = () => {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);
  const stateData = useSelector((state) => state.products);

  //service methods
  const getSalesRequest = useGetSales();

  // route methofs
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const page = new URLSearchParams(search).get("page");
  const take = new URLSearchParams(search).get("take");
  console.log(page);
  console.log(take);
  const navigate = useNavigate();

  //local state
  const [showLoading, setShowLoading] = useState(false);
  const [rows, setRowData] = useState([]);
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/carlist"
      onClick={handleClick}
    >
      Sales
    </Link>,
  ];
  const [passengersCount, setPassengersCount] = useState(0);
  const [controller, setController] = useState({
    page: page || 0,
    rowsPerPage: take || 10,
  });

  // React Hooks
  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    getData();
  }, [controller]);

  // get all setSalesRepresentative data

  const getData = async () => {
    setShowLoading(true);
    await getSalesRequest(controller);
    setRowData(stateData.products_list.data);
    setPassengersCount(stateData.products_list.total);
    setShowLoading(false);
  };

  // server side pagination
  const handlePageChange = (event, newPage) => {
    console.log(newPage);

    console.log(event, newPage);
    //getData()
    const { page, value } = event?.target;
    setSearchParams({ page: newPage, take: 10 });

    setController({
      ...controller,
      page: newPage,
    });
    getData();
  };

  //events
  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  return (
    <div id="Sales">
      <br></br>
      <Container maxWidth="lg">
        <div role="presentation" onClick={handleClick}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div className="sale-table-list">
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="custm-th">Name</TableCell>
                  <TableCell className="custm-th">BOOKING</TableCell>
                  <TableCell className="custm-th">CUSTOMER CARE</TableCell>
                  <TableCell className="custm-th">DELIVERY</TableCell>
                  <TableCell className="custm-th">ENQUIRY</TableCell>
                  <TableCell className="custm-th">FINANCE</TableCell>
                  <TableCell className="custm-th">RETAIL</TableCell>
                  <TableCell className="custm-th">PERFORMANCE</TableCell>
                  <TableCell className="custm-th">ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((data) => (
                  <TableRow>
                    <TableCell className="table-data">
                      {data.salesRepresentativeName}
                    </TableCell>
                    <TableCell className="table-data">{data.BOOKING}</TableCell>
                    <TableCell className="table-data">
                      {data.CUSTOMER_CARE}
                    </TableCell>
                    <TableCell className="table-data">
                      {data.DELIVERY}
                    </TableCell>
                    <TableCell className="table-data">{data.ENQUIRY}</TableCell>
                    <TableCell className="table-data">{data.FINANCE}</TableCell>
                    <TableCell className="table-data">{data.RETAIL}</TableCell>
                    <TableCell className="table-data">
                      {Math.round(data.PERFORMANCE)}
                      <PercentIcon
                        sx={{ color: "#FF0000", "max-height": "16px" }}
                      />
                    </TableCell>
                    <TableCell
                      className="table-data"
                      sx={{ display: "inline-flex" }}
                    >
                      <IconButton variant="outlined" className="actions">
                        {data.ACTIONS}
                      </IconButton>
                      <Link to={"/analysis"}>
                        <IconButton variant="outlined" className="graph">
                          ANALYSIS
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              onPageChange={handlePageChange}
              page={controller.page}
              count={passengersCount}
              rowsPerPage={controller.rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              getRowId={(row) => row._id}
            />
            {showLoading === true ? (
              <p className="custm-loader">
                <CircularProgress />
              </p>
            ) : (
              ""
            )}
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Sales;
