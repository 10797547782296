import { combineReducers, configureStore } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"
import equirySclice from '../src/storeSlices/equirySlice'
import carSlice from '../src/storeSlices/carSlice'
import salesSlice from './storeSlices/salesSlice'
import salesProductsSlice from "./storeSlices/salesProductsSlice"
import signInSlice from "./authSlices/signInSlice"

const appReducer = combineReducers({
    equiry: equirySclice,
    singleenquiry: equirySclice,
    car: carSlice,
    sales: salesSlice,
    products: salesProductsSlice,
    signIn: signInSlice
})

const rootReducer = (state, action) => {
    if (action.type === "SIGN_OUT") {
        // for all keys defined in your persistConfig(s)
        storage.removeItem('persist:root')
        // storage.removeItem('persist:otherKey')

        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

const persistConfig = {
    key: "root",
    storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export default configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

})