import { TextField } from "@mui/material"
import { useState } from "react"
import { IconButton, InputAdornment } from "@mui/material";
import CancelOutlined from "@mui/icons-material/CancelOutlined";


const SearchByDmsValue = ({ captureValue }) => {

    const [dmsNum, setDMSValue] = useState()
    return (
        <>
            <TextField
                sx={{ border: "1px solid #cccccc", background: "#ffffff", borderRadius: "3px", paddingInlineStart: "5px" }}
                placeholder="DMS Number"
                value={dmsNum}
                onChange={(e) => {
                    setDMSValue(e.target.value)
                    captureValue(e.target.value)
                }}
                InputProps={{

                    endAdornment: (
                        <InputAdornment position="start">
                            {dmsNum ?
                                <IconButton onClick={() => {
                                    setDMSValue("")
                                    captureValue("")

                                }}>
                                    <CancelOutlined />
                                </IconButton> : ""}
                        </InputAdornment>
                    )
                }}
            />
        </>
    )
}
export default SearchByDmsValue