import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const SearchVersionsAutoComplete = ({ id, captureValue }) => {
    console.log(id)

    const AuthStateData = useSelector((state) => state.signIn);
    const [loading, setLoading] = React.useState()

    const navigate = useNavigate()

    const [versionsData, setVersionsData] = React.useState([]);


    React.useEffect(() => {
        if (id) {
            getVersionData()
        } else {
            setVersionsData([])
            setInputValue(null)
            setValue(null)
        }

    }, [id])


    //get car version data from the API
    const getVersionData = async () => {
        setLoading(true)
        let result = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/cars/models/${id}`,
            {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`,
                }),
            }
        );

        result = await result.json();
        setLoading(false)
        setVersionsData(result.data.versions)
    };


    const [value, setValue] = React.useState("model");
    const [inputValue, setInputValue] = React.useState('');

    const [searchString, setSearchString] = React.useState('');

    const onChangeSearchString = (event) => {
        setSearchString(event.target.value);
        captureValue(event);
    }


    return (
        <>
            <Autocomplete
                loading={loading}
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    captureValue(newValue);

                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);

                }}
                noOptionsText={'Please select car model'}
                id="controllable-states-demo"
                options={versionsData}
                getOptionLabel={(option) => option.title ? option.title : ""}
                sx={{
                    width: 300, height: "50px", marginLeft: "30px",
                    '& .MuiTextField-root': {
                        border: "1px solid black"
                    }

                }}
                renderInput={(params) => <TextField {...params} label="Versions" />}
            />
        </>
    )
}
export default SearchVersionsAutoComplete