import { Card, Typography } from '@mui/material';
import React, { PureComponent } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DateRangePicker, Stack } from 'rsuite';
import "rsuite/dist/rsuite.css";
import isAfter from 'date-fns/isAfter';
import moment from 'moment';
import { getDateWithYearForApi } from '../pipes/timePipe';

const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } =
    DateRangePicker;

const EnquiriesGraph = () => {

    const [enquiriesDetails, setEnquiresGraphData] = useState()
    const [loading, setLoading] = useState(false);
    const AuthStateData = useSelector((state) => state.signIn);
    const [fromDate, setFromDate] = useState();

    const [defaultFromDate, setDefaultFromDate] = useState(getDateWithYearForApi(new Date('2023-07-01').toISOString()));
    const [defaultToDate, setDefaultToDate] = useState(getDateWithYearForApi(new Date().toISOString()));


    useEffect(() => {
        getEnquiriesGraphData()
    }, [])


    //api call for get statisticsData
    const getEnquiriesGraphData = async (fromDate = defaultFromDate, toDate = defaultToDate) => {
        setLoading(true);
        try {
            const url = `${process.env.REACT_APP_API_BASE_URL}/enquiries/status-wise/count?status=ENQUIRY&from_date=${fromDate}&to_date=${toDate}`;
            const options = {
                method: "get",
                headers: new Headers({
                    "Content-Type": "application/json",
                    authorization: `${AuthStateData.signInSuccess.userDetails.refresh_token}`
                }),
            };
            const response = await fetch(url, options);
            const responseData = await response.json();
            console.log(responseData)
            if (responseData.success) {
                setEnquiresGraphData(responseData.data);
            } else {
                setEnquiresGraphData(null)
                throw responseData;
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    //date
    const [value, setValue] = React.useState([
        new Date(),
        new Date()

    ]);



    return (
        <>
            <Card >
                <div style={{ display: "flex", width: "90%", flexDirection: "row", alignContent: "center" }}>
                    <Typography variant='h6' style={{ width: "90%" }}>Enquiries</Typography>
                    <Stack direction="column" spacing={8} alignItems="flex-start">
                        <DateRangePicker value={value}
                            onChange={(newDate) => {
                                setFromDate(newDate)
                                if (newDate) {
                                    let date1 = new Date(moment(new Date(newDate[0])).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                                    let date2 = new Date(moment(new Date(newDate[1])).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                                    getEnquiriesGraphData(date1, date2)
                                }
                                else {
                                    let date1 = new Date(moment(new Date()).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                                    let date2 = new Date(moment(new Date()).format("YYYY-MM-DD")).toISOString().substring(0, 10);
                                    getEnquiriesGraphData(date1, date2)
                                }

                            }}
                            showOneCalendar
                            disabledDate={afterToday()}
                        />
                    </Stack>
                </div>                <hr></hr>
                {enquiriesDetails == null ? <div style={{ marginTop: "30%", textAlign: "center " }}>
                    <Typography variant='title1'>No Enquiries found In Between Range</Typography>
                </div> :
                    <LineChart
                        width={500}
                        height={300}
                        data={enquiriesDetails}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="enquiry_date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>}
            </Card>
        </>
    )
}
export default EnquiriesGraph