import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepButton from "@mui/material/StepButton";
import { useState } from "react";

function Progress(props) {
  console.log("po", props)
  const [userType, setUserType] = useState(0);
  const [actualType, setActualType] = useState(0);

  const steps = [
    "Enquiry",
    "Booking",
    "Cust.care",
    "Finance",
    "Retail",
    "Delivery",

    // "Cust.care",
  ];

  const statusStatus = {
    ENQUIRY: 0,
    BOOKING: 1,
    FINANCE: 3,
    RETAIL: 4,
    DELIVERY: 5,
  };

  useEffect(() => {
    return test();
  }, []);

  const test = () => {
    if (props.equeryData.status === "ENQUIRY" && props.equeryData.dms_number) {
      setUserType(0);
    }
    if (props.equeryData.status === "BOOKING") {
      setUserType(1);
    }
    // if (props.status === "CUSTOMER CARE") {
    //   setUserType(2);
    // }
    if (props.equeryData.status === "FINANCE") {
      setUserType(3);
    }
    if (props.equeryData.status === "RETAIL") {
      setUserType(4);
    }
    if (props.equeryData.status === "DELIVERY") {
      setUserType(5);
    }
    if (props.equeryData.status === "CUSTOMER CARE") {
      setUserType(6);
    }

    if (props.equeryData.actual_status === "ENQUIRY") {
      setActualType(0);
    }
    if (props.equeryData.actual_status === "BOOKING") {
      setActualType(1);
    }
    // if (props.status === "CUSTOMER CARE") {
    //   setActualType(2);
    // }
    if (props.equeryData.actual_status === "FINANCE") {
      setActualType(3);
    }
    if (props.equeryData.actual_status === "RETAIL") {
      setActualType(4);
    }
    if (props.equeryData.actual_status === "DELIVERY") {
      setActualType(5);
    }
    if (props.equeryData.actual_status === "CUSTOMER CARE") {
      setActualType(6);
    }
  };

  const updateToParent = (index) => {
    props.equeryData.status = index.toUpperCase();
    props.updateStatusTab(statusStatus[props.equeryData.status]);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        {props.equeryData && (
          <Stepper
            activeStep={statusStatus[props.equeryData.status]}
            orientation="vertical"

          >
            {steps.map((label, index) => (
              <Step
                key={label}
                completed={
                  statusStatus[props.equeryData.status] >= index && props.equeryData.dms_number
                }

              >
                <StepButton
                  color="inherit"
                // onClick={() => updateToParent(label)}
                >
                  <StepLabel sx={{

                    '& .MuiStepLabel-label': {
                      fontSize: "clamp(.875rem, 1vw, 2rem) !important",
                    }
                  }}>
                    {label}
                    <br />
                    {index === 0 ? (
                      <Typography variant="caption">
                        {/* {moment(props.equeryData.enquiry_date).format(
                          "DD/MM/YYYY"
                        )} */}
                      </Typography>
                    ) : null}
                    {index === 1 &&
                      props.equeryData.actual_status == "BOOKING" ? (
                      <Typography variant="caption">
                        {/* {moment(props.equeryData.booking_date).format(
                          "DD/MM/YYYY"
                        )} */}
                      </Typography>
                    ) : null}
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}
      </Box>
    </div>
  );
}

export default Progress;

// import * as React from "react";
// import Box from "@mui/material/Box";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
// import StepContent from "@mui/material/StepContent";
// import Button from "@mui/material/Button";
// import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";

// const steps = [
//   {
//     label: "Enquiry",
//   },
//   {
//     label: "Booking",
//   },
//   {
//     label: "Cust.care",
//   },
//   {
//     label: "Finance",
//   },
//   {
//     label: "Retail",
//   },
// ];

// function Progress() {
//   const [activeStep, setActiveStep] = React.useState(0);

//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleReset = () => {
//     setActiveStep(0);
//   };

//   return (
//     <Box sx={{ maxWidth: 400 }}>
//       <Stepper activeStep={activeStep} orientation="vertical">
//         {steps.map((step, index) => (
//           <Step key={step.label}>
//             <StepLabel
//               optional={
//                 index === 5 ? (
//                   <Typography variant="caption">Last step</Typography>
//                 ) : null
//               }
//             >
//               {step.label}
//             </StepLabel>
//             <StepContent>
//               <Typography>{step.description}</Typography>
//               <Box sx={{ mb: 2 }}></Box>
//             </StepContent>
//           </Step>
//         ))}
//       </Stepper>
//       {activeStep === steps.length && (
//         <Paper square elevation={0} sx={{ p: 3 }}>
//           <Typography>All steps completed - you&apos;re finished</Typography>
//           <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//             Reset
//           </Button>
//         </Paper>
//       )}
//     </Box>
//   );
// }

// export default Progress;
