import { useDispatch, useSelector } from "react-redux";
import { setCarsList } from "../../src/storeSlices/carSlice";
export const useGetCarList = () => {
  const dispatch = useDispatch();
  const signInSliceData = useSelector((state) => state.signIn);
  return async (controller) => {
    let result = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/cars?page=${controller.page}&take=${controller.rowsPerPage}`,
      {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: `${signInSliceData.signInSuccess.userDetails.refresh_token}`
        }),
      }
    );

    let getcarsdata = await result.json();

    dispatch(setCarsList(getcarsdata));

    return {
      cars_list: getcarsdata
    }
  };
};
