import { createSlice } from '@reduxjs/toolkit'

export const salesSlice = createSlice({
    name: 'sales',
    initialState: {
        sales_list: {

        }
    },
    reducers: {
        setSalesList: (state, data) => {

            console.log("before",data.payload)
            state.sales_list = data.payload
            console.log("after",data.payload)

        }
    }
})

// Action creators are generated for each case reducer function
export const { setSalesList } = salesSlice.actions

export default salesSlice.reducer