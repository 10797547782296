//react
import { useEffect, useState } from "react";
import * as React from "react";

//navigation from router
import { useNavigate } from "react-router-dom";

//numeric format for numbers
import { NumericFormat } from "react-number-format";

//importing Store
import { useSelector } from "react-redux";

//importing MeterialUI
import {
  Card,
  FormControl,
  Grid,
  Typography,
  TextField,
  Chip,
  Paper,
} from "@mui/material";

//importing local Components
import EventEmitter from "../../utils/Emitter";
import "../scss/add/AddBookingAccessories.scss";

function AddBookingAccessories(props) {
  // store selector
  const AuthStateData = useSelector((state) => state.signIn);

  // route methods
  const navigate = useNavigate();

  const { inputRef, onChange } = props;

  // local state
  const [loading, setLoading] = useState(false);

  const [freeCostAmount, setFreeCostAmount] = useState("");
  const [bookingAmount, setBookingAmount] = useState("");

  const [allValidationErrors, setAllValidationErrors] = useState({});
  const [netPrice, setNetPrice] = useState();
  const [values, setValues] = useState({ tags: [] });

  const MAX_VAL = 1000000000;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };

  // pagination url

  useEffect(() => {
    if (!AuthStateData.signInSuccess) {
      navigate("/");
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    });

    settingAccessories();

    const setNetPriceEventEmitter = (eventData) => {
      setNetPrice(eventData.value);
      props.enquriyInfo.booking_info.net_price_to_collect = eventData.value;
    };

    const listener = EventEmitter.addListener(
      "net_price",
      setNetPriceEventEmitter
    );
    return () => {
      listener.remove();
    };
  }, [netPrice]);

  const settingAccessories = () => {
    if (
      props.enquriyInfo.booking_info.extra_accessories_to_be_put_free_of_cost
        .title
    ) {
      setValues({
        tags: props.enquriyInfo.booking_info.extra_accessories_to_be_put_free_of_cost.title.split(
          ", "
        ),
      });
    }
  };

  //on clicking the enter in extra accessories
  const onKeyPress = (e) => {
    if (e.key == "Enter" && e.target.value !== "") {
      e.preventDefault();

      let indexDuplicate = values.tags.findIndex((x) => x === e.target.value);
      if (indexDuplicate === -1) {
        let copy = [...values.tags, e.target.value];
        let extraAccessories = copy.join(", ");
        props.enquriyInfo.booking_info.extra_accessories_to_be_put_free_of_cost.title =
          extraAccessories;
        setValues({ tags: [...values.tags, e.target.value] });
      }
      e.target.value = "";
    }
  };

  //deleting the created chips
  const handleChipDelete = (item) => {
    let copy = [...values.tags];
    const filterd = copy.filter((chip) => {
      return item !== chip;
    });
    if (filterd) {
      props.enquriyInfo.booking_info.extra_accessories_to_be_put_free_of_cost.title =
        filterd.join(", ");
      setValues({ tags: filterd });
    }
  };

  return (
    <div id="addAccessories">
      <Card className="accessories-info" variant="outlined">
        <Grid container className="block-inner" spacing={3}>
          <Grid className="block-item" item xs={12}>
            <Typography className="title">Accessories</Typography>
          </Grid>
          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading-1">Extra Accessories</Typography>
              <TextField
                fullWidth={true}
                id="standard-name"
                // variant="standard"
                className="text-field"
                sx={{ background: "#f6f7f9" }}
                onKeyDown={onKeyPress}
              />
              <Paper
                value={
                  props.enquriyInfo.booking_info
                    .extra_accessories_to_be_put_free_of_cost.title
                }
              >
                {values.tags &&
                  values.tags.map((tag, id) => (
                    <Chip
                      color="default"
                      onDelete={() => handleChipDelete(tag)}
                      key={id}
                      label={tag}
                    />
                  ))}
              </Paper>
            </FormControl>
          </Grid>
          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner" variant="standard">
              <Typography className="heading-1">Free Cost Amount</Typography>
              <NumericFormat
                className="text-field"
                customInput={TextField}
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                // variant="standard"
                style={{
                  cursor: "pointer",
                  border: "1px blue",
                  background: "#f6f7f9",
                }}
                prefix="₹"
                isAllowed={withValueCap}
                getInputRef={inputRef}
                value={
                  props.enquriyInfo.booking_info
                    .extra_accessories_to_be_put_free_of_cost.price
                }
                onValueChange={(e) => {
                  setFreeCostAmount(e.floatValue);
                  props.enquriyInfo.booking_info.extra_accessories_to_be_put_free_of_cost.price =
                    e.floatValue;
                }}
                thousandSeparator
                thousandsGroupStyle="lakh"
                helpertext={
                  allValidationErrors
                    ? allValidationErrors[
                    "extra_accessories_to_be_put_free_of_cost.price"
                    ]
                    : ""
                }
                error={
                  allValidationErrors
                    ? allValidationErrors[
                    "extra_accessories_to_be_put_free_of_cost.price"
                    ]
                    : ""
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </Card>
      <Card className="price-block" variant="outlined">
        <Grid className="block-inner" container>
          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading-1">Net Price</Typography>
              <NumericFormat
                className="text-field"
                customInput={TextField}
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{
                  cursor: "pointer",
                  border: "1px blue",
                }}
                inputProps={{ readOnly: true }}
                prefix="₹"
                isAllowed={withValueCap}
                getInputRef={inputRef}
                value={
                  props.enquriyInfo.booking_info.net_price_to_collect
                    ? "₹ " +
                    props.enquriyInfo.booking_info.net_price_to_collect
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                    : ""
                }
                thousandSeparator
                thousandsGroupStyle="lakh"
                helpertext={
                  allValidationErrors
                    ? allValidationErrors["net_price_to_collect"]
                    : ""
                }
                error={
                  allValidationErrors
                    ? allValidationErrors["net_price_to_collect"]
                    : ""
                }
              />
              {props.enquriyInfo.booking_info.net_price_to_collect ? (
                ""
              ) : (
                <Typography
                  variant="h6"
                  color="error"
                  style={{ fontSize: "12px", paddingLeft: "15px" }}
                >
                  {props.allValidationErrors
                    ? props.allValidationErrors[
                    "booking_info.net_price_to_collect"
                    ]
                    : ""}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading-1">Booking Amount   <strong style={{ color: "rgb(228 12 15)" }}>*</strong></Typography>
              <NumericFormat
                className="text-field"
                customInput={TextField}
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{
                  cursor: "pointer",
                  border: "1px blue",
                }}
                prefix="₹"
                isAllowed={withValueCap}
                getInputRef={inputRef}
                value={props.enquriyInfo.booking_info.booking_amount}
                name="boomou"
                onValueChange={(e) => {
                  setBookingAmount(e.floatValue);
                  props.enquriyInfo.booking_info.booking_amount = e.floatValue;
                  props.allValidationErrors["booking_info.booking_amount"] = "";
                }}
                thousandSeparator
                thousandsGroupStyle="lakh"
                helpertext={
                  props.allValidationErrors
                    ? props.allValidationErrors["booking_info.booking_amount"]
                    : ""
                }
                error={
                  props.allValidationErrors
                    ? props.allValidationErrors["booking_info.booking_amount"]
                    : ""
                }
              />
              <Typography
                variant="h6"
                color="error"
                style={{ fontSize: "12px", paddingLeft: "15px" }}
              >
                {props.allValidationErrors
                  ? props.allValidationErrors["booking_info.booking_amount"]
                  : ""}
              </Typography>
            </FormControl>
          </Grid>

          <Grid item className="form-block" xs={4}>
            <FormControl className="form-inner">
              <Typography className="heading-1">Balance Amount</Typography>
              <NumericFormat
                className="text-field"
                customInput={TextField}
                labelid="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="standard"
                style={{
                  cursor: "pointer",
                  border: "1px blue",
                }}
                inputProps={{ readOnly: true }}
                prefix="₹"
                isAllowed={withValueCap}
                getInputRef={inputRef}
                value={
                  props.enquriyInfo.booking_info.net_price_to_collect ||
                    props.enquriyInfo.booking_info.booking_amount
                    ? "₹ " +
                    (
                      props.enquriyInfo.booking_info.net_price_to_collect -
                      (props.enquriyInfo.booking_info.booking_amount || 0)
                    )
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
                    : ""
                }
                thousandSeparator
                thousandsGroupStyle="lakh"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default AddBookingAccessories;
